pre,
textarea {
  overflow: auto;
}
img,
textarea {
  max-width: 100%;
}
dfn,
em,
i {
  font-style: italic;
}
b,
dl dt,
strong {
  font-weight: 700;
}
.slick-dots li button::before,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.clearfix::after,
.slick-track::after,
.tabs::after,
hr {
  clear: both;
}
.best-tv-providers-cards__card .card__logo img,
.best-tv-providers-summary .summary__logo img {
  max-height: 60px;
  max-width: 150px;
}
.button__top,
.invisible,
.slick-loading .slick-track {
  visibility: hidden;
}
.hero-components .vc-table-scrolling__labels,
.hero-components .vc-table-scrolling__table,
.vc-table-scrolling__labels,
.vc-table-scrolling__table {
  border-collapse: collapse;
  border-spacing: 0;
} /*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
.form-error.is-visible,
.is-dropdown-submenu.js-dropdown-active,
.tabs-panel.is-active,
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}
.menu input,
audio,
canvas,
img,
progress,
video {
  display: inline-block;
}
a:active,
a:hover {
  outline-width: 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img {
  border-style: none;
  vertical-align: middle;
  height: auto;
  -ms-interpolation-mode: bicubic;
}
.slider-index__slides,
body.is-reveal-open,
svg:not(:root) {
  overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  font-family: inherit;
}
button,
select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button; /* For WebKit browsers */
  appearance: button; /* Standard property */
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: ButtonText dotted 1px;
}
input {
  overflow: visible;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  appearance: button; /* Standard property */
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
b,
em,
i,
small,
strong {
  line-height: inherit;
}
progress {
  vertical-align: baseline;
}
textarea {
  min-height: 50px;
}
button,
hr {
  overflow: visible;
}
summary {
  display: list-item;
}
canvas {
  position: inherit !important;
}
[hidden],
template {
  display: none;
}
.foundation-mq {
  font-family: "small=0em&medium=40em&topbar=49.375em&large=64em&xlarge=75em&xxlarge=90em";
}
html {
  box-sizing: border-box;
  font-size: 100%;
}
*,
::after,
::before {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  line-height: 1.5;
  color: #151515;
}
.map_canvas embed,
.map_canvas img,
.map_canvas object,
.mqa-display embed,
.mqa-display img,
.mqa-display object {
  max-width: none !important;
}
button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0.3125rem;
  background: rgba(0, 0, 0, 0);
  line-height: 1;
  cursor: auto;
}
.package-card .see-less,
.package-card .see-more,
.stars.dynamic label,
[type="checkbox"] + label[for],
[type="radio"] + label[for],
a {
  cursor: pointer;
}
.has-button [data-whatinput="mouse"] a:first-of-type,
.hero-components
  .vc-table-scrolling__cell.has-button
  [data-whatinput="mouse"]
  a:first-of-type,
.slick-dots li button:focus,
.slick-dots li button:hover,
.slick-list:focus,
[data-whatinput="mouse"] .button,
[data-whatinput="mouse"] .close-button,
[data-whatinput="mouse"] .dropdown.menu a,
[data-whatinput="mouse"] .has-button a:first-of-type,
[data-whatinput="mouse"]
  .hero-components
  .vc-table-scrolling__cell.has-button
  a:first-of-type,
[data-whatinput="mouse"] .menu li,
[data-whatinput="mouse"] .reveal,
[data-whatinput="mouse"] button {
  outline: 0;
}
.hide-for-portrait,
.is-visible,
.show-for-landscape {
  display: block !important;
}
.hide,
.hide-for-landscape,
.is-hidden,
.show-for-portrait,
.show-for-print {
  display: none !important;
}
.flex-container,
.menu.icon-bottom a,
.menu.icon-left a,
.menu.icon-right a,
.menu.icon-top a,
.menu.icons a,
.row {
  display: flex;
}
.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  flex-flow: row wrap;
}
.grid-container:not(.full) > .grid-padding-x,
.grid-padding-x .grid-padding-x,
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.dropdown.menu .nested.is-dropdown-submenu,
.row .row.collapse,
.row.collapse > .column > .row,
.row.collapse > .columns > .row,
.row.is-collapse-child,
.small-margin-collapse,
.small-margin-collapse > .cell,
.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded,
.row:not(.expanded) .row {
  max-width: none;
}
.row.expanded .row,
.subnav {
  margin-right: auto;
  margin-left: auto;
}
.main-wrap .page .cta-sticky--column-padding-restrict,
.row.collapse > .column,
.row.collapse > .columns,
.small-collapse > .column,
.small-collapse > .columns,
.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}
.column,
.columns,
.grid-padding-x > .cell,
.small-uncollapse > .column,
.small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.column,
.columns {
  flex: 1 1 0px;
  min-width: 0;
}
.column.row.row,
.row.row.columns {
  float: none;
  display: block;
}
.row .column.row.row,
.row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}
.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.small-2,
.small-up-6 > .column,
.small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.small-3,
.small-up-4 > .column,
.small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}
.small-4,
.small-up-3 > .column,
.small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.small-6,
.small-up-2 > .column,
.small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}
.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.small-12,
.small-up-1 > .column,
.small-up-1 > .columns,
.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}
.menu,
.small-up-1,
.small-up-2,
.small-up-3,
.small-up-4,
.small-up-5,
.small-up-6,
.small-up-7,
.small-up-8 {
  flex-wrap: wrap;
}
.small-up-5 > .column,
.small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}
.small-up-7 > .column,
.small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}
.small-up-8 > .column,
.small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column,
.row.large-unstack > .columns,
.row.medium-unstack > .column,
.row.medium-unstack > .columns,
.row.topbar-unstack > .column,
.row.topbar-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 49.375em) and (min-width: 49.375em) {
  .topbar-expand {
    flex: 1 1 0px;
  }
}
.shrink,
legend {
  max-width: 100%;
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.shrink {
  flex: 0 0 auto;
}
.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child,
.menu a,
.menu button,
.menu input,
.menu select,
.reveal > :last-child {
  margin-bottom: 0;
}
.align-right,
.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}
.align-center,
.align-center.vertical.menu > li > a,
.menu-centered > .menu {
  justify-content: center;
}
.align-justify {
  justify-content: space-between;
}
.align-spaced {
  justify-content: space-around;
}
.align-top {
  align-items: flex-start;
}
.align-self-top {
  align-self: flex-start;
}
.align-bottom {
  align-items: flex-end;
}
.align-self-bottom {
  align-self: flex-end;
}
.align-middle,
.menu.simple {
  align-items: center;
}
.align-self-middle {
  align-self: center;
}
.align-stretch {
  align-items: stretch;
}
.align-self-stretch {
  align-self: stretch;
}
.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.small-order-1 {
  order: 1;
}
.small-order-2 {
  order: 2;
}
.small-order-3 {
  order: 3;
}
.small-order-4 {
  order: 4;
}
.small-order-5 {
  order: 5;
}
.small-order-6 {
  order: 6;
}
.flex-child-auto {
  flex: 1 1 auto;
}
.flex-child-grow {
  flex: 1 0 auto;
}
.flex-child-shrink {
  flex: 0 1 auto;
}
.flex-dir-row {
  flex-direction: row;
}
.flex-dir-row-reverse {
  flex-direction: row-reverse;
}
.flex-dir-column {
  flex-direction: column;
}
.flex-dir-column-reverse {
  flex-direction: column-reverse;
}
.city-list-toggle h4,
blockquote,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0;
}
ol,
ul {
  margin-left: 1.25rem;
}
small {
  font-size: 80%;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.h7,
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.9375rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: inherit;
  text-rendering: optimizeLegibility;
}
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #cacaca;
}
.h1,
h1 {
  font-size: 1.875rem;
}
.h2,
h2 {
  font-size: 1.5rem;
}
.h3,
h3 {
  font-size: 1.3125rem;
}
.h4,
h4 {
  font-size: 1.125rem;
}
.h5,
h5 {
  font-size: 1rem;
}
.h6,
h6 {
  font-size: 0.875rem;
}
.h7,
h7 {
  font-size: 0.75rem;
}
dl,
ol,
ul {
  margin-bottom: 1rem;
}
figure,
kbd,
label {
  margin: 0;
}
.subheader,
legend {
  margin-bottom: 0.5rem;
}
a {
  background-color: rgba(0, 0, 0, 0);
  -webkit-text-decoration-skip: objects;
  line-height: inherit;
  color: #5050b9;
  text-decoration: underline;
}
a img {
  border: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}
dl,
ol,
ul {
  list-style-position: outside;
  line-height: 1.5;
}
li {
  font-size: inherit;
}
ul {
  list-style-type: disc;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-left: 1.25rem;
  margin-bottom: 0;
}
dl dt {
  margin-bottom: 0.3rem;
}
blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}
blockquote,
blockquote p {
  line-height: 1.5;
  color: #8a8a8a;
}
cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a;
}
cite:before {
  content: "— ";
}
abbr,
abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none;
}
kbd {
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a;
  border-radius: 0.3125rem;
}
.subheader {
  margin-top: 0.2rem;
  font-weight: 400;
  line-height: 1.4;
  color: #8a8a8a;
}
.lead {
  font-size: 125%;
  line-height: 1.6;
}
.stat {
  font-size: 2.5rem;
  line-height: 1;
}
[type="color"],
[type="date"],
[type="datetime-local"],
[type="datetime"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  height: 2.4375rem;
  appearance: none;
  background-color: #fefefe;
  font-weight: 400;
  line-height: 1.5;
  color: #0c0c1d;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
}
p + .stat {
  margin-top: -1rem;
}
ol.no-bullet,
ul.no-bullet {
  margin-left: 0;
  list-style: none;
}
.text-left {
  text-align: left;
}
.menu.align-right.vertical li .submenu li,
.text-right,
.title-bar-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
@media print {
  blockquote,
  img,
  pre,
  tr {
    page-break-inside: avoid;
  }
  * {
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print,
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  blockquote,
  pre {
    border: 1px solid #8a8a8a;
  }
  thead {
    display: table-header-group;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}
[type="color"],
[type="date"],
[type="datetime-local"],
[type="datetime"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
textarea {
  display: block;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-size: 1rem;
}
[type="color"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="datetime"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
select:focus,
textarea:focus {
  outline: 0;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
.grid-margin-y:not(.grid-y) > .cell,
.grid-margin-y > .auto,
.grid-margin-y > .shrink,
.grid-y > .auto,
.grid-y > .shrink,
.scStarsContainer .stars,
textarea[rows] {
  height: auto;
}
input::placeholder,
textarea::placeholder {
  color: #0c0c1d;
}
input:disabled,
input[readonly],
select:disabled,
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
[type="button"],
[type="submit"] {
  appearance: none;
  border-radius: 0.3125rem;
}
.zip-checker.ca-form *,
input[type="search"] {
  box-sizing: border-box;
}
[type="checkbox"],
[type="file"],
[type="radio"] {
  margin: 0 0 1rem;
}
[type="checkbox"] + label,
[type="radio"] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}
label > [type="checkbox"],
label > [type="radio"] {
  margin-right: 0.5rem;
}
.accordion-menu li,
.cell .grid-frame,
.grid-x > .small-12,
.is-dropdown-submenu > li,
.small-margin-collapse > .small-12,
.small-up-1 > .cell,
[type="file"] {
  width: 100%;
}
label {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.8;
  color: #0c0c1d;
}
label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}
.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a;
}
.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}
.input-group > :first-child {
  border-radius: 0.3125rem 0 0 0.3125rem;
}
.input-group > :last-child > * {
  border-radius: 0 0.3125rem 0.3125rem 0;
}
.input-group-button,
.input-group-button a,
.input-group-button button,
.input-group-button input,
.input-group-button label,
.input-group-field,
.input-group-label {
  margin: 0;
  white-space: nowrap;
}
.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.form-error,
.is-invalid-label {
  color: #cc4b37;
}
.input-group-label:first-child {
  border-right: 0;
}
.input-group-label:last-child {
  border-left: 0;
}
.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}
.fieldset,
select {
  border: 1px solid #cacaca;
}
.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}
.input-group-button a,
.input-group-button button,
.input-group-button input,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  box-sizing: border-box;
  display: table;
  padding: 0;
  color: inherit;
  white-space: normal;
}
.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
}
.fieldset legend {
  margin: 0 0 0 -0.1875rem;
  padding: 0 0.1875rem;
}
select {
  margin: 0 0 1rem;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  border-radius: 0.3125rem;
  font-size: 1rem;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
}
@media screen and (min-width: 0\0) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}
.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea;
}
.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}
.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
}
.button,
.has-button a:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:first-of-type,
.vc-table-scrolling__cell.has-button a:first-of-type {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem;
  font-family: inherit;
  -webkit-appearance: none;
  appearance: button; /* Standard property */
  border: 1px solid transparent;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe;
}
.button:focus,
.button:hover,
.has-button a:focus:first-of-type,
.has-button a:hover:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:focus:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:hover:first-of-type {
  background-color: #14679e;
  color: #fefefe;
}
.button.tiny,
.has-button a.tiny:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.tiny:first-of-type {
  font-size: 0.6rem;
}
.button.small,
.has-button a.small:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.small:first-of-type,
.tabs-title > a {
  font-size: 0.75rem;
}
.button.large,
.has-button a.large:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.large:first-of-type {
  font-size: 1.25rem;
}
.button.expanded,
.has-button a.expanded:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.expanded:first-of-type {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.button.disabled,
.button.disabled.primary,
.button.disabled.primary:focus,
.button.disabled.primary:hover,
.button.disabled:focus,
.button.disabled:hover,
.button.primary,
.button[disabled],
.button[disabled].primary,
.button[disabled].primary:focus,
.button[disabled].primary:hover,
.button[disabled]:focus,
.button[disabled]:hover,
.has-button a.disabled.primary:first-of-type,
.has-button a.disabled:first-of-type,
.has-button a.primary:first-of-type,
.has-button a[disabled].primary:first-of-type,
.has-button a[disabled]:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.disabled:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.primary:first-of-type,
.hero-components
  .vc-table-scrolling__cell.has-button
  a[disabled]:first-of-type {
  background-color: #1779ba;
  color: #fefefe;
}
.button.primary:focus,
.button.primary:hover,
.has-button a.primary:focus:first-of-type,
.has-button a.primary:hover:first-of-type {
  background-color: #126195;
  color: #fefefe;
}
.button.disabled.secondary,
.button.disabled.secondary:focus,
.button.disabled.secondary:hover,
.button.secondary,
.button[disabled].secondary,
.button[disabled].secondary:focus,
.button[disabled].secondary:hover,
.has-button a.disabled.secondary:first-of-type,
.has-button a.secondary:first-of-type,
.has-button a[disabled].secondary:first-of-type,
.hero-components
  .vc-table-scrolling__cell.has-button
  a.secondary:first-of-type {
  background-color: #767676;
  color: #fefefe;
}
.button.secondary:focus,
.button.secondary:hover,
.has-button a.secondary:focus:first-of-type,
.has-button a.secondary:hover:first-of-type {
  background-color: #5e5e5e;
  color: #fefefe;
}
.button.disabled.success,
.button.disabled.success:focus,
.button.disabled.success:hover,
.button.success,
.button[disabled].success,
.button[disabled].success:focus,
.button[disabled].success:hover,
.has-button a.disabled.success:first-of-type,
.has-button a.success:first-of-type,
.has-button a[disabled].success:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.success:first-of-type {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button.success:focus,
.button.success:hover,
.has-button a.success:focus:first-of-type,
.has-button a.success:hover:first-of-type {
  background-color: #22bb5b;
  color: #0a0a0a;
}
.button.disabled.warning,
.button.disabled.warning:focus,
.button.disabled.warning:hover,
.button.warning,
.button[disabled].warning,
.button[disabled].warning:focus,
.button[disabled].warning:hover,
.has-button a.disabled.warning:first-of-type,
.has-button a.warning:first-of-type,
.has-button a[disabled].warning:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.warning:first-of-type {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button.warning:focus,
.button.warning:hover,
.has-button a.warning:focus:first-of-type,
.has-button a.warning:hover:first-of-type {
  background-color: #cc8b00;
  color: #0a0a0a;
}
.button.alert,
.button.disabled.alert,
.button.disabled.alert:focus,
.button.disabled.alert:hover,
.button[disabled].alert,
.button[disabled].alert:focus,
.button[disabled].alert:hover,
.has-button a.alert:first-of-type,
.has-button a.disabled.alert:first-of-type,
.has-button a[disabled].alert:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.alert:first-of-type {
  background-color: #cc4b37;
  color: #fefefe;
}
.button.alert:focus,
.button.alert:hover,
.has-button a.alert:focus:first-of-type,
.has-button a.alert:hover:first-of-type {
  background-color: #a53b2a;
  color: #fefefe;
}
.button.disabled.ratings,
.button.disabled.ratings:focus,
.button.disabled.ratings:hover,
.button.ratings,
.button[disabled].ratings,
.button[disabled].ratings:focus,
.button[disabled].ratings:hover,
.has-button a.disabled.ratings:first-of-type,
.has-button a.ratings:first-of-type,
.has-button a[disabled].ratings:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.ratings:first-of-type {
  background-color: #ffd800;
  color: #0a0a0a;
}
.button.ratings:focus,
.button.ratings:hover,
.has-button a.ratings:focus:first-of-type,
.has-button a.ratings:hover:first-of-type {
  background-color: #ccad00;
  color: #0a0a0a;
}
.button.accent-light,
.button.disabled.accent-light,
.button.disabled.accent-light:focus,
.button.disabled.accent-light:hover,
.button[disabled].accent-light,
.button[disabled].accent-light:focus,
.button[disabled].accent-light:hover,
.has-button a.accent-light:first-of-type,
.has-button a.disabled.accent-light:first-of-type,
.has-button a[disabled].accent-light:first-of-type,
.hero-components
  .vc-table-scrolling__cell.has-button
  a.accent-light:first-of-type {
  background-color: #d3d3ee;
  color: #0a0a0a;
}
.button.accent-light:focus,
.button.accent-light:hover,
.has-button a.accent-light:focus:first-of-type,
.has-button a.accent-light:hover:first-of-type {
  background-color: #9292d5;
  color: #0a0a0a;
}
.button.clear,
.button.clear.disabled,
.button.clear.disabled:focus,
.button.clear.disabled:hover,
.button.clear:focus,
.button.clear:hover,
.button.clear[disabled],
.button.clear[disabled]:focus,
.button.clear[disabled]:hover,
.button.hollow,
.button.hollow.disabled,
.button.hollow.disabled:focus,
.button.hollow.disabled:hover,
.button.hollow:focus,
.button.hollow:hover,
.button.hollow[disabled],
.button.hollow[disabled]:focus,
.button.hollow[disabled]:hover,
.has-button a.clear.disabled:first-of-type,
.has-button a.clear:first-of-type,
.has-button a.clear[disabled]:first-of-type,
.has-button a.hollow.disabled:first-of-type,
.has-button a.hollow:first-of-type,
.has-button a.hollow[disabled]:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.clear:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.hollow:first-of-type,
code {
  background-color: rgba(0, 0, 0, 0);
}
.button.disabled,
.button.disabled.accent-light,
.button.disabled.alert,
.button.disabled.primary,
.button.disabled.ratings,
.button.disabled.secondary,
.button.disabled.success,
.button.disabled.warning,
.button[disabled],
.button[disabled].accent-light,
.button[disabled].alert,
.button[disabled].primary,
.button[disabled].ratings,
.button[disabled].secondary,
.button[disabled].success,
.button[disabled].warning,
.has-button a.disabled.accent-light:first-of-type,
.has-button a.disabled.alert:first-of-type,
.has-button a.disabled.primary:first-of-type,
.has-button a.disabled.ratings:first-of-type,
.has-button a.disabled.secondary:first-of-type,
.has-button a.disabled.success:first-of-type,
.has-button a.disabled.warning:first-of-type,
.has-button a.disabled:first-of-type,
.has-button a[disabled].accent-light:first-of-type,
.has-button a[disabled].alert:first-of-type,
.has-button a[disabled].primary:first-of-type,
.has-button a[disabled].ratings:first-of-type,
.has-button a[disabled].secondary:first-of-type,
.has-button a[disabled].success:first-of-type,
.has-button a[disabled].warning:first-of-type,
.has-button a[disabled]:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.disabled:first-of-type,
.hero-components
  .vc-table-scrolling__cell.has-button
  a[disabled]:first-of-type {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.hollow,
.has-button a.hollow:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.hollow:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear.primary:focus,
.button.clear.primary:hover,
.button.clear:focus,
.button.clear:hover,
.button.hollow.primary:focus,
.button.hollow.primary:hover,
.button.hollow:focus,
.button.hollow:hover,
.has-button a.clear.primary:focus:first-of-type,
.has-button a.clear.primary:hover:first-of-type,
.has-button a.clear:focus:first-of-type,
.has-button a.clear:hover:first-of-type,
.has-button a.hollow.primary:focus:first-of-type,
.has-button a.hollow.primary:hover:first-of-type,
.has-button a.hollow:focus:first-of-type,
.has-button a.hollow:hover:first-of-type {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.hollow:focus.disabled,
.button.hollow:focus[disabled],
.button.hollow:hover.disabled,
.button.hollow:hover[disabled],
.has-button a.hollow:focus.disabled:first-of-type,
.has-button a.hollow:focus[disabled]:first-of-type,
.has-button a.hollow:hover.disabled:first-of-type,
.has-button a.hollow:hover[disabled]:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.primary,
.has-button a.hollow.primary:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.primary:focus.disabled,
.button.hollow.primary:focus[disabled],
.button.hollow.primary:hover.disabled,
.button.hollow.primary:hover[disabled],
.has-button a.hollow.primary:focus.disabled:first-of-type,
.has-button a.hollow.primary:focus[disabled]:first-of-type,
.has-button a.hollow.primary:hover.disabled:first-of-type,
.has-button a.hollow.primary:hover[disabled]:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.secondary,
.has-button a.hollow.secondary:first-of-type {
  border: 1px solid #767676;
  color: #767676;
}
.button.clear.secondary:focus,
.button.clear.secondary:hover,
.button.hollow.secondary:focus,
.button.hollow.secondary:hover,
.has-button a.clear.secondary:focus:first-of-type,
.has-button a.clear.secondary:hover:first-of-type,
.has-button a.hollow.secondary:focus:first-of-type,
.has-button a.hollow.secondary:hover:first-of-type {
  border-color: #3b3b3b;
  color: #3b3b3b;
}
.button.hollow.secondary:focus.disabled,
.button.hollow.secondary:focus[disabled],
.button.hollow.secondary:hover.disabled,
.button.hollow.secondary:hover[disabled],
.has-button a.hollow.secondary:focus.disabled:first-of-type,
.has-button a.hollow.secondary:focus[disabled]:first-of-type,
.has-button a.hollow.secondary:hover.disabled:first-of-type,
.has-button a.hollow.secondary:hover[disabled]:first-of-type {
  border: 1px solid #767676;
  color: #767676;
}
.button.hollow.success,
.has-button a.hollow.success:first-of-type {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success:focus,
.button.clear.success:hover,
.button.hollow.success:focus,
.button.hollow.success:hover,
.has-button a.clear.success:focus:first-of-type,
.has-button a.clear.success:hover:first-of-type,
.has-button a.hollow.success:focus:first-of-type,
.has-button a.hollow.success:hover:first-of-type {
  border-color: #157539;
  color: #157539;
}
.button.hollow.success:focus.disabled,
.button.hollow.success:focus[disabled],
.button.hollow.success:hover.disabled,
.button.hollow.success:hover[disabled],
.has-button a.hollow.success:focus.disabled:first-of-type,
.has-button a.hollow.success:focus[disabled]:first-of-type,
.has-button a.hollow.success:hover.disabled:first-of-type,
.has-button a.hollow.success:hover[disabled]:first-of-type {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.warning,
.has-button a.hollow.warning:first-of-type {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning:focus,
.button.clear.warning:hover,
.button.hollow.warning:focus,
.button.hollow.warning:hover,
.has-button a.clear.warning:focus:first-of-type,
.has-button a.clear.warning:hover:first-of-type,
.has-button a.hollow.warning:focus:first-of-type,
.has-button a.hollow.warning:hover:first-of-type {
  border-color: #805700;
  color: #805700;
}
.button.hollow.warning:focus.disabled,
.button.hollow.warning:focus[disabled],
.button.hollow.warning:hover.disabled,
.button.hollow.warning:hover[disabled],
.has-button a.hollow.warning:focus.disabled:first-of-type,
.has-button a.hollow.warning:focus[disabled]:first-of-type,
.has-button a.hollow.warning:hover.disabled:first-of-type,
.has-button a.hollow.warning:hover[disabled]:first-of-type {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.alert,
.has-button a.hollow.alert:first-of-type {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert:focus,
.button.clear.alert:hover,
.button.hollow.alert:focus,
.button.hollow.alert:hover,
.has-button a.clear.alert:focus:first-of-type,
.has-button a.clear.alert:hover:first-of-type,
.has-button a.hollow.alert:focus:first-of-type,
.has-button a.hollow.alert:hover:first-of-type {
  border-color: #67251a;
  color: #67251a;
}
.button.hollow.alert:focus.disabled,
.button.hollow.alert:focus[disabled],
.button.hollow.alert:hover.disabled,
.button.hollow.alert:hover[disabled],
.has-button a.hollow.alert:focus.disabled:first-of-type,
.has-button a.hollow.alert:focus[disabled]:first-of-type,
.has-button a.hollow.alert:hover.disabled:first-of-type,
.has-button a.hollow.alert:hover[disabled]:first-of-type {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.hollow.ratings,
.has-button a.hollow.ratings:first-of-type {
  border: 1px solid #ffd800;
  color: #ffd800;
}
.button.clear.ratings:focus,
.button.clear.ratings:hover,
.button.hollow.ratings:focus,
.button.hollow.ratings:hover,
.has-button a.clear.ratings:focus:first-of-type,
.has-button a.clear.ratings:hover:first-of-type,
.has-button a.hollow.ratings:focus:first-of-type,
.has-button a.hollow.ratings:hover:first-of-type {
  border-color: #806c00;
  color: #806c00;
}
.button.hollow.ratings:focus.disabled,
.button.hollow.ratings:focus[disabled],
.button.hollow.ratings:hover.disabled,
.button.hollow.ratings:hover[disabled],
.has-button a.hollow.ratings:focus.disabled:first-of-type,
.has-button a.hollow.ratings:focus[disabled]:first-of-type,
.has-button a.hollow.ratings:hover.disabled:first-of-type,
.has-button a.hollow.ratings:hover[disabled]:first-of-type {
  border: 1px solid #ffd800;
  color: #ffd800;
}
.button.hollow.accent-light,
.has-button a.hollow.accent-light:first-of-type {
  border: 1px solid #d3d3ee;
  color: #d3d3ee;
}
.button.clear.accent-light:focus,
.button.clear.accent-light:hover,
.button.hollow.accent-light:focus,
.button.hollow.accent-light:hover,
.has-button a.clear.accent-light:focus:first-of-type,
.has-button a.clear.accent-light:hover:first-of-type,
.has-button a.hollow.accent-light:focus:first-of-type,
.has-button a.hollow.accent-light:hover:first-of-type {
  border-color: #3f3fa2;
  color: #3f3fa2;
}
.button.hollow.accent-light:focus.disabled,
.button.hollow.accent-light:focus[disabled],
.button.hollow.accent-light:hover.disabled,
.button.hollow.accent-light:hover[disabled],
.has-button a.hollow.accent-light:focus.disabled:first-of-type,
.has-button a.hollow.accent-light:focus[disabled]:first-of-type,
.has-button a.hollow.accent-light:hover.disabled:first-of-type,
.has-button a.hollow.accent-light:hover[disabled]:first-of-type {
  border: 1px solid #d3d3ee;
  color: #d3d3ee;
}
.button.clear,
.has-button a.clear:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.clear:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear:focus.disabled,
.button.clear:focus[disabled],
.button.clear:hover.disabled,
.button.clear:hover[disabled],
.has-button a.clear:focus.disabled:first-of-type,
.has-button a.clear:focus[disabled]:first-of-type,
.has-button a.clear:hover.disabled:first-of-type,
.has-button a.clear:hover[disabled]:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear,
.button.clear.disabled,
.button.clear:focus,
.button.clear:focus.disabled,
.button.clear:focus[disabled],
.button.clear:hover,
.button.clear:hover.disabled,
.button.clear:hover[disabled],
.button.clear[disabled],
.has-button a.clear:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a.clear:first-of-type {
  border-color: transparent;
}
.button.clear.primary,
.has-button a.clear.primary:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear.primary:focus.disabled,
.button.clear.primary:focus[disabled],
.button.clear.primary:hover.disabled,
.button.clear.primary:hover[disabled],
.has-button a.clear.primary:focus.disabled:first-of-type,
.has-button a.clear.primary:focus[disabled]:first-of-type,
.has-button a.clear.primary:hover.disabled:first-of-type,
.has-button a.clear.primary:hover[disabled]:first-of-type {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.clear.primary,
.button.clear.primary.disabled,
.button.clear.primary:focus,
.button.clear.primary:focus.disabled,
.button.clear.primary:focus[disabled],
.button.clear.primary:hover,
.button.clear.primary:hover.disabled,
.button.clear.primary:hover[disabled],
.button.clear.primary[disabled],
.has-button a.clear.primary:first-of-type {
  border-color: transparent;
}
.button.clear.secondary,
.has-button a.clear.secondary:first-of-type {
  border: 1px solid #767676;
  color: #767676;
}
.button.clear.secondary:focus.disabled,
.button.clear.secondary:focus[disabled],
.button.clear.secondary:hover.disabled,
.button.clear.secondary:hover[disabled],
.has-button a.clear.secondary:focus.disabled:first-of-type,
.has-button a.clear.secondary:focus[disabled]:first-of-type,
.has-button a.clear.secondary:hover.disabled:first-of-type,
.has-button a.clear.secondary:hover[disabled]:first-of-type {
  border: 1px solid #767676;
  color: #767676;
}
.button.clear.secondary,
.button.clear.secondary.disabled,
.button.clear.secondary:focus,
.button.clear.secondary:focus.disabled,
.button.clear.secondary:focus[disabled],
.button.clear.secondary:hover,
.button.clear.secondary:hover.disabled,
.button.clear.secondary:hover[disabled],
.button.clear.secondary[disabled],
.has-button a.clear.secondary:first-of-type {
  border-color: transparent;
}
.button.clear.success,
.has-button a.clear.success:first-of-type {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success:focus.disabled,
.button.clear.success:focus[disabled],
.button.clear.success:hover.disabled,
.button.clear.success:hover[disabled],
.has-button a.clear.success:focus.disabled:first-of-type,
.has-button a.clear.success:focus[disabled]:first-of-type,
.has-button a.clear.success:hover.disabled:first-of-type,
.has-button a.clear.success:hover[disabled]:first-of-type {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success,
.button.clear.success.disabled,
.button.clear.success:focus,
.button.clear.success:focus.disabled,
.button.clear.success:focus[disabled],
.button.clear.success:hover,
.button.clear.success:hover.disabled,
.button.clear.success:hover[disabled],
.button.clear.success[disabled],
.has-button a.clear.success:first-of-type {
  border-color: transparent;
}
.button.clear.warning,
.has-button a.clear.warning:first-of-type {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning:focus.disabled,
.button.clear.warning:focus[disabled],
.button.clear.warning:hover.disabled,
.button.clear.warning:hover[disabled],
.has-button a.clear.warning:focus.disabled:first-of-type,
.has-button a.clear.warning:focus[disabled]:first-of-type,
.has-button a.clear.warning:hover.disabled:first-of-type,
.has-button a.clear.warning:hover[disabled]:first-of-type {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning,
.button.clear.warning.disabled,
.button.clear.warning:focus,
.button.clear.warning:focus.disabled,
.button.clear.warning:focus[disabled],
.button.clear.warning:hover,
.button.clear.warning:hover.disabled,
.button.clear.warning:hover[disabled],
.button.clear.warning[disabled],
.has-button a.clear.warning:first-of-type {
  border-color: transparent;
}
.button.clear.alert,
.has-button a.clear.alert:first-of-type {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert:focus.disabled,
.button.clear.alert:focus[disabled],
.button.clear.alert:hover.disabled,
.button.clear.alert:hover[disabled],
.has-button a.clear.alert:focus.disabled:first-of-type,
.has-button a.clear.alert:focus[disabled]:first-of-type,
.has-button a.clear.alert:hover.disabled:first-of-type,
.has-button a.clear.alert:hover[disabled]:first-of-type {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert,
.button.clear.alert.disabled,
.button.clear.alert:focus,
.button.clear.alert:focus.disabled,
.button.clear.alert:focus[disabled],
.button.clear.alert:hover,
.button.clear.alert:hover.disabled,
.button.clear.alert:hover[disabled],
.button.clear.alert[disabled],
.has-button a.clear.alert:first-of-type {
  border-color: transparent;
}
.button.clear.ratings,
.has-button a.clear.ratings:first-of-type {
  border: 1px solid #ffd800;
  color: #ffd800;
}
.button.clear.ratings:focus.disabled,
.button.clear.ratings:focus[disabled],
.button.clear.ratings:hover.disabled,
.button.clear.ratings:hover[disabled],
.has-button a.clear.ratings:focus.disabled:first-of-type,
.has-button a.clear.ratings:focus[disabled]:first-of-type,
.has-button a.clear.ratings:hover.disabled:first-of-type,
.has-button a.clear.ratings:hover[disabled]:first-of-type {
  border: 1px solid #ffd800;
  color: #ffd800;
}
.button.clear.ratings,
.button.clear.ratings.disabled,
.button.clear.ratings:focus,
.button.clear.ratings:focus.disabled,
.button.clear.ratings:focus[disabled],
.button.clear.ratings:hover,
.button.clear.ratings:hover.disabled,
.button.clear.ratings:hover[disabled],
.button.clear.ratings[disabled],
.has-button a.clear.ratings:first-of-type {
  border-color: transparent;
}
.button.clear.accent-light,
.has-button a.clear.accent-light:first-of-type {
  border: 1px solid #d3d3ee;
  color: #d3d3ee;
}
.button.clear.accent-light:focus.disabled,
.button.clear.accent-light:focus[disabled],
.button.clear.accent-light:hover.disabled,
.button.clear.accent-light:hover[disabled],
.has-button a.clear.accent-light:focus.disabled:first-of-type,
.has-button a.clear.accent-light:focus[disabled]:first-of-type,
.has-button a.clear.accent-light:hover.disabled:first-of-type,
.has-button a.clear.accent-light:hover[disabled]:first-of-type {
  border: 1px solid #d3d3ee;
  color: #d3d3ee;
}
.button.clear.accent-light,
.button.clear.accent-light.disabled,
.button.clear.accent-light:focus,
.button.clear.accent-light:focus.disabled,
.button.clear.accent-light:focus[disabled],
.button.clear.accent-light:hover,
.button.clear.accent-light:hover.disabled,
.button.clear.accent-light:hover[disabled],
.button.clear.accent-light[disabled],
.has-button a.clear.accent-light:first-of-type {
  border-color: transparent;
}
.button.dropdown::after,
.has-button a.dropdown:first-of-type::after,
.hero-components
  .vc-table-scrolling__cell.has-button
  a.dropdown:first-of-type::after {
  width: 0;
  height: 0;
  border: 0.4em inset;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  float: right;
  margin-left: 1em;
}

.button.dropdown.hollow.primary::after,
.button.dropdown.hollow::after,
.has-button a.dropdown.hollow.primary:first-of-type::after,
.has-button a.dropdown.hollow:first-of-type::after {
  border-top-color: #1779ba;
}
.button.dropdown.hollow.secondary::after,
.has-button a.dropdown.hollow.secondary:first-of-type::after {
  border-top-color: #767676;
}
.button.dropdown.hollow.success::after,
.has-button a.dropdown.hollow.success:first-of-type::after {
  border-top-color: #3adb76;
}
.button.dropdown.hollow.warning::after,
.has-button a.dropdown.hollow.warning:first-of-type::after {
  border-top-color: #ffae00;
}
.button.dropdown.hollow.alert::after,
.has-button a.dropdown.hollow.alert:first-of-type::after {
  border-top-color: #cc4b37;
}
.button.dropdown.hollow.ratings::after,
.has-button a.dropdown.hollow.ratings:first-of-type::after {
  border-top-color: #ffd800;
}
.button.dropdown.hollow.accent-light::after,
.has-button a.dropdown.hollow.accent-light:first-of-type::after {
  border-top-color: #d3d3ee;
}
.button.arrow-only::after,
.has-button a.arrow-only:first-of-type::after,
.hero-components
  .vc-table-scrolling__cell.has-button
  a.arrow-only:first-of-type::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}
.has-button a:focus:first-of-type,
.has-button a:hover:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:focus:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:hover:first-of-type,
a.button:focus,
a.button:hover {
  text-decoration: none;
}
.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
}
.has-button .menu a:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button .menu a:first-of-type,
.menu .button,
.menu .has-button a:first-of-type,
.menu .hero-components .vc-table-scrolling__cell.has-button a:first-of-type,
.menu a {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}
.menu,
.menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}
.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}
.cell.auto,
.menu.expanded li,
.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}
.menu.simple li + li {
  margin-left: 1rem;
}
.menu.simple a,
.reveal.collapse,
.tabs.simple > li > a {
  padding: 0;
}
.accordion-menu .is-accordion-submenu a,
.accordion-menu a,
.dropdown .is-dropdown-submenu a,
.dropdown.menu a,
.menu .menu-text {
  padding: 0.7rem 1rem;
}
@media print, screen and (min-width: 40em) {
  .reveal,
  .reveal.large,
  .reveal.small,
  .reveal.tiny {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .column,
  .columns,
  .medium-uncollapse > .column,
  .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0;
  }
  .medium-2,
  .medium-up-6 > .column,
  .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3,
  .medium-up-4 > .column,
  .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4,
  .medium-up-3 > .column,
  .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6,
  .medium-up-2 > .column,
  .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12,
  .medium-up-1 > .column,
  .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1,
  .medium-up-2,
  .medium-up-3,
  .medium-up-4,
  .medium-up-5,
  .medium-up-6,
  .medium-up-7,
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column,
  .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-7 > .column,
  .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 > .column,
  .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .menu.medium-expanded li,
  .menu.medium-simple li,
  .row.medium-unstack > .column,
  .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
  .medium-collapse > .column,
  .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .h1,
  h1 {
    font-size: 2.625rem;
  }
  .h2,
  h2 {
    font-size: 1.875rem;
  }
  .h3,
  h3 {
    font-size: 1.5rem;
  }
  .h4,
  h4 {
    font-size: 1.125rem;
  }
  .h5,
  h5 {
    font-size: 1rem;
  }
  .h6,
  h6 {
    font-size: 0.875rem;
  }
  .h7,
  h7 {
    font-size: 0.75rem;
  }
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .hide-for-medium {
    display: none !important;
  }
}
.accordion-menu .nested.is-accordion-submenu,
.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}
.menu.icon-left li a,
.menu.icon-right li a {
  flex-flow: row nowrap;
}
.menu.icon-left li a i,
.menu.icon-left li a img,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}
.menu.icon-right li a i,
.menu.icon-right li a img,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}
.menu.icon-bottom li a,
.menu.icon-top li a {
  flex-flow: column nowrap;
}
.menu.icon-bottom li a i,
.menu.icon-bottom li a img,
.menu.icon-bottom li a svg,
.menu.icon-top li a i,
.menu.icon-top li a img,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu .active > a,
.menu .is-active > a {
  background: #1779ba;
  color: #fefefe;
}
.menu-centered > .menu li .submenu li,
.menu.align-center li .submenu li,
.menu.align-left,
.menu.align-right li .submenu li {
  justify-content: flex-start;
}
.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}
.menu.align-right.vertical li {
  display: block;
  text-align: right;
}
.accordion-menu.align-right .nested.is-accordion-submenu,
.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}
.menu-centered > .menu li,
.menu.align-center li {
  display: flex;
  justify-content: center;
}
.menu .menu-text {
  font-weight: 700;
  line-height: 1;
  color: inherit;
}
.close-button:focus,
.close-button:hover,
code {
  color: #0a0a0a;
}
.no-js .dropdown.menu ul,
.no-js [data-responsive-menu] ul {
  display: none;
}
.menu-icon,
.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.menu-icon.dark::after,
.menu-icon::after {
  position: absolute;
  left: 0;
  display: block;
  height: 2px;
  content: "";
  width: 100%;
  top: 0;
}
.menu-icon::after {
  background: #ff2128;
  box-shadow: 0 7px 0 #ff2128, 0 14px 0 #ff2128;
}
.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}
.menu-icon.dark::after {
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
}
.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}
.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
}
.text--is-light,
.text--is-regular,
body {
  font-weight: 400;
}
.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
}
.top-bar,
.top-bar ul {
  background-color: #fff;
}
.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}
.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}
.grid-margin-x:not(.grid-x) > .cell,
.grid-margin-x > .auto,
.grid-margin-x > .shrink,
.grid-x > .auto,
.grid-x > .shrink,
.grid-y > .cell,
.top-bar input.button,
.zip-form {
  width: auto;
}
@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}
.aligncenter,
.float-center,
.reveal {
  margin-right: auto;
  margin-left: auto;
}
.cell.shrink,
.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}
@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}
@media screen and (min-width: 40em) and (max-width: 49.3125em) {
  .hide-for-medium-only {
    display: none !important;
  }
}
@media screen and (max-width: 39.9375em), screen and (min-width: 49.375em) {
  .show-for-medium-only {
    display: none !important;
  }
}
@media screen and (max-width: 49.3125em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .show-for-topbar {
    display: none !important;
  }
}
@media screen and (min-width: 49.375em) and (max-width: 63.9375em) {
  .hide-for-topbar-only {
    display: none !important;
  }
}
@media screen and (max-width: 49.3125em), screen and (min-width: 64em) {
  .show-for-topbar-only {
    display: none !important;
  }
}
@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-topbar {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-topbar .top-bar-left,
  .top-bar.stacked-for-topbar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .show-for-large {
    display: none !important;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}
@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}
.show-for-sr,
.show-on-focus,
.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
.availability-map__map-container,
.ctv-header,
.is-accordion-submenu-parent,
.is-dropdown-submenu-parent,
.sticky,
.sticky-container {
  position: relative;
}
.show-on-focus:active,
.show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}
@media screen and (orientation: landscape) {
  .hide-for-portrait,
  .show-for-landscape {
    display: block !important;
  }
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-portrait,
  .show-for-landscape {
    display: none !important;
  }
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.aligncenter,
.float-center {
  display: block;
}
.clearfix::after,
.clearfix::before,
.tabs::after,
.tabs::before {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.sticky {
  z-index: 0;
  transform: translate3d(0, 0, 0);
}
.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%;
}
.dropdown.menu.vertical > li .is-dropdown-submenu,
.sticky.is-stuck.is-at-top {
  top: 0;
}
.sticky.is-anchored.is-at-bottom,
.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}
.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}
html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none;
}
.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll;
}
.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0.3125rem;
  background-color: none;
  position: relative;
  top: 100px;
  overflow-y: auto;
}
.grid-container,
.grid-container.fluid {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.reveal .column,
.reveal .columns {
  min-width: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
    width: 600px;
    max-width: 75rem;
  }
  .reveal.tiny {
    width: 30%;
    max-width: 75rem;
  }
  .reveal.small {
    width: 50%;
    max-width: 75rem;
  }
  .reveal.large {
    width: 90%;
    max-width: 75rem;
  }
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
}
.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}
@media screen and (max-width: 39.9375em) {
  .hide-for-small-only,
  .show-for-medium {
    display: none !important;
  }
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}
.reveal.without-overlay {
  position: fixed;
}
.dropdown.menu.align-right .is-dropdown-submenu.first-sub,
.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}
.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}
.dropdown.menu .is-active > a {
  background: rgba(0, 0, 0, 0);
  color: #5050b9;
}
.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}
.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu,
.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}
.is-dropdown-menu.vertical {
  width: 100px;
}
.alignright,
.is-dropdown-menu.vertical.align-right,
.main-menu,
[dir="rtl"] .slick-slide {
  float: right;
}
.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}
.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}
.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}
.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 250px;
  border: 1px solid #cacaca;
  background: #fefefe;
}
.is-dropdown-submenu .is-dropdown-submenu {
  margin-top: -1px;
}
.has-submenu-toggle > a {
  margin-right: 40px;
}
.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: 6px inset;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent;
  top: 0;
  bottom: 0;
  margin: auto;
}
.tabs,
.tabs-content,
.tabs-content.vertical {
  border: 1px solid #e6e6e6;
}
.submenu-toggle[aria-expanded="true"]::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%;
}
.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}
.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}
.close-button,
.close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}
.grid-container {
  max-width: 75rem;
  margin: 0 auto;
}
.grid-container.fluid {
  max-width: 100%;
  margin: 0 auto;
}
.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin: 0 auto;
}
.cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
}
.grid-x > .small-1,
.grid-x > .small-10,
.grid-x > .small-11,
.grid-x > .small-12,
.grid-x > .small-2,
.grid-x > .small-3,
.grid-x > .small-4,
.grid-x > .small-5,
.grid-x > .small-6,
.grid-x > .small-7,
.grid-x > .small-8,
.grid-x > .small-9,
.grid-x > .small-full,
.grid-x > .small-shrink,
.grid-y > .small-1,
.grid-y > .small-10,
.grid-y > .small-11,
.grid-y > .small-12,
.grid-y > .small-2,
.grid-y > .small-3,
.grid-y > .small-4,
.grid-y > .small-5,
.grid-y > .small-6,
.grid-y > .small-7,
.grid-y > .small-8,
.grid-y > .small-9,
.grid-y > .small-full,
.grid-y > .small-shrink {
  flex-basis: auto;
}
.grid-x > .small-1,
.small-margin-collapse > .small-1 {
  width: 8.3333333333%;
}
.grid-x > .small-2,
.small-margin-collapse > .small-2,
.small-up-6 > .cell {
  width: 16.6666666667%;
}
.grid-x > .small-3,
.small-margin-collapse > .small-3,
.small-up-4 > .cell {
  width: 25%;
}
.grid-x > .small-4,
.small-margin-collapse > .small-4,
.small-up-3 > .cell {
  width: 33.3333333333%;
}
.grid-x > .small-5,
.small-margin-collapse > .small-5 {
  width: 41.6666666667%;
}
.grid-x > .small-6,
.small-margin-collapse > .small-6,
.small-up-2 > .cell {
  width: 50%;
}
.grid-x > .small-7,
.small-margin-collapse > .small-7 {
  width: 58.3333333333%;
}
.grid-x > .small-8,
.small-margin-collapse > .small-8 {
  width: 66.6666666667%;
}
.grid-x > .small-9,
.small-margin-collapse > .small-9 {
  width: 75%;
}
.grid-x > .small-10,
.small-margin-collapse > .small-10 {
  width: 83.3333333333%;
}
.grid-x > .small-11,
.small-margin-collapse > .small-11 {
  width: 91.6666666667%;
}
.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.grid-margin-x > .cell {
  width: calc(100% - 1.25rem);
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.grid-margin-x > .small-1 {
  width: calc(8.3333333333% - 1.25rem);
}
.grid-margin-x.small-up-6 > .cell,
.grid-margin-x > .small-2 {
  width: calc(16.6666666667% - 1.25rem);
}
.grid-margin-x.small-up-4 > .cell,
.grid-margin-x > .small-3 {
  width: calc(25% - 1.25rem);
}
.grid-margin-x.small-up-3 > .cell,
.grid-margin-x > .small-4 {
  width: calc(33.3333333333% - 1.25rem);
}
.grid-margin-x > .small-5 {
  width: calc(41.6666666667% - 1.25rem);
}
.grid-margin-x.small-up-2 > .cell,
.grid-margin-x > .small-6 {
  width: calc(50% - 1.25rem);
}
.grid-margin-x > .small-7 {
  width: calc(58.3333333333% - 1.25rem);
}
.grid-margin-x > .small-8 {
  width: calc(66.6666666667% - 1.25rem);
}
.grid-margin-x > .small-9 {
  width: calc(75% - 1.25rem);
}
.grid-margin-x > .small-10 {
  width: calc(83.3333333333% - 1.25rem);
}
.grid-margin-x > .small-11 {
  width: calc(91.6666666667% - 1.25rem);
}
.grid-margin-x.small-up-1 > .cell,
.grid-margin-x > .small-12 {
  width: calc(100% - 1.25rem);
}
.full-width-background .full-width-hero-card2 .contents .column-4,
.small-up-5 > .cell {
  width: 20%;
}
.small-up-7 > .cell {
  width: 14.2857142857%;
}
.small-up-8 > .cell {
  width: 12.5%;
}
.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem);
}
.grid-margin-x.small-up-7 > .cell {
  width: calc(14.2857142857% - 1.25rem);
}
.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem);
}
.channel-lineup__filters,
.small-offset-0 {
  margin-left: 0;
}
.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem);
}
.small-offset-1 {
  margin-left: 8.3333333333%;
}
.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.3333333333% + 0.625rem);
}
.small-offset-2 {
  margin-left: 16.6666666667%;
}
.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.6666666667% + 0.625rem);
}
.small-offset-3 {
  margin-left: 25%;
}
.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem);
}
.small-offset-4 {
  margin-left: 33.3333333333%;
}
.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.3333333333% + 0.625rem);
}
.small-offset-5 {
  margin-left: 41.6666666667%;
}
.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.6666666667% + 0.625rem);
}
.small-offset-6 {
  margin-left: 50%;
}
.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem);
}
.small-offset-7 {
  margin-left: 58.3333333333%;
}
.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.3333333333% + 0.625rem);
}
.small-offset-8 {
  margin-left: 66.6666666667%;
}
.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.6666666667% + 0.625rem);
}
.small-offset-9 {
  margin-left: 75%;
}
.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem);
}
.small-offset-10 {
  margin-left: 83.3333333333%;
}
.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.3333333333% + 0.625rem);
}
.small-offset-11 {
  margin-left: 91.6666666667%;
}
.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.6666666667% + 0.625rem);
}
.grid-y {
  display: flex;
  flex-flow: column nowrap;
}
.grid-y > .small-1 {
  height: 8.3333333333%;
}
.grid-y > .small-2 {
  height: 16.6666666667%;
}
.grid-y > .small-3 {
  height: 25%;
}
.grid-y > .small-4 {
  height: 33.3333333333%;
}
.grid-y > .small-5 {
  height: 41.6666666667%;
}
.grid-y > .small-6 {
  height: 50%;
}
.grid-y > .small-7 {
  height: 58.3333333333%;
}
.grid-y > .small-8 {
  height: 66.6666666667%;
}
.grid-y > .small-9 {
  height: 75%;
}
.grid-y > .small-10 {
  height: 83.3333333333%;
}
.grid-y > .small-11 {
  height: 91.6666666667%;
}
.cell .grid-y.grid-frame,
.grid-y > .small-12 {
  height: 100%;
}
.grid-margin-y,
.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.hero--generic-blog.no-padding-bottom,
.hero--generic.no-padding-bottom {
  padding-bottom: 0;
}
.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}
.cell-block,
.cell-block-y {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar;
}
.cell-block {
  overflow-x: auto;
  max-width: 100%;
}
.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
}
.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}
.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
}
.grid-margin-y > .cell {
  height: calc(100% - 1.25rem);
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.grid-margin-y > .small-1 {
  height: calc(8.3333333333% - 1.25rem);
}
.grid-margin-y > .small-2 {
  height: calc(16.6666666667% - 1.25rem);
}
.grid-margin-y > .small-3 {
  height: calc(25% - 1.25rem);
}
.grid-margin-y > .small-4 {
  height: calc(33.3333333333% - 1.25rem);
}
.grid-margin-y > .small-5 {
  height: calc(41.6666666667% - 1.25rem);
}
.grid-margin-y > .small-6 {
  height: calc(50% - 1.25rem);
}
.grid-margin-y > .small-7 {
  height: calc(58.3333333333% - 1.25rem);
}
.grid-margin-y > .small-8 {
  height: calc(66.6666666667% - 1.25rem);
}
.grid-margin-y > .small-9 {
  height: calc(75% - 1.25rem);
}
.grid-margin-y > .small-10 {
  height: calc(83.3333333333% - 1.25rem);
}
.grid-margin-y > .small-11 {
  height: calc(91.6666666667% - 1.25rem);
}
.grid-margin-y > .small-12 {
  height: calc(100% - 1.25rem);
}
.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem);
}
.tabs {
  margin: 0;
  background: #fefefe;
  list-style-type: none;
}
.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}
.tabs.simple > li > a:hover {
  background: rgba(0, 0, 0, 0);
}
.tabs.primary {
  background: #1779ba;
}
.tabs.primary > li > a {
  color: #fefefe;
}
.tabs.primary > li > a:focus,
.tabs.primary > li > a:hover {
  background: #1673b1;
}
.tabs-title {
  float: left;
}
.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  line-height: 1;
  color: #1779ba;
}
.tabs-title > a:hover {
  background: #fefefe;
  color: #1468a0;
}
.tabs-title > a:focus,
.tabs-title > a[aria-selected="true"] {
  background: #e6e6e6;
  color: #1779ba;
}
.tabs-content {
  border-top: 0;
  background: #fefefe;
  color: #151515;
  transition: 0.5s;
}
.hero--generic,
.hero--generic-blog {
  background-color: #202050;
  background-image: linear-gradient(30deg, #0a0a19, #202050);
  background-position-x: center;
  text-align: center;
}
.hero--generic,
.hero--generic-blog,
.section--image-bg {
  background-repeat: no-repeat;
  background-size: cover;
}
.tabs-content.vertical {
  border-left: 0;
}
.tabs-panel {
  display: none;
  padding: 1rem;
}
body {
  min-width: 320px;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
}
.text--is-bold {
  font-weight: 700;
}
.hero,
code {
  font-weight: 400;
}
.text--no-wrap {
  white-space: nowrap;
}
.block,
.footer__logo,
.main-menu #toggle-menu:checked + .main-menu__container,
.slick-initialized .slick-slide,
.slick-slide img,
.text--is-block {
  display: block;
}
.inline-block,
.text--is-inline-block {
  display: inline-block;
}
.text--is-underlined {
  text-decoration: underline;
}
.text--is-not-underlined {
  text-decoration: none;
}
.text--is-error {
  color: red;
  font-size: 0.8rem;
}
.text--is-uppercase {
  text-transform: uppercase;
}
.margin-center {
  margin: 0 auto;
}
.margin-bottom {
  margin-bottom: 15px !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 0;
  font-family: Courier, "New Courier", monospace;
  font-size: 14px;
}
.hero--generic h1,
h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-transform: uppercase;
}
:root:root .row.expanded .row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
}
.sr-only {
  position: absolute;
  top: auto;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.max-100 {
  max-width: 100% !important;
  width: 100% !important;
}
.padding-top-mini {
  padding-top: 5px;
}
.padding--left-30 {
  padding-left: 1.875rem;
}
.padding--right-30 {
  padding-right: 1.875rem;
}
.hero {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
}
@media print, screen and (min-width: 40em) {
  .grid-container,
  .grid-container.fluid,
  .grid-padding-x > .cell {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .grid-x > .medium-1,
  .grid-x > .medium-10,
  .grid-x > .medium-11,
  .grid-x > .medium-12,
  .grid-x > .medium-2,
  .grid-x > .medium-3,
  .grid-x > .medium-4,
  .grid-x > .medium-5,
  .grid-x > .medium-6,
  .grid-x > .medium-7,
  .grid-x > .medium-8,
  .grid-x > .medium-9,
  .grid-x > .medium-full,
  .grid-x > .medium-shrink,
  .grid-y > .medium-1,
  .grid-y > .medium-10,
  .grid-y > .medium-11,
  .grid-y > .medium-12,
  .grid-y > .medium-2,
  .grid-y > .medium-3,
  .grid-y > .medium-4,
  .grid-y > .medium-5,
  .grid-y > .medium-6,
  .grid-y > .medium-7,
  .grid-y > .medium-8,
  .grid-y > .medium-9,
  .grid-y > .medium-full,
  .grid-y > .medium-shrink {
    flex-basis: auto;
  }
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .medium-1,
  .medium-margin-collapse > .medium-1,
  .medium-margin-collapse > .small-1,
  .small-margin-collapse > .medium-1 {
    width: 8.3333333333%;
  }
  .grid-x > .medium-2,
  .medium-margin-collapse > .medium-2,
  .medium-margin-collapse > .small-2,
  .medium-up-6 > .cell,
  .small-margin-collapse > .medium-2 {
    width: 16.6666666667%;
  }
  .grid-x > .medium-3,
  .medium-margin-collapse > .medium-3,
  .medium-margin-collapse > .small-3,
  .medium-up-4 > .cell,
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .grid-x > .medium-4,
  .medium-margin-collapse > .medium-4,
  .medium-margin-collapse > .small-4,
  .medium-up-3 > .cell,
  .small-margin-collapse > .medium-4 {
    width: 33.3333333333%;
  }
  .grid-x > .medium-5,
  .medium-margin-collapse > .medium-5,
  .medium-margin-collapse > .small-5,
  .small-margin-collapse > .medium-5 {
    width: 41.6666666667%;
  }
  .grid-x > .medium-6,
  .medium-margin-collapse > .medium-6,
  .medium-margin-collapse > .small-6,
  .medium-up-2 > .cell,
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .grid-x > .medium-7,
  .medium-margin-collapse > .medium-7,
  .medium-margin-collapse > .small-7,
  .small-margin-collapse > .medium-7 {
    width: 58.3333333333%;
  }
  .grid-x > .medium-8,
  .medium-margin-collapse > .medium-8,
  .medium-margin-collapse > .small-8,
  .small-margin-collapse > .medium-8 {
    width: 66.6666666667%;
  }
  .grid-x > .medium-9,
  .medium-margin-collapse > .medium-9,
  .medium-margin-collapse > .small-9,
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .grid-x > .medium-10,
  .medium-margin-collapse > .medium-10,
  .medium-margin-collapse > .small-10,
  .small-margin-collapse > .medium-10 {
    width: 83.3333333333%;
  }
  .grid-x > .medium-11,
  .medium-margin-collapse > .medium-11,
  .medium-margin-collapse > .small-11,
  .small-margin-collapse > .medium-11 {
    width: 91.6666666667%;
  }
  .cell .medium-grid-frame,
  .grid-x > .medium-12,
  .medium-margin-collapse > .medium-12,
  .medium-margin-collapse > .small-12,
  .medium-up-1 > .cell,
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
  .grid-margin-x {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .grid-margin-x > .auto,
  .grid-margin-x > .medium-auto,
  .grid-margin-x > .medium-shrink,
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1,
  .grid-margin-x > .small-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x.medium-up-6 > .cell,
  .grid-margin-x > .medium-2,
  .grid-margin-x > .small-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x.medium-up-4 > .cell,
  .grid-margin-x > .medium-3,
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.medium-up-3 > .cell,
  .grid-margin-x > .medium-4,
  .grid-margin-x > .small-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-5,
  .grid-margin-x > .small-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x.medium-up-2 > .cell,
  .grid-margin-x > .medium-6,
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .medium-7,
  .grid-margin-x > .small-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-8,
  .grid-margin-x > .small-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .medium-9,
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .medium-10,
  .grid-margin-x > .small-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .medium-11,
  .grid-margin-x > .small-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x.medium-up-1 > .cell,
  .grid-margin-x > .medium-12,
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem);
  }
  .grid-container:not(.full) > .grid-padding-x,
  .grid-padding-x .grid-padding-x {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .medium-up-5 > .cell {
    width: 20%;
  }
  .medium-up-7 > .cell {
    width: 14.2857142857%;
  }
  .medium-up-8 > .cell {
    width: 12.5%;
  }
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem);
  }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem);
  }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.3333333333% - 1.25rem);
  }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem);
  }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem);
  }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.6666666667% - 1.25rem);
  }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.2857142857% - 1.25rem);
  }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem);
  }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
  .medium-margin-collapse,
  .medium-margin-collapse > .cell,
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .hero,
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-offset-0 {
    margin-left: 0;
  }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem);
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.3333333333% + 0.9375rem);
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.6666666667% + 0.9375rem);
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem);
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.3333333333% + 0.9375rem);
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.6666666667% + 0.9375rem);
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem);
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.3333333333% + 0.9375rem);
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.6666666667% + 0.9375rem);
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem);
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.3333333333% + 0.9375rem);
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.6666666667% + 0.9375rem);
  }
  .grid-y > .medium-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-margin-y > .auto,
  .grid-margin-y > .medium-auto,
  .grid-margin-y > .medium-shrink,
  .grid-margin-y > .shrink,
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.3333333333%;
  }
  .grid-y > .medium-2 {
    height: 16.6666666667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.3333333333%;
  }
  .grid-y > .medium-5 {
    height: 41.6666666667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.3333333333%;
  }
  .grid-y > .medium-8 {
    height: 66.6666666667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.3333333333%;
  }
  .grid-y > .medium-11 {
    height: 91.6666666667%;
  }
  .cell .grid-y.medium-grid-frame,
  .grid-y > .medium-12 {
    height: 100%;
  }
  .grid-margin-y,
  .grid-padding-y .grid-padding-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .medium-cell-block,
  .medium-cell-block-y {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
  }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
  }
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .grid-margin-y > .medium-1,
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-2,
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-3,
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .medium-4,
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-5,
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-6,
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .medium-7,
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-8,
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-9,
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .medium-10,
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .medium-11,
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .medium-12,
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-frame.grid-margin-y,
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem);
  }
  .margin-bottom {
    margin-bottom: 30px !important;
  }
}
.hero--generic {
  position: relative;
  padding: 15px 0;
  color: #fff;
}
@media print, screen and (min-width: 40em) {
  .hero--generic {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hero--generic h1 {
    font-size: 2.625rem;
    line-height: 3rem;
  }
}
.hero--generic h1 {
  font-weight: 700;
  margin: 0 auto 15px;
}
.hero--generic h5,
.hero--generic-blog h1,
.hero--generic-blog h5 {
  margin: 0 auto 2px;
  font-weight: 700;
}
.hero--generic h1 .heading--geo-small-text {
  font-weight: 700;
  display: block;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
@media print, screen and (min-width: 40em) {
  .hero--generic h1 {
    width: 60%;
  }
  .hero--generic h1 .heading--geo-small-text {
    font-size: 1rem;
  }
  .hero--generic h1.heading--is-not-constrained {
    width: 100%;
  }
  .hero--generic h2.subtitle {
    font-size: 1.125rem;
  }
}
.hero--generic h1.entry-title {
  padding: 0;
  text-align: center;
  box-sizing: border-box;
}
.hero--generic h2.subtitle {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 1.125rem;
  text-align: center;
}
.hero--generic h5 {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.hero--generic-blog h1,
h4,
h5,
p {
  line-height: 1.5rem;
}
.hero--generic table a {
  color: #5050b9;
}
.has-button .hero--generic table a:first-of-type,
.has-button .hero--generic table a:hover:first-of-type,
.hero--generic table .has-button a:first-of-type,
.hero--generic table .has-button a:hover:first-of-type,
.hero--generic
  table
  .hero-components
  .vc-table-scrolling__cell.has-button
  a:first-of-type,
.hero--generic table a.button--generic,
.hero--generic table a.button--generic:hover,
.hero-components
  .vc-table-scrolling__cell.has-button
  .hero--generic
  table
  a:first-of-type {
  color: #fefefe;
}
.hero--generic table a:hover,
.main-menu ul.main-menu__ul > li:hover > a,
.main-menu ul.main-menu__ul > li > ul > li:hover a {
  color: #ff2128;
}
@media print, screen and (min-width: 64em) {
  .grid-container:not(.full) > .grid-padding-x,
  .grid-padding-x .grid-padding-x,
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-2,
  .large-up-6 > .column,
  .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-3,
  .large-up-4 > .column,
  .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-4,
  .large-up-3 > .column,
  .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-6,
  .large-up-2 > .column,
  .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-12,
  .large-up-1 > .column,
  .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-1,
  .large-up-2,
  .large-up-3,
  .large-up-4,
  .large-up-5,
  .large-up-6,
  .large-up-7,
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column,
  .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-7 > .column,
  .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 > .column,
  .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .menu.large-expanded li,
  .menu.large-simple li,
  .row.large-unstack > .column,
  .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
  .large-collapse > .column,
  .large-collapse > .columns,
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
  .grid-container,
  .grid-container.fluid,
  .grid-padding-x > .cell,
  .large-uncollapse > .column,
  .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .hide-for-large {
    display: none !important;
  }
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .grid-x > .large-1,
  .grid-x > .large-10,
  .grid-x > .large-11,
  .grid-x > .large-12,
  .grid-x > .large-2,
  .grid-x > .large-3,
  .grid-x > .large-4,
  .grid-x > .large-5,
  .grid-x > .large-6,
  .grid-x > .large-7,
  .grid-x > .large-8,
  .grid-x > .large-9,
  .grid-x > .large-full,
  .grid-x > .large-shrink,
  .grid-y > .large-1,
  .grid-y > .large-10,
  .grid-y > .large-11,
  .grid-y > .large-12,
  .grid-y > .large-2,
  .grid-y > .large-3,
  .grid-y > .large-4,
  .grid-y > .large-5,
  .grid-y > .large-6,
  .grid-y > .large-7,
  .grid-y > .large-8,
  .grid-y > .large-9,
  .grid-y > .large-full,
  .grid-y > .large-shrink {
    flex-basis: auto;
  }
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .large-1,
  .large-margin-collapse > .large-1,
  .large-margin-collapse > .medium-1,
  .large-margin-collapse > .small-1,
  .medium-margin-collapse > .large-1,
  .small-margin-collapse > .large-1 {
    width: 8.3333333333%;
  }
  .grid-x > .large-2,
  .large-margin-collapse > .large-2,
  .large-margin-collapse > .medium-2,
  .large-margin-collapse > .small-2,
  .large-up-6 > .cell,
  .medium-margin-collapse > .large-2,
  .small-margin-collapse > .large-2 {
    width: 16.6666666667%;
  }
  .grid-x > .large-3,
  .large-margin-collapse > .large-3,
  .large-margin-collapse > .medium-3,
  .large-margin-collapse > .small-3,
  .large-up-4 > .cell,
  .medium-margin-collapse > .large-3,
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .grid-x > .large-4,
  .large-margin-collapse > .large-4,
  .large-margin-collapse > .medium-4,
  .large-margin-collapse > .small-4,
  .large-up-3 > .cell,
  .medium-margin-collapse > .large-4,
  .small-margin-collapse > .large-4 {
    width: 33.3333333333%;
  }
  .grid-x > .large-5,
  .large-margin-collapse > .large-5,
  .large-margin-collapse > .medium-5,
  .large-margin-collapse > .small-5,
  .medium-margin-collapse > .large-5,
  .small-margin-collapse > .large-5 {
    width: 41.6666666667%;
  }
  .grid-x > .large-6,
  .large-margin-collapse > .large-6,
  .large-margin-collapse > .medium-6,
  .large-margin-collapse > .small-6,
  .large-up-2 > .cell,
  .medium-margin-collapse > .large-6,
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .grid-x > .large-7,
  .large-margin-collapse > .large-7,
  .large-margin-collapse > .medium-7,
  .large-margin-collapse > .small-7,
  .medium-margin-collapse > .large-7,
  .small-margin-collapse > .large-7 {
    width: 58.3333333333%;
  }
  .grid-x > .large-8,
  .large-margin-collapse > .large-8,
  .large-margin-collapse > .medium-8,
  .large-margin-collapse > .small-8,
  .medium-margin-collapse > .large-8,
  .small-margin-collapse > .large-8 {
    width: 66.6666666667%;
  }
  .grid-x > .large-9,
  .large-margin-collapse > .large-9,
  .large-margin-collapse > .medium-9,
  .large-margin-collapse > .small-9,
  .medium-margin-collapse > .large-9,
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .grid-x > .large-10,
  .large-margin-collapse > .large-10,
  .large-margin-collapse > .medium-10,
  .large-margin-collapse > .small-10,
  .medium-margin-collapse > .large-10,
  .small-margin-collapse > .large-10 {
    width: 83.3333333333%;
  }
  .grid-x > .large-11,
  .large-margin-collapse > .large-11,
  .large-margin-collapse > .medium-11,
  .large-margin-collapse > .small-11,
  .medium-margin-collapse > .large-11,
  .small-margin-collapse > .large-11 {
    width: 91.6666666667%;
  }
  .cell .large-grid-frame,
  .grid-x > .large-12,
  .large-margin-collapse > .large-12,
  .large-margin-collapse > .medium-12,
  .large-margin-collapse > .small-12,
  .large-up-1 > .cell,
  .medium-margin-collapse > .large-12,
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
  .grid-margin-x {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .grid-margin-x > .cell {
    width: calc(100% - 1.875rem);
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .grid-margin-x > .auto,
  .grid-margin-x > .large-auto,
  .grid-margin-x > .large-shrink,
  .grid-margin-x > .medium-auto,
  .grid-margin-x > .medium-shrink,
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .large-1,
  .grid-margin-x > .medium-1,
  .grid-margin-x > .small-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x.large-up-6 > .cell,
  .grid-margin-x.medium-up-6 > .cell,
  .grid-margin-x > .large-2,
  .grid-margin-x > .medium-2,
  .grid-margin-x > .small-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x.large-up-4 > .cell,
  .grid-margin-x.medium-up-4 > .cell,
  .grid-margin-x > .large-3,
  .grid-margin-x > .medium-3,
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.large-up-3 > .cell,
  .grid-margin-x.medium-up-3 > .cell,
  .grid-margin-x > .large-4,
  .grid-margin-x > .medium-4,
  .grid-margin-x > .small-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-5,
  .grid-margin-x > .medium-5,
  .grid-margin-x > .small-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x.large-up-2 > .cell,
  .grid-margin-x.medium-up-2 > .cell,
  .grid-margin-x > .large-6,
  .grid-margin-x > .medium-6,
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .large-7,
  .grid-margin-x > .medium-7,
  .grid-margin-x > .small-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-8,
  .grid-margin-x > .medium-8,
  .grid-margin-x > .small-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .large-9,
  .grid-margin-x > .medium-9,
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .large-10,
  .grid-margin-x > .medium-10,
  .grid-margin-x > .small-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .large-11,
  .grid-margin-x > .medium-11,
  .grid-margin-x > .small-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x.large-up-1 > .cell,
  .grid-margin-x.medium-up-1 > .cell,
  .grid-margin-x > .large-12,
  .grid-margin-x > .medium-12,
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.875rem);
  }
  .large-up-5 > .cell {
    width: 20%;
  }
  .large-up-7 > .cell {
    width: 14.2857142857%;
  }
  .large-up-8 > .cell {
    width: 12.5%;
  }
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem);
  }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem);
  }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.3333333333% - 1.25rem);
  }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem);
  }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem);
  }
  .grid-margin-x.large-up-5 > .cell,
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.6666666667% - 1.25rem);
  }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.2857142857% - 1.25rem);
  }
  .grid-margin-x.large-up-7 > .cell,
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem);
  }
  .grid-margin-x.large-up-8 > .cell,
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
  .large-margin-collapse,
  .large-margin-collapse > .cell,
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-offset-0 {
    margin-left: 0;
  }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem);
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.3333333333% + 0.9375rem);
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.6666666667% + 0.9375rem);
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem);
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.3333333333% + 0.9375rem);
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.6666666667% + 0.9375rem);
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem);
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.3333333333% + 0.9375rem);
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.6666666667% + 0.9375rem);
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem);
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.3333333333% + 0.9375rem);
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.6666666667% + 0.9375rem);
  }
  .grid-y > .large-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-margin-y > .auto,
  .grid-margin-y > .large-auto,
  .grid-margin-y > .large-shrink,
  .grid-margin-y > .medium-auto,
  .grid-margin-y > .medium-shrink,
  .grid-margin-y > .shrink,
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.3333333333%;
  }
  .grid-y > .large-2 {
    height: 16.6666666667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.3333333333%;
  }
  .grid-y > .large-5 {
    height: 41.6666666667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.3333333333%;
  }
  .grid-y > .large-8 {
    height: 66.6666666667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.3333333333%;
  }
  .grid-y > .large-11 {
    height: 91.6666666667%;
  }
  .cell .grid-y.large-grid-frame,
  .grid-y > .large-12 {
    height: 100%;
  }
  .grid-margin-y,
  .grid-padding-y .grid-padding-y {
    margin-top: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
  .grid-padding-y > .cell {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .large-cell-block,
  .large-cell-block-y {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
  }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
  }
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
  .grid-margin-y > .cell {
    height: calc(100% - 1.875rem);
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .grid-margin-y > .large-1,
  .grid-margin-y > .medium-1,
  .grid-margin-y > .small-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-2,
  .grid-margin-y > .medium-2,
  .grid-margin-y > .small-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-3,
  .grid-margin-y > .medium-3,
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .large-4,
  .grid-margin-y > .medium-4,
  .grid-margin-y > .small-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-5,
  .grid-margin-y > .medium-5,
  .grid-margin-y > .small-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-6,
  .grid-margin-y > .medium-6,
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .large-7,
  .grid-margin-y > .medium-7,
  .grid-margin-y > .small-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-8,
  .grid-margin-y > .medium-8,
  .grid-margin-y > .small-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-9,
  .grid-margin-y > .medium-9,
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .large-10,
  .grid-margin-y > .medium-10,
  .grid-margin-y > .small-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .large-11,
  .grid-margin-y > .medium-11,
  .grid-margin-y > .small-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .large-12,
  .grid-margin-y > .medium-12,
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-frame.grid-margin-y,
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem);
  }
  .hero--generic {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .hero--generic-blog.no-padding-bottom,
  .hero--generic.no-padding-bottom {
    padding-bottom: 0;
  }
}
.hero--generic img:not(.avatar) {
  width: 200px;
}
.hero--generic.hero__bg--internet-plans,
.hero--generic.hero__bg--view-plans {
  background-color: #576a7a;
}
.hero--generic.hero__bg--has-no-image {
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}
.hero--generic p.subtitle {
  margin-bottom: 1.125rem;
  text-align: center;
}
.hero--generic p.subtitle,
.hero--generic p.subtitle-header {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: inherit;
  font-family: Oswald, Helvetica, Arial, sans-serif;
}
.hero--generic-blog {
  position: relative;
  padding: 15px 0;
  color: #fff;
}
@media print, screen and (min-width: 40em) {
  .hero--generic h5 {
    font-size: 1rem;
    width: 100%;
  }
  .hero--generic.no-padding-bottom {
    padding-bottom: 0;
  }
  .hero--generic p.subtitle-header {
    font-size: 1.125rem;
  }
  .hero--generic-blog {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.hero--generic-blog h1 {
  text-transform: uppercase;
  font-size: 1.375rem;
}
@media print, screen and (min-width: 40em) {
  .hero--generic-blog h1 {
    font-size: 2.625rem;
    line-height: 3rem;
    width: 60%;
  }
  .hero--generic-blog h1.heading--is-not-constrained {
    width: 100%;
  }
}
.hero--generic-blog h5 {
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.hero--generic-blog a,
.hero--generic-blog a:hover {
  color: #fff;
}
.hero--spectrum .heading-channel-lineup a,
.hero--time-warner .heading-channel-lineup a {
  color: inherit;
}
.hero--generic-blog table a {
  color: #5050b9;
  text-decoration: none;
}
.hero--generic-blog table a:hover {
  color: #ff2128;
  text-decoration: underline;
}
.hero--view-calc {
  color: #fff;
}
.hero--buckeye-broadband {
  background-color: #11407a;
}
.hero--spectrum {
  color: #fff;
}
.hero--time-warner {
  background-color: #01325d;
  background-image: linear-gradient(to right, #01325d 0, #0061a4 100%);
}
.hero__flex-center,
.zip-form--is-centered {
  display: flex;
  justify-content: center;
}
.hero__breadcrumb {
  display: flex;
  align-items: center;
  opacity: 0.5;
}
.hero__breadcrumb__logo {
  width: 58px;
  height: 16px;
}
.hero__breadcrumb__delimiter {
  margin-right: 5px;
  margin-left: 5px;
}
.hero__breadcrumb__delimiter svg {
  width: 12px;
  height: 12px;
}
.hero__breadcrumb__page {
  margin-top: 5px;
  font-size: 12px;
}
.hero__breadcrumb__page a {
  color: #fff;
  text-decoration: none;
}
.hero__breadcrumb__page a:hover {
  color: #f2f2f2;
  text-decoration: underline;
}
.header__promo {
  display: none;
  background-color: #151515;
}
.header__promo--frontier .header__promo__callout {
  width: 60%;
  font-size: 1rem;
}
.header__promo--time-warner {
  background-color: #01325d;
}
.change-location-link-area,
.has-menu,
.header-search,
.header__promo__phone {
  align-self: center;
}
.header__promo__phone--is-hidden {
  display: none;
}
.header__promo .cl-phone {
  display: flex;
}
.header__promo .cl-phone .header__promo__link {
  align-self: center;
  margin: 0 1.5rem;
  color: inherit;
}
.header__promo .cl-phone .header__promo__link:hover {
  color: inherit;
}
.header__promo__callout {
  align-self: center;
  margin-right: 0.5rem;
}
.header__promo__link {
  align-self: center;
  margin: 0 1.5rem;
  color: inherit;
  text-decoration: none;
}
.header__promo__link:hover {
  color: inherit;
  text-decoration: underline;
}
.header__promo__root-site-link {
  display: inline-block;
  align-self: center;
  max-width: 150px;
  margin-top: 5px;
}
.header__promo__root-site-link svg {
  width: 100px;
}
.site__footer {
  padding: 2.1875rem 0;
  color: #5b5b5b;
}
.site__footer a {
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
}
.site__footer a:hover {
  text-decoration: underline;
}
.site__footer #ot-sdk-btn.ot-sdk-show-settings {
  all: unset;
  font-size: 0.875rem;
  color: #fff;
}
.site__footer #ot-sdk-btn.ot-sdk-show-settings:hover {
  color: #df432b;
  text-decoration: underline;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
}
.site__footer__disclaimer {
  margin: 0;
  padding: 0;
  color: #5b5b5b;
  text-align: center;
}
.footer__column {
  margin-bottom: 30px;
  border-right: 1px solid #c5c5c5;
}
@media print, screen and (min-width: 64em) {
  .footer__column {
    margin-bottom: 0;
  }
}
.footer__column:nth-child(2),
.footer__column:nth-child(3) {
  margin-bottom: 30px;
  border-right-width: 0;
}
.footer__column:nth-child(4) {
  margin-bottom: 0;
}
.footer__column--last {
  border-right: 0;
}
.footer__column--logo {
  margin-bottom: 30px;
  border-right-width: 0;
  text-align: center;
}
.footer__legal {
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
  line-height: 18px;
}
.footer__legal li {
  display: inline-block;
  margin-right: 10px;
}
.footer__legal li:last-child {
  margin-right: 0;
}
.footer__copyright,
.footer__legal a {
  font-size: 0.75rem;
}
.footer__blog-disclaimer {
  margin-top: 15px;
  color: #fff;
  font-size: 0.75rem;
  text-align: left;
}
.footer__iframe {
  width: 100%;
  height: 155px;
  border: 0;
  overflow: hidden;
}
@media print, screen and (min-width: 40em) {
  .hero--generic-blog h5 {
    font-size: 1rem;
    width: 100%;
  }
  .hero--generic-blog.no-padding-bottom {
    padding-bottom: 0;
  }
  .header__promo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    color: #fefefe;
    font-size: 1.25rem;
    text-align: center;
  }
  .site__footer {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .footer__column:nth-child(2),
  .footer__column:nth-child(3) {
    margin-bottom: 0;
    border-right-width: 1px;
  }
  .footer__iframe {
    height: 95px;
  }
}
.footer__colheader {
  text-align: center;
  text-transform: uppercase;
}
.footer__sitemap {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  max-width: 320px;
  text-align: center;
}
.footer__sitemap a,
.footer__sitemap-item__link {
  width: 50%;
  float: left;
  color: #5b5b5b;
}
.footer__sitemap--generic a {
  width: 100%;
  color: #5b5b5b;
}
.footer__social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  overflow: visible;
}
.footer__social li,
.footer__social-item {
  display: inline-block;
}
.footer__social-item {
  position: relative;
  width: 50px;
  text-align: center;
}
.footer__social-item__link {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #5b5b5b;
}
.main-menu .main-menu__container .searchform,
.slider-content--toggle,
.zip-form--white-bg {
  background-color: #f3f3f3;
}
.footer__social-item__link--facebook:hover {
  background-color: #5582b8;
}
.footer__social-item__link--google:hover {
  background-color: #e75747;
}
.footer__social-item__link--twitter:hover {
  background-color: #11bae5;
}
.footer__social-item__link--blog:hover {
  background-color: #f33727;
}
.footer__social-item__link .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  transform: translate(-50%, -50%);
  fill: #f3f3f3;
}
.best-tv-providers-summary:nth-child(5) hr,
.city-list-toggle .toggle--is-hidden,
.city-list-toggle2 .toggle--is-hidden,
.footer__social-item__label,
.main-menu #toggle-menu,
.slick-slide.slick-loading img {
  display: none;
}
.footer__brands {
  display: flex;
  justify-content: center;
  margin: auto 0;
}
.ctv-logo,
.slick-dots li,
.slider-index a,
.subnav__list li a {
  display: inline-block;
}
.footer__brands--cabletv {
  width: 97px;
  height: 27px;
  margin-top: 0.3125rem;
  margin-right: 25px;
  float: left;
}
.footer__brands--dgs {
  width: 62px;
  height: 36px;
  margin-top: 0.3125rem;
  float: left;
}
.ctv-logo {
  max-width: 70px;
  width: 300px;
  margin: 10px 0;
}
.ctv-logo img {
  height: 80px;
   width: 250px;
  padding-top : 5px;
  min-width: 200px;
  max-width: 300px;
  height: 70px;
  background-size: cover;
}

.site__footer a img{
  height: 75px; 
  width: 250px;
  background-size: cover;
}
.main-menu .main-menu__container {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  display: none;
  z-index: 3;
  background-color: #fefefe;
  box-shadow: 0 2px 4px #5b5b5b;
}
.toggle-mobile-nav .bars,
.toggle-mobile-nav .bars::after,
.toggle-mobile-nav .bars::before {
  height: 2px;
  transform-origin: center center;
  background-color: #000;
  position: absolute;
  width: 20px;
}
.main-menu ul.main-menu__ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
}
.main-menu ul.main-menu__ul > li {
  display: block;
  position: relative;
}
.main-menu ul.main-menu__ul > li.menu-item-has-children {
  border-bottom: 1px solid #f3f3f3;
}
.main-menu ul.main-menu__ul > li.menu-item-has-children > a::after {
  content: url(../images/pages/generic/menu-plus.svg);
  display: block;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.main-menu ul.main-menu__ul > li.menu-item-has-children.open > a::after {
  content: url(../images/pages/generic/menu-minus.svg);
}
.main-menu ul.main-menu__ul > li > a {
  position: relative;
  display: inline-block;
  width: 100%;
  text-transform: capitalize;
  font-weight: 700;
  padding: 14px;
  color: #151515;
  text-decoration: none;
}
.main-menu ul.main-menu__ul > li > ul {
  display: none;
  width: 100%;
}
.main-menu ul.main-menu__ul > li > ul > li > a {
  display: block;
  padding: 0.7rem 1.3rem;
  font-weight: 400;
  color: #151515;
}
.main-menu ul.main-menu__ul > li > ul > li:last-child a {
  font-weight: 700;
  text-decoration: underline;
}
.toggle-mobile-nav {
  position: absolute;
  top: 50%;
  right: 15px;
  width: 37px;
  height: 37px;
  color: #fefefe;
  text-align: center;
  cursor: pointer;
  transform: translateY(-50%);
}
.slick-list,
.slick-slider,
.slick-track,
.subnav__list,
.subnav__list li {
  position: relative;
}
.toggle-mobile-nav .bars {
  display: block;
  top: 18px;
  left: 9px;
  transition: transform 0.3s ease-in;
}
.toggle-mobile-nav .bars::after {
  display: block;
  top: 6px;
  transition: transform 0.3s ease-in, top 0.3s ease-in;
  content: "";
}
.toggle-mobile-nav .bars::before {
  display: block;
  top: -6px;
  transition: transform 0.3s ease-in, top 0.3s ease-in;
  content: "";
}
.toggle-mobile-nav.active .bars {
  transform: rotate(135deg);
}
.toggle-mobile-nav.active .bars::after {
  top: 0;
  transform: rotate(-270deg);
}
.toggle-mobile-nav.active .bars::before {
  top: 0;
  transform: rotate(-180deg);
}
.change-location-link-area {
  font-size: 0.875rem;
}
.subnav__nav {
  max-width: 100%;
  overflow-y: auto;
}
.slick-list,
.slider-content__slides,
body.overflow-hidden {
  overflow: hidden;
}
.subnav__list {
  display: inline-flex;
  align-items: center;
  height: 40px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.subnav__list li {
  padding-right: 15px;
  padding-left: 15px;
}
.subnav__list li a {
  position: relative;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
  white-space: nowrap;
}
.subnav__list li a:hover {
  color: #d9d9d9;
  text-decoration: none;
}
.subnav__list li a.current-page,
.subnav__list li a:active,
.subnav__list li a:focus {
  color: #fff;
  text-decoration: none;
}
.subnav__list li a.current-page:hover {
  color: #d9d9d9;
  text-decoration: none;
}
.subnav__list li.current-page::after {
  display: block;
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -16px;
  margin-left: -8px;
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #1d253c;
  border-left: 8px solid transparent;
  content: "";
}
.subnav__list li:first-child {
  padding-left: 0;
}
.slick-slider {
  display: block;
  user-select: none;
  touch-action: pan-y;
  box-sizing: border-box;
}
.slick-list {
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list.dragging {
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  display: block;
  top: 0;
  left: 0;
}
.slick-track::after,
.slick-track::before {
  display: table;
  content: "";
}
.slick-slide {
  display: none;
  height: 100%;
  min-height: 1px;
  float: left;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-dots {
  display: block;
  position: absolute;
  bottom: -45px;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li.slick-active button::before {
  color: #0a0a0a;
  opacity: 0.75;
}
.slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  border: 0;
  outline: 0;
  background-color: rgba(0, 0, 0, 0);
  color: transparent;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
}
.slick-dots li button::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: #0a0a0a;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  content: "•";
  opacity: 0.25;
}
.slick-dots li button:focus::before,
.slick-dots li button:hover::before {
  opacity: 1;
}
.slider-index {
  color: #151515;
}
.slider-index a {
  width: 16.28%;
  height: 7.1875rem;
  margin: 0;
  padding-top: 1.875rem;
  text-align: center;
}
.slider-index__slide-image {
  max-width: 12.5rem;
  height: 2.8125rem;
  margin: 0 auto;
  padding-right: 2rem;
  padding-left: 2rem;
}
.table--default tr td:first-child,
.zip-form--blue-inline {
  max-width: 100%;
}
.slider-content {
  position: relative;
  justify-content: center;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.slider-content .slick-dots {
  position: relative;
  bottom: 0;
  left: 50%;
  margin: 0;
  transform: translateX(-50%);
}
.slider-content .slick-dots li button::before,
.stars.medium label::before {
  font-size: 2.5rem;
}
.slider-content--toggle {
  position: relative;
  padding: 0;
}
.slider-content--toggle .slick-dots {
  position: absolute;
  top: 200px;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
.slider-content--toggle .slider-content__next-arrow {
  top: 5.625rem;
  right: 15px;
}
.slider-content--toggle .slider-content__previous-arrow {
  top: 5.625rem;
  left: 15px;
}
.slider-content__slide {
  outline: 0;
  text-align: center;
}
.slider-content__slide img {
  margin-right: auto;
  margin-left: auto;
}
.zip-form--bordered .zip-form__fields,
.zip-form--trans-bg .zip-form__fields {
  margin: 0 auto;
}
.slider-content__slide p {
  padding: 0 1.5625rem;
}
.slider-content__slide--toggle {
  position: relative;
  outline: 0;
  text-align: left;
}
.slider-content__slide--toggle p {
  padding: 0;
}
.slider-content__slide-top {
  margin-top: 40px;
}
.slider-content__slide-bottom {
  padding: 0 15px 35px;
}
.slider-content__list {
  text-align: center;
}
.slider-content__list ul {
  max-width: 18.75rem;
  margin: 0 auto;
  padding-right: 1.5625rem;
  padding-left: 2.8125rem;
  text-align: left;
}
.slider-content__list--aligned-left {
  text-align: left;
}
#breadcrumbs a:first-of-type,
.slider-content__list--aligned-left ul {
  padding-left: 0;
}
.slider-content__list--aligned-left ul.fill-in li {
  width: 100%;
  padding-right: 25px;
  float: none;
}
.slider-content__list--aligned-left ul.fill-in::after {
  display: table;
  clear: both;
  content: "";
}
.slider-content__next-arrow,
.slider-content__previous-arrow {
  color: #c5c5c5;
  position: absolute;
  top: 3.125rem;
  left: 0;
  width: 1.25rem;
  height: 2.1875rem;
  cursor: pointer;
  opacity: 0.3;
  z-index: 5;
}
.slider-content__next-arrow path,
.slider-content__next-arrow polygon,
.slider-content__next-arrow use,
.slider-content__previous-arrow path,
.slider-content__previous-arrow polygon,
.slider-content__previous-arrow use {
  fill: #c5c5c5;
}
.section,
.slider-content__next-arrow:focus,
.slider-content__next-arrow:hover,
.slider-content__previous-arrow:focus,
.slider-content__previous-arrow:hover {
  color: #151515;
}
.slider-content__next-arrow:focus path,
.slider-content__next-arrow:focus polygon,
.slider-content__next-arrow:focus use,
.slider-content__next-arrow:hover path,
.slider-content__next-arrow:hover polygon,
.slider-content__next-arrow:hover use,
.slider-content__previous-arrow:focus path,
.slider-content__previous-arrow:focus polygon,
.slider-content__previous-arrow:focus use,
.slider-content__previous-arrow:hover path,
.slider-content__previous-arrow:hover polygon,
.slider-content__previous-arrow:hover use {
  fill: #151515;
}
.slider-content__next-arrow {
  right: 0;
  left: auto;
}
.zip-form--bordered {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border: 1px solid #cacaca;
  border-radius: 0.3125rem;
  text-align: center;
}
.zip-form--fixed-width {
  width: auto;
}
.zip-form--white-bg {
  color: #9f9f9f;
}
.zip-form--light-bg {
  background-color: #f2f2f2;
  color: #9f9f9f;
}
.zip-form--trans-bg {
  margin-bottom: 1.5rem;
  padding: 2rem;
  border: 0;
  border-radius: 0.3125rem;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.cta-text,
.cta-text__link:hover {
  color: #f3f3f3;
}
@media print, screen and (min-width: 40em) {
  .footer__sitemap {
    text-align: left;
  }
  .footer__social {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .footer__social-item {
    position: relative;
    width: 50%;
    float: left;
    text-align: left;
  }
  .footer__social-item__link {
    position: absolute;
  }
  .footer__social-item__label {
    display: block;
    padding: 0.1875rem 0 0.625rem 2.5rem;
    color: #5b5b5b;
  }
  .footer__brands {
    justify-content: flex-start;
  }
  .ctv-logo {
    width: 400px;
  }
  .ctv-logo img {
    max-width: 200px !important;
    height: 70px;
  }
  .slider-content .slick-dots {
    display: none;
  }
  .slider-content--toggle {
    padding-top: 25px;
    padding-bottom: 70px;
  }
  .slider-content__slide p {
    padding: 0 0.9375rem;
  }
  .slider-content__slide--toggle p {
    padding: 0;
  }
  .slider-content__slide-top {
    height: auto;
    padding-bottom: 0;
  }
  .slider-content__slide-bottom {
    padding-bottom: 0;
  }
  .slider-content__list ul {
    max-width: 28.125rem;
    padding-left: 4.0625rem;
  }
  .slider-content__list--aligned-left ul.fill-in li {
    width: 48%;
    float: left;
  }
  .zip-form--fixed-width {
    width: 80%;
  }
  .zip-form--blue-inline {
    margin: 0;
    float: right;
  }
  .zip-form--blue-inline .zip-form__fields {
    margin: 0;
  }
  .zip-form--blue-inline::after {
    display: table;
    clear: both;
    content: "";
  }
}
.zip-form--blue-inline .zip-form__fields {
  margin: 0 auto;
}
.zip-form--blue-inline button {
  background-color: #087b82;
}
.zip-form--blue-inline button:focus,
.zip-form--blue-inline button:hover {
  background-color: #07646a;
}
.zip-form--small .zip-form__button button,
.zip-form--small .zip-form__input input {
  height: 48px;
  font-size: 1.125rem;
}
.zip-form__header {
  flex: 1 100%;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}
.zip-form__subhead {
  flex-basis: 100%;
}
.zip-form__fields {
  display: flex;
}
.zip-form__input {
  margin-right: 15px;
}
.zip-form__input input {
  width: 140px;
  height: 50px;
  border-radius: 0;
  font-size: 0.95rem;
}
.tabs__panels,
.zip-form__input input.has-no-margin {
  margin: 0;
}
.zip-form__button button {
  height: 5cap;
  padding: 0.5rem 1.25rem;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 0.8;
  text-transform: uppercase;
  vertical-align: -2px;
}
.provider-cards .tabs-title a,
.provider-cards__filters li {
  padding: 0.4375rem 0.5rem;
  border: 2px solid #5050b9;
  font-size: 0.75rem;
}
.zip-form__button button:active,
.zip-form__button button:focus,
.zip-form__button button:hover {
  background-color: #d40007;
}
.cta-text {
  height: 112px;
  padding-right: 35px;
  padding-left: 35px;
  background-color: #222;
  text-align: center;
}
.cta-text__text-container {
  display: inline-block;
  align-items: center;
  line-height: normal;
  vertical-align: middle;
}
.cta-text__middle-content {
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.cta-text__text {
  display: block;
  font-size: 1.75rem;
  line-height: 1.75rem;
  text-transform: uppercase;
}
.availability-map__see-packages--visible,
.cta-text__link,
.package-card .brand-icons__list li {
  display: inline-block;
}
@media print, screen and (min-width: 40em) {
  .zip-form__input input {
    width: 100%;
  }
  .cta-text__text {
    font-size: 2.1875rem;
    line-height: 2.1875rem;
  }
  .cta-text__link {
    display: inline-block;
    font-size: 1.75rem;
    line-height: 1.75rem;
  }
}
.cta-text__text--bold {
  font-weight: 700;
}
.cta-text__link {
  color: #f3f3f3;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-decoration: none;
}
.cta-text__link--underlined,
.main-wrap .page .cta-sticky__heading a:hover {
  text-decoration: underline;
}
.button span,
.cta-text__link--bold,
.has-button a:first-of-type span,
.hero-components .vc-table-scrolling__cell.has-button a:first-of-type span,
.vc-table-scrolling__cell.has-button a:first-of-type span {
  font-weight: 700;
}
.main-wrap .page .cta-sticky {
  background-color: #202050;
  color: #fff;
  text-align: center;
}
@media print, screen and (min-width: 40em) {
  .main-wrap .page .cta-sticky {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
  }
  .main-wrap .page .cta-sticky--column-padding-restrict {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.main-wrap .page .cta-sticky--stuck {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  box-shadow: 0 2px 4px #5b5b5b;
  z-index: 100;
}
.main-wrap .page .cta-sticky a:active,
.main-wrap .page .cta-sticky a:focus,
.main-wrap .page .cta-sticky a:hover {
  color: #f2f2f2;
}
.main-wrap .page .cta-sticky h2 {
  color: #fff;
}
.list li:last-child,
.main-wrap .page .cta-sticky h3 {
  margin-bottom: 0;
}
.main-wrap .page .cta-sticky__heading {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
}
.main-wrap .page .cta-sticky__heading a {
  text-decoration: none;
}
.main-wrap .page .cta-sticky__button-area {
  height: 60px;
}
.availability-map__mapbox {
  width: 100%;
  min-height: 28.125rem;
}
.availability-map__mapbox--generic {
  height: 34.375rem;
}
.availability-map__select {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.375rem;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.8);
  color: #f3f3f3;
  z-index: 999;
}
.availability-map__brand-select {
  max-width: 9.375rem;
  margin-bottom: 0;
}
.availability-map__see-packages {
  max-width: 18.75rem;
  padding: 0.625rem 1.25rem !important;
}
.package-card__wrapper {
  max-width: auto;
}
.package-card {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: #5b5b5b;
}
.package-card .brand-icons__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.package-card .brand-icons__list__item svg {
  width: 35px;
  height: 35px;
  fill: #e6e6e6;
}
.hr--for-ad svg path,
.hr--for-ad svg polygon,
.hr--for-ad svg use,
.package-card .brand-icons__list__item.available svg,
.provider-cards-small__card-features ul li svg path,
.provider-cards-small__card-features ul li svg polygon,
.provider-cards-small__card-features ul li svg use,
.provider-cards__card-flex-area .provider-cards__card-features li svg path,
.provider-cards__card-flex-area .provider-cards__card-features li svg polygon,
.provider-cards__card-flex-area .provider-cards__card-features li svg use,
.store-locations__nav .nav__links .link path,
.store-locations__nav .nav__links .link polygon,
.store-locations__nav .nav__links .link use {
  fill: #5050b9;
}
.package-card .package-name {
  margin-bottom: 5px;
  color: #151515;
  font-size: 1.25rem;
  line-height: 1;
}
.package-card .price-area__intro {
  margin-bottom: 0;
  font-size: 0.875rem;
}
.package-card .price-area__price {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  color: #151515;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 1;
}
.package-card .price-area__price__text {
  font-size: 2.25rem;
}
.package-card .price-area__price__duration {
  margin-top: 9px;
  font-size: 1rem;
}
.package-card .price-area__legal {
  width: 100%;
  margin-top: 5px;
  color: #5b5b5b;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
}
.package-card .package-features__headline {
  color: #151515;
  font-size: 1rem;
  line-height: 1.25rem;
}
.package-card .package-features__list,
.package-card .package-features__supporting-copy {
  font-size: 1rem;
}
.package-card .package-features__legal {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.package-card .package-features__button {
  display: inline-block;
  align-self: flex-end;
  padding: 5px 10px;
  border-radius: 0;
  background-color: rgba(80, 80, 185, 0.33);
  color: #5050b9;
  text-align: center;
  text-decoration: none;
}
.package-card .package-features__button:hover {
  background-color: rgba(54, 54, 135, 0.33);
}
.package-card .package-features__visibility-toggle {
  border-bottom: 2px solid #c5c5c5;
  color: #5050b9;
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
}
.package-card .package-features__visibility-toggle svg {
  width: 10px;
  height: 10px;
  fill: #5050b9;
}
body,
html {
  scroll-behavior: smooth;
}
.header-padding-top,
.section--padding-top {
  padding-top: 30px;
}
.provider-cards {
  padding-top: 15px;
  background-color: #e6e6e6;
}
.provider-cards .tabs {
  /* display: inline-block; */
  float: right;
  margin-bottom: 30px;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}
.provider-cards .tabs-title {
  margin-bottom: 0;
  margin-left: 0.5rem;
}
.provider-cards .tabs-title.is-active a {
  background-color: #5050b9;
  color: #f3f3f3;
}
.provider-cards .tabs-title a {
  font-weight: 700;
  line-height: 1em;
  text-decoration: none;
  color: #5050b9;
}
.provider-cards .tabs-title a:hover {
  background-color: #202050;
  color: #f3f3f3;
  border-color: #202050;
}
.provider-cards .tabs-content {
  border: 0;
  background: rgba(0, 0, 0, 0);
}
.provider-cards .tabs-panel {
  padding: 0.5rem;
}
.provider-cards__filters {
  margin: 0;
  padding: 0 5px 0 0;
  list-style: none;
  text-align: right;
  white-space: nowrap;
}
.provider-cards__filters li {
  display: inline-block;
  margin-left: 0.1875rem;
  border-radius: 0;
  margin-bottom: 1rem;
  line-height: 12px;
  cursor: pointer;
}
.provider-cards-small__card-bottom,
.provider-cards__card-bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 -1px 9px 2px #c5c5c5;
}
.provider-cards__filter {
  color: #5050b9;
  font-weight: 700;
}
.button:disabled,
.has-button a:disabled:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:disabled:first-of-type,
.provider-cards__filter.disabled {
  cursor: not-allowed;
  opacity: 0.25;
}
.provider-cards__filter--selected {
  background-color: #5050b9;
  color: #f3f3f3;
}
.provider-cards__select-area {
  text-align: left;
  padding-left: 15px;
}
.provider-cards__select-area .provider-cards__select {
  width: 100%;
  color: #5b5b5b;
  font-size: 0.75rem;
}
.provider-cards__providers {
  padding-top: 0;
  margin-top: 0;
  width: 100%;
}
.provider-cards__card {
  margin: 0 15px 2.5rem;
}
.provider-cards__card--is-ad {
  margin-bottom: 1.875rem;
}
.expanded .provider-cards__card {
  margin-right: 0;
  margin-left: 0;
}
.animate-cards .provider-cards__card {
  animation: 0.33s ease-in-out 0.1s forwards card-slide;
}
.animate-cards .provider-cards__card:first-child {
  animation-delay: 0s;
}
.animate-cards .provider-cards__card:nth-child(2) {
  animation-delay: 0.1s;
}
.animate-cards .provider-cards__card:nth-child(3) {
  animation-delay: 0.2s;
}
.animate-cards .provider-cards__card:nth-child(4) {
  animation-delay: 0.3s;
}
.animate-cards .provider-cards__card:nth-child(5) {
  animation-delay: 0.4s;
}
.animate-cards .provider-cards__card:nth-child(6) {
  animation-delay: 0.5s;
}
.animate-cards .provider-cards__card:nth-child(7) {
  animation-delay: 0.6s;
}
.animate-cards .provider-cards__card:nth-child(8) {
  animation-delay: 0.7s;
}
.animate-cards .provider-cards__card:nth-child(9) {
  animation-delay: 0.8s;
}
.animate-cards .provider-cards__card:nth-child(10) {
  animation-delay: 0.9s;
}
.animate-cards .provider-cards__card:nth-child(11) {
  animation-delay: 1s;
}
.animate-cards .provider-cards__card:nth-child(12) {
  animation-delay: 1.1s;
}
.animate-cards .provider-cards__card:nth-child(13) {
  animation-delay: 1.2s;
}
.animate-cards .provider-cards__card:nth-child(14) {
  animation-delay: 1.3s;
}
.animate-cards .provider-cards__card:nth-child(15) {
  animation-delay: 1.4s;
}
.animate-cards .provider-cards__card:nth-child(16) {
  animation-delay: 1.5s;
}
.animate-cards .provider-cards__card:nth-child(17) {
  animation-delay: 1.6s;
}
.animate-cards .provider-cards__card:nth-child(18) {
  animation-delay: 1.7s;
}
.animate-cards .provider-cards__card:nth-child(19) {
  animation-delay: 1.8s;
}
.animate-cards .provider-cards__card:nth-child(20) {
  animation-delay: 1.9s;
}
@keyframes card-slide {
  from {
    transform: translateY(150px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.provider-cards__card-top {
  position: relative;
  padding: 5px 0 5px 24px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #000;
}
.provider-cards__card-top .counter {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -5px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: #a6a6a6;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.provider-cards__card-top .counter::before {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-width: 6px 5px;
  border-top: 0;
  border-left: 0;
  border-style: solid;
  border-color: transparent #777 transparent transparent;
  content: "";
}
.provider-cards__card-top .counter::after {
  display: block;
  position: absolute;
  top: 0;
  right: -18px;
  width: 0;
  height: 0;
  border-width: 12px 9px;
  border-style: solid;
  border-color: transparent transparent transparent #a6a6a6;
  content: "";
}
.provider-cards__card-top .counter.ad-color {
  background-color: #5050b9;
}
.best-of-hero-card.is-best .best-of-hero-card__label,
.nav-links .page-numbers.dots:active,
.nav-links .page-numbers.dots:focus,
.nav-links .page-numbers.dots:hover,
.provider-cards-small__card-top .counter.accent-color,
.provider-cards__card-top .counter.accent-color,
.reveal.exit-intent-popup button[type="submit"] {
  background-color: #ff2128;
}
.provider-cards__card-top .counter.ad-color::before {
  border-color: transparent #3d3d99 transparent transparent;
}
.provider-cards__card-top .counter.ad-color::after {
  border-color: transparent transparent transparent #5050b9;
}
.provider-cards__card-top .counter.accent-color::before {
  border-color: transparent #ed0007 transparent transparent;
}
.provider-cards__card-top .counter.accent-color::after {
  border-color: transparent transparent transparent #ff2128;
}
.provider-cards__card-top h3 {
  display: inline-block;
  margin-bottom: 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}
.provider-cards__card-top .coverage {
  display: block;
  position: relative;
  color: #fff;
  font-size: 0.875rem;
}
@media screen and (min-width: 500px) {
  .provider-cards__card-top .coverage {
    position: absolute;
    right: 15px;
    top: 10px;
  }
}
.provider-cards__card-bottom {
  padding: 0 15px 15px;
  background-color: #fff;
  border-color: #f3f3f3;
}
.provider-cards__card-bottom .columns {
  min-height: unset;
  border-top: 0;
  border-left: 0;
}
.provider-cards__card-bottom .columns:first-child {
  border-top: 0;
  border-left: 0;
}
.provider-cards__card-bottom .columns:nth-child(3) {
  padding-right: 0;
  padding-left: 0;
  border-top: 2px solid #e6e6e6;
}
.provider-cards__card-bottom .columns:nth-child(4) {
  padding-left: 0;
  padding-right: 0;
}
.provider-cards__card-logo {
  position: relative;
  top: 50%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  transform: translateY(-50%);
}
.provider-cards__card-rating-table {
  display: table;
  height: 100%;
  margin: 0 auto;
  padding: 10px 0;
  text-align: center;
}
.provider-cards__card-rating-table .rating-stars-container {
  white-space: nowrap;
}
.provider-cards__card-rating-table-cell {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}
.provider-cards__card-rating {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.provider-cards__card-rating .provider-cards__card-rating-details {
  font-size: 0.875rem;
  line-height: 1.5;
  text-decoration: underline;
  white-space: nowrap;
  margin-top: -5px;
}
.provider-cards__card-channels-speeds-table {
  display: table;
  height: 100%;
  margin: 0;
  padding: 10px 0;
  text-align: left;
}
.provider-cards__card-channels-speeds-table-cell {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  font-family: Oswald, Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.button__top,
.h5,
.h6,
.h7,
h5,
h6,
h7 {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
}
.provider-cards__card-channels-speeds-table-cell span {
  font-size: 0.75rem;
  font-weight: 400;
}
.provider-cards__card-flex-area {
  display: block;
  height: 100%;
  width: auto;
}
.provider-cards__card-flex-area .provider-cards__card-features-table {
  display: table;
  flex: 0 1 60%;
  margin-bottom: 5px;
  text-align: left;
}
.provider-cards__card-flex-area .provider-cards__card-features {
  margin: 5px 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.provider-cards__card-flex-area .provider-cards__card-features li {
  display: flex;
  padding: 0.5rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .provider-cards__card-flex-area .provider-cards__card-features li {
    width: 245px;
  }
}
.provider-cards__card-flex-area .provider-cards__card-features li svg {
  color: #5050b9;
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  margin-top: 1px;
  margin-right: 8px;
  vertical-align: -2px;
}
.provider-cards__card-flex-area .provider-cards__card-button-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 40%;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}
.provider-cards__card-flex-area .provider-cards__card-button-area > div {
  height: 100%;
}
.provider-cards__card-flex-area .provider-cards__card-button-area .container {
  height: max-content;
  padding-left: 15px;
}
.provider-cards__card-flex-area .provider-cards__card-phone-area {
  margin-top: 15px;
  font-size: 0.875rem;
}
.provider-cards__card-flex-area .provider-cards__card-phone-area span {
  display: inline;
}
.provider-cards__card-flex-area .provider-cards__card-phone-area a {
  font-weight: 700;
  text-decoration: underline;
}
.provider-cards__card-flex-area .provider-cards__card-pricing {
  font-size: 14px;
  font-weight: 400;
  color: #5b5b5b;
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  border-top: 2px solid #e6e6e6;
  padding: 10px 0;
}
.provider-cards__card-flex-area .provider-cards__card-pricing .card-price {
  font-size: 20px;
  font-family: Oswald, Helvetica, Arial, sans-serif;
  color: #1d1d49;
  font-weight: 700;
}
.provider-cards-small__card {
  margin-top: 0;
  margin-right: 15px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.provider-cards-small__card-top {
  position: relative;
  padding: 5px 0 5px 36px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #000;
}
.provider-cards-small__card-top .counter {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: -5px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  background: #a6a6a6;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
}
.provider-cards-small__card-top .counter::before {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-width: 6px 5px;
  border-top: 0;
  border-left: 0;
  border-style: solid;
  border-color: transparent #5b5b5b transparent transparent;
  content: "";
}
.provider-cards-small__card-top .counter::after {
  display: block;
  position: absolute;
  top: 0;
  right: -18px;
  width: 0;
  height: 0;
  border-width: 12px 9px;
  border-style: solid;
  border-color: transparent transparent transparent #c5c5c5;
  content: "";
}
.has-button
  .section--generic-quarternary-zip-form-cta
  .zip-form__fields
  a:first-of-type,
.has-button
  .section--generic-tertiary-zip-form-cta
  .zip-form__fields
  a:first-of-type,
.section--generic-quarternary-zip-form-cta .zip-form__fields .button--generic,
.section--generic-quarternary-zip-form-cta
  .zip-form__fields
  .has-button
  a:first-of-type,
.section--generic-tertiary-zip-form-cta .zip-form__fields .button--generic,
.section--generic-tertiary-zip-form-cta
  .zip-form__fields
  .has-button
  a:first-of-type {
  background-color: #5b5b5b;
}
.provider-cards-small__card-top .counter.accent-color::before {
  border-color: transparent #ed0007 transparent transparent;
}
.provider-cards-small__card-top .counter.accent-color::after {
  border-color: transparent transparent transparent #ff2128;
}
.provider-cards-small__card-top .heading {
  display: inline-block;
  margin-bottom: 0;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
}
.provider-cards-small__card-bottom {
  height: 100%;
  padding: 0 16px 32px;
  background-color: #fff;
  border-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.full-width-background .full-width-hero-card2 .features-bullets,
.provider-cards-small__card-bottom .heading,
.pull_quote .author_name {
  font-size: 18px;
}
.provider-cards-small__card-logo {
  margin-bottom: 20px;
}
.provider-cards-small__card-logo img {
  height: 120px;
}
@media screen and (min-width: 640px) and (max-width: 1024px) {
  .provider-cards-small__card-logo img,
  .provider-cards__card-logo img {
    max-width: 200px;
  }
}
.provider-cards-small__card-rating-table {
  display: table;
  height: 100%;
  margin: 0 auto;
  padding: 10px 0;
  text-align: center;
}
.provider-cards-small__card-rating-table .rating-stars-container {
  white-space: nowrap;
}
.provider-cards-small__card-rating {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.provider-cards-small__card-rating .provider-cards-small__card-rating-details {
  font-size: 0.875rem;
  line-height: 1.5;
  text-decoration: underline;
  white-space: nowrap;
  margin-top: -5px;
}
.provider-cards-small__card-features ul {
  margin: 5px 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.provider-cards-small__card-features ul li {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
}
.provider-cards-small__card-features ul li svg {
  color: #5050b9;
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  margin-top: 1px;
  margin-right: 8px;
  vertical-align: -2px;
}
.best-tv-providers-cards {
  margin-bottom: 25px;
}
.best-tv-providers-cards__card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: auto;
  margin-bottom: 15px;
  padding: 35px 15px 15px;
  border: 2px solid #cdcdcd;
  border-radius: 5px;
  background-color: #f3f3f3;
}
.city-list-toggle,
.section--white-bg {
  background-color: #fff;
}
@media print, screen and (min-width: 40em) {
  .main-wrap .page .cta-sticky--stuck {
    padding-right: 15px;
    padding-left: 15px;
  }
  .main-wrap .page .cta-sticky__heading {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .package-card__wrapper {
    max-width: 275px;
  }
  .provider-cards .tabs-title a {
    font-size: 1rem;
    line-height: 1em;
    padding: 0.9375rem 1rem;
  }
  .provider-cards__filters {
    padding-right: 0;
  }
  .provider-cards__filters li {
    margin-left: 0.5rem;
    font-size: 1rem;
    line-height: 16px;
    padding: 0.9375rem 1rem;
  }
  .provider-cards__select-area .provider-cards__select {
    width: 250px;
    height: 50px;
    color: #5b5b5b;
    font-size: 1rem;
  }
  .provider-cards-small__card-bottom,
  .provider-cards__card-bottom {
    padding-top: 15px;
  }
  .provider-cards__card-bottom .columns:nth-child(2) {
    border-top: 0;
  }
  .provider-cards__card-bottom .columns:nth-child(3) {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
  .provider-cards__card-bottom .columns:nth-child(4) {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .provider-cards__card-flex-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .provider-cards__card-flex-area .provider-cards__card-features-table-cell {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
  }
  .provider-cards__card-flex-area .provider-cards__card-button-area {
    display: table;
    height: 100%;
    width: auto;
    border-left: 1px solid #e6e6e6;
  }
  .provider-cards__card-flex-area .provider-cards__card-button-area > div {
    display: table-cell;
    vertical-align: middle;
  }
  .provider-cards__card-flex-area .provider-cards__display-table {
    display: table;
    height: 100%;
    border-left: 1px solid #e6e6e6;
  }
  .provider-cards__card-flex-area .provider-cards__card-pricing {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    border-top: 0;
    text-align: center;
  }
  .best-tv-providers-cards__card {
    justify-content: space-evenly;
    padding-top: 25px;
  }
}
.best-tv-providers-cards__card .card__num-logo-area {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 25px;
}
.best-tv-providers-cards__card .card__num {
  display: inline-block;
  position: absolute;
  left: -45px;
  width: 45px;
  color: #5b5b5b;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}
.best-tv-providers-cards__card .card__logo {
  display: inline-block;
  width: 150px;
}
.best-tv-providers-cards__card .card__best-feature {
  width: 100%;
  margin-bottom: 5px;
  color: #151515;
  font-weight: 700;
}
.best-tv-providers-cards__card .card__stars {
  width: 100%;
  margin-top: 10px;
}
@media print, screen and (min-width: 40em) {
  .best-tv-providers-cards__card .card__num-logo-area {
    margin-bottom: 0;
  }
  .best-tv-providers-cards__card .card__num {
    position: relative;
    left: 0;
    margin-bottom: 0;
    text-align: center;
  }
  .best-tv-providers-cards__card .card__best-feature {
    width: 200px;
    margin-bottom: 0;
  }
  .best-tv-providers-cards__card .card__stars {
    width: auto;
    margin-top: 15px;
  }
}
.best-tv-providers-cards__card .card__stars img {
  width: 146px;
  height: 25px;
}
.best-tv-providers-cards__card .card__stars a {
  color: #5050b9;
  font-size: 14px;
  text-decoration: underline;
}
.best-tv-providers-cards__card .card__button {
  width: 100%;
  max-width: 350px;
  margin-top: 15px;
}
.best-tv-providers-summary .summary__logo {
  display: none;
  margin-top: 25px;
}
.best-tv-providers-summary .summary__name {
  font-size: 24px;
  font-weight: 700;
}
.best-tv-providers-summary hr {
  margin: 0.9375rem auto;
  border-bottom: 2px solid #e6e6e6;
}
.city-list-toggle {
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
}
.city-list-toggle .city-list-toggle__blocks {
  display: table;
  width: 100%;
}
.city-list-toggle .city-list-toggle__blocks .blocks__block--left {
  display: table-cell;
  width: 250px;
  padding-right: 15px;
  vertical-align: middle;
}
.city-list-toggle .city-list-toggle__blocks .blocks__block--center {
  display: none;
  padding-bottom: 0;
}
.city-list-toggle .city-list-toggle__blocks .blocks__block--right {
  display: table-cell;
  padding-left: 15px;
  vertical-align: middle;
}
.city-list-toggle .toggle--is-hidden-override {
  display: none !important;
}
.city-list-toggle .toggle--is-shown {
  display: block;
}
.city-list-toggle #long-cities a::after,
.city-list-toggle #short-cities1 a::after,
.city-list-toggle #short-cities2 a::after {
  content: ",   ";
}
.city-list-toggle #long-cities a:last-child::after,
.city-list-toggle #short-cities1 a:last-child::after,
.city-list-toggle #short-cities2 a:last-child::after,
.city-list-toggle2 #long-cities a:last-child::after,
.city-list-toggle2 #short-cities a:last-child::after {
  content: "";
}
.city-list-toggle2 .toggle--is-hidden-override {
  display: none !important;
}
.city-list-toggle2 .toggle--is-shown,
.container {
  display: block;
}
.heading--has-icon-24,
.image--is-header-icon {
  display: inline-block;
}
.list--fill-in-small::after,
.list--fill-in::after {
  display: table;
  clear: both;
  content: "";
}
.city-list-toggle2 .view-all-cities {
  margin-top: 30px;
  font-size: 14px;
}
.margin--center,
.section--generic-quarternary-zip-form-cta .zip-form__fields,
.section--generic-tertiary-zip-form-cta .zip-form__fields,
.section--generic-zip-form-cta .zip-form__fields {
  margin: 0 auto;
}
.city-list-toggle2 #long-cities a::after,
.city-list-toggle2 #short-cities a::after {
  content: "      ";
}
h1 {
  font-weight: 700;
}
h2 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
}
.heading--channel-lineup,
h3 {
  font-weight: 700;
  line-height: 1.6875rem;
}
h3 {
  font-size: 1.3125rem;
}
h4 {
  font-size: 1.125rem;
  font-weight: 700;
}
h5 {
  font-size: 1rem;
  font-weight: 700;
}
h6 {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}
h7 {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
}
.heading--has-icon-24 svg {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-bottom: -5px;
}
.product-card .contents .pros-cons-container .pros-cons-item:last-child,
.section--generic-quarternary-zip-form-cta h4,
.section--generic-tertiary-zip-form-cta h4,
.section--generic-zip-form-cta h4,
.single-review-hero-card
  .contents
  .pros-cons-container
  .pros-cons-item:last-child {
  margin-bottom: 20px;
}
.heading--channel-lineup {
  font-size: 1.4375rem;
}
.price__header,
.section--legal {
  font-weight: 400;
}
.heading--channel-lineup a {
  text-decoration: none;
}
.heading--channel-lineup a:hover {
  text-decoration: underline;
}
p + .h2,
p + .h3,
p + .h4,
p + .h5,
p + .h6,
p + .h7,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6,
p + h7 {
  padding-top: 0.9375rem;
}
.section--padding-bottom {
  padding-bottom: 30px;
}
@media print, screen and (min-width: 40em) {
  .best-tv-providers-cards__card .card__button {
    width: auto;
    margin-top: 0;
  }
  .best-tv-providers-summary .summary__logo {
    display: block;
  }
  .best-tv-providers-summary hr {
    margin: 1.875rem auto;
  }
  .city-list-toggle .city-list-toggle__blocks .blocks__block--center {
    display: table-cell;
    vertical-align: middle;
  }
  h1 {
    font-size: 2.625rem;
    line-height: 3rem;
  }
  h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }
  h7 {
    font-size: 0.75rem;
  }
  .heading--channel-lineup {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
  .section--padding-top {
    padding-top: 60px;
  }
  .section--padding-bottom {
    padding-bottom: 60px;
  }
}
.section--padding-top-30 {
  padding-top: 30px;
}
.section--padding-bottom-30 {
  padding-bottom: 30px;
}
.section--inset-shadow {
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.section--light-bg {
  background-color: #f3f3f3;
}
.section--medium-bg {
  background-color: #c5c5c5;
}
.section--light-purple {
  background-color: #d3d3ee;
}
.section--gradient {
  background-color: #202050;
  background-image: linear-gradient(30deg, #0a0a19, #202050);
  color: #fefefe;
}
.section--image-bg {
  background-position: top center;
  color: #fefefe;
}
.section--gradient2 {
  background-image: linear-gradient(225deg, #0c0c1d 0, #1d253c 100%);
  color: #fefefe;
}
.section--generic-zip-form-cta {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #6262c0;
  color: #fff;
}
.section--generic-tertiary-zip-form-cta {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eba74b;
  color: #f3f3f3;
}
.section--generic-quarternary-zip-form-cta {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #aebc4a;
  color: #f3f3f3;
}
.section__provider-logos {
  background-color: #fff;
}
.section__provider-logos .row--has-provider-logo {
  height: 135px;
}
.card__rating-dialog,
.section__fade {
  position: relative;
}
.section__fade-left,
.section__fade-right {
  position: absolute;
  width: 150px;
  height: 150px;
  z-index: 1;
}
.section__fade-left {
  left: 0;
  background: -moz-linear-gradient(left, #fff, rgba(255, 255, 255, 0));
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#fff),
    to(rgba(255, 255, 255, 0))
  );
}
.section__fade-right {
  right: 0;
  background: -moz-linear-gradient(right, #fff, rgba(255, 255, 255, 0));
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#fff),
    to(rgba(255, 255, 255, 0))
  );
}
.section--subnav-container {
  background-color: #5050b9;
}
.bg-primary,
.section--cta-sticky {
  background-color: #202050;
}
.row--justify-center {
  justify-content: center;
}
.hr.rule--is-medium,
hr.rule--is-medium {
  width: 50rem;
  border-width: medium;
  margin-top: 20px;
  margin-bottom: 20px;
}
.image--is-circle,
.post-info .avatar {
  border-radius: 50%;
}
.image--is-rounded,
.vc_general.vc_cta3.vc_cta3-shape-rounded,
.vc_message_box-rounded {
  border-radius: 5px;
}
.image--is-provider-logo {
  max-width: 100px;
}
@media print, screen and (min-width: 40em) {
  .image--is-provider-logo {
    max-width: 200px;
  }
}
@media print, screen and (min-width: 64em) {
  .footer__column--logo {
    margin-bottom: 0;
    border-right-width: 1px;
  }
  .footer__iframe {
    height: 85px;
  }
  .footer__colheader {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
  }
  .main-menu .main-menu__container {
    display: block;
    position: relative;
    left: auto;
    top: auto;
    box-shadow: none;
  }
  .main-menu .main-menu__container .searchform {
    display: none;
  }
  .main-menu ul.main-menu__ul > li > a {
    padding: 26px;
  }
  .main-menu ul.main-menu__ul > li > ul {
    display: none;
    position: absolute;
    padding: 20px 0;
    top: 90%;
    right: 0;
    min-width: 250px;
    background: #fefefe;
    box-shadow: 0 2px 4px #5b5b5b;
    z-index: 3;
  }
  .main-menu ul.main-menu__ul > li:hover ul {
    display: block;
  }
  .main-menu ul.main-menu__ul > li {
    display: inline-block;
  }
  .main-menu ul.main-menu__ul > li.menu-item-has-children {
    border-bottom: 0;
  }
  .main-menu ul.main-menu__ul > li.menu-item-has-children > a::after,
  .toggle-mobile-nav {
    display: none;
  }
  .change-location-link-area {
    font-size: 1rem;
  }
  .scroll::-webkit-scrollbar {
    height: 4px;
    cursor: pointer;
  }
  .scroll::-webkit-scrollbar-track {
    background: #f3f3f3;
  }
  .scroll::-webkit-scrollbar-thumb {
    background: #fff;
  }
  .cta-text__text {
    padding-right: 12px;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  .cta-text__link {
    font-size: 2.1875rem;
    line-height: 2.1875rem;
  }
  .provider-cards__card-bottom .columns,
  .provider-cards__card-bottom .columns:nth-child(3) {
    border-top: 0;
    border-left: 1px solid #e6e6e6;
  }
  .provider-cards__card-logo {
    margin-bottom: 0;
  }
  .provider-cards-small__card-logo img,
  .provider-cards__card-logo img {
    max-width: 100%;
  }
  .provider-cards-small__card-rating-table,
  .provider-cards__card-rating-table {
    padding: 0;
  }
  .provider-cards__card-channels-speeds-table {
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
  .provider-cards__card-flex-area .provider-cards__card-phone-area {
    text-align: center;
  }
  .provider-cards__card-flex-area .provider-cards__card-phone-area span {
    display: block;
  }
  .best-tv-providers-cards__card {
    padding: 30px 0;
  }
  .best-tv-providers-cards__card .card__stars {
    width: auto;
    margin-top: 0;
  }
  .image--is-provider-logo {
    max-width: 300px;
  }
}
.image--max-height-220 {
  max-height: 13.75rem;
}
.image--max-height-100 {
  max-height: 6.25rem;
}
.image--max-height-300 {
  max-height: 18.75rem;
}
.image--max-height-24 {
  max-height: 1.5rem;
}
.image--max-height-60 {
  max-height: 3.75rem;
}
.image--max-height-75 {
  max-height: 4.6875rem;
}
.image--max-height-330 {
  max-height: 20.625rem;
}
.image--max-width-100 {
  max-width: 6.25rem;
  height: auto;
}
.image--max-width-120 {
  max-width: 7.5rem;
  height: auto;
}
.image--max-width-150 {
  max-width: 9.375rem;
  height: auto;
}
.image--max-width-200 {
  max-width: 12.5rem;
  height: auto;
}
.image--min-width-50 {
  max-width: 3.125rem;
  height: auto;
}
.image--fix-height-30 {
  width: auto;
  height: 1.875rem;
}
.image--fix-height-60 {
  width: auto;
  height: 3.75rem;
}
.image--fix-height-100 {
  width: auto;
  height: 6.25rem;
}
.image--icon-size-18,
.image--icon-size-18 svg {
  width: 18px;
  height: 18px;
}
.image--icon-size-18 {
  vertical-align: -4px;
}
.image--icon-size-24,
.image--icon-size-24 svg {
  width: 24px !important;
  height: 24px !important;
}
.image--icon-size-24 {
  vertical-align: -6px;
}
.image--icon-size-32,
.image--icon-size-32 svg {
  width: 32px;
  height: 32px;
}
.image--icon-size-64,
.image--icon-size-64 svg {
  width: 64px;
  height: 64px;
}
.image--featured-banner {
  position: relative;
  width: 100% !important;
  height: auto;
  margin-bottom: 10px;
}
.image--featured-banner img {
  width: 95% !important;
  height: auto;
}
.list,
.list li,
.list__features {
  margin-bottom: 15px;
}
.price {
  margin-top: 15px;
}
.price--charter {
  color: #be185b;
}
.price--xfinity {
  color: #2b9cd8;
}
.price__header {
  font-size: 0.875rem;
}
.price__main {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.875rem;
}
.alignleft,
.price__main__cents,
.price__main__dollars,
.price__main__sign,
.price__main__text {
  float: left;
}
.price__main__duration {
  margin-top: 4px;
  float: left;
  font-size: 1rem;
  line-height: 1rem;
}
.price__legal {
  clear: both;
  font-size: 0.75rem;
  font-weight: 400;
}
.list li {
  line-height: 24px;
}
.list__features {
  display: none;
  position: relative;
  margin-top: 10px;
}
.list__features li {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5625rem;
}
.list__icon-header {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.list__icon-header h3 {
  margin-bottom: 0;
}
.list__icon-header .icon-header__icon {
  margin-right: 15px;
}
.list__icon-header .icon-header__icon img {
  max-width: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.list--indented {
  padding-left: 20px;
}
.list--fill-in li {
  width: 100%;
  padding-right: 25px;
  float: none;
}
.list--fill-in-small li {
  width: 48%;
  padding-right: 25px;
  float: left;
}
.footer__local--generic,
.list--no-list-style {
  margin: 0;
  padding: 0;
  list-style: none;
}
.hr,
hr {
  max-width: 73.75rem;
  margin: 1.875rem auto;
  border-bottom: 1px solid #c5c5c5;
}
@media print, screen and (min-width: 40em) {
  .image--featured-banner {
    position: relative;
    top: -20px;
    margin-bottom: 0;
  }
  .image--featured-banner img {
    width: 50% !important;
    margin-bottom: 0;
  }
  .price__main {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }
  .price__main__duration {
    margin-top: 9px;
  }
  .list__features {
    display: block;
  }
  .list__features li {
    font-size: 1.25rem;
    line-height: 1.6875rem;
  }
  .list--fill-in li {
    width: 48%;
    float: left;
  }
  .hr,
  hr {
    margin: 3.75rem auto;
  }
}
.hr.header,
hr.header {
  width: 100%;
  max-width: none;
  margin-top: 0;
}
.hr.footer,
hr.footer {
  width: 100%;
  max-width: none;
  margin-bottom: 0;
}
.hr.footer--no-margin,
hr.footer--no-margin {
  margin: 0;
  border-bottom: 1px solid rgba(197, 197, 197, 0.1);
}
.hr.margin-sm,
hr.margin-sm {
  width: auto;
  max-width: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
.hr.rule--is-white,
hr.rule--is-white {
  border-bottom: 2px solid #f3f3f3;
}
.hr.rule--is-small,
hr.rule--is-small {
  width: 30px;
  margin: 0 auto 15px;
  text-align: center;
}
.hr.rule--has-small-margins,
hr.rule--has-small-margins {
  width: auto;
  max-width: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
.hr.rule--has-tiny-margins,
hr.rule--has-tiny-margins {
  width: auto;
  max-width: none;
  margin-top: 5px;
  margin-bottom: 5px;
}
.hr.rule--is-thick,
hr.rule--is-thick {
  border-width: 2px;
}
.hr--for-ad {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  color: #5050b9;
  font-size: 0.875rem;
}
.hr--for-ad::after,
.hr--for-ad::before {
  flex: 1 0 10px;
  border-top: 2px solid #5050b9;
  content: "";
}
.hr--for-ad::before {
  margin-right: 15px;
}
.hr--for-ad::after {
  margin-left: 15px;
}
.hr--for-ad svg {
  color: #5050b9;
  width: 12px;
  height: 12px;
}
.button--has-icon-on-left svg path,
.button--has-icon-on-left svg polygon,
.button--has-icon-on-left svg use,
.button--has-icon-on-right svg path,
.button--has-icon-on-right svg polygon,
.button--has-icon-on-right svg use,
.color--grayscale-color1 path,
.color--grayscale-color1 polygon,
.color--grayscale-color1 use {
  fill: #fff;
}
.hr--for-dense-list {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 5px solid #000;
}
p {
  text-rendering: optimizeLegibility;
  margin-bottom: 15px;
  font-size: 1rem;
}
.paragraph--is-legal,
.paragraph--is-small {
  margin-bottom: 15px;
  padding-top: 15px;
  color: #5b5b5b;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.paragraph--is-legal-sm {
  margin-bottom: 10px;
  color: #5b5b5b;
  font-size: 0.625rem;
  line-height: 0.75rem;
}
.main-wrap.page ul.tabs li,
.main-wrap.post ul.tabs li,
.main-wrap.search ul.tabs li,
.paragraph--no-margin-bottom {
  margin-bottom: 0;
}
.main-wrap.page p.paragraph--is-legal,
.main-wrap.page p.paragraph--is-small,
.main-wrap.post p.paragraph--is-legal,
.main-wrap.post p.paragraph--is-small,
.main-wrap.search p.paragraph--is-legal,
.main-wrap.search p.paragraph--is-small {
  line-height: 0.875rem;
}
.button,
.has-button a:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:first-of-type,
.vc-table-scrolling__cell.has-button a:first-of-type {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  padding: 0.3125rem 0.9375rem;
  border-radius: 0;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}
.button__chat,
.button__top {
  position: fixed;
  right: 20px;
  z-index: 11;
}
.button--inherit-bg {
  background-color: inherit;
}
.button--generic,
.has-button a:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:first-of-type,
.vc-table-scrolling__cell.has-button a:first-of-type {
  background-color: #ff2128;
  color: #fff;
  text-decoration: none;
}
.hero--generic #breadcrumbs a:hover,
.hero--generic .breadcrumb a:hover {
  color: #cdcdcd;
}
.button--generic:active,
.button--generic:disabled:hover,
.button--generic:focus,
.button--generic:hover,
.has-button a:active:first-of-type,
.has-button a:disabled:hover:first-of-type,
.has-button a:focus:first-of-type,
.has-button a:hover:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:active:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:focus:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:hover:first-of-type,
.reveal.exit-intent-popup button[type="submit"]:active,
.reveal.exit-intent-popup button[type="submit"]:focus,
.reveal.exit-intent-popup button[type="submit"]:hover {
  background-color: #d40007;
}
.button--generic:disabled,
.has-button a:disabled:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:disabled:first-of-type,
.nav-links .page-numbers.current:active,
.nav-links .page-numbers.current:focus,
.nav-links .page-numbers.current:hover,
button.cta:active,
button.cta:focus,
button.cta:hover {
  background-color: #ff080f;
}
.button--generic-color1:active,
.button--generic-color1:disabled:hover,
.button--generic-color1:focus,
.button--generic-color1:hover {
  background-color: #363687;
}
.button--generic-color1 {
  border-radius: 0;
  background-color: #5050b9;
}
.button--generic-color1:disabled {
  background-color: #4444ab;
}
.button--generic-color3 {
  background-color: #c5c5c5 !important;
  color: #151515 !important;
}
.button--spectrum {
  background-color: #faa41a;
}
.button--spectrum:active,
.button--spectrum:focus,
.button--spectrum:hover {
  background-color: #f59905;
}
.button--time-warner {
  background-color: #f87300;
}
.button--is-nav-button,
button.cta {
  margin: 10px 0;
  background-color: #ff2128;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
}
.button--time-warner:active,
.button--time-warner:focus,
.button--time-warner:hover {
  background-color: #df6700;
}
.button--is-short {
  width: auto;
  max-width: 150px;
}
.button--is-short-unlimited,
.store-locations,
.store-locations__locations {
  width: auto;
}
.button--is-auto-width {
  width: 100%;
}
@media print, screen and (min-width: 40em) {
  .button--is-auto-width {
    width: auto;
  }
  .margin-bottom {
    margin-bottom: 30px;
  }
}
.button--is-big,
.has-button a:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:first-of-type,
.vc-table-scrolling__cell.has-button a:first-of-type {
  padding: 0.625rem 1.25rem;
  font-size: 0.925rem;
  white-space: nowrap;
}
.hero--generic #breadcrumbs a:first-of-type,
.hero--generic .breadcrumb a:first-of-type {
  padding-left: 0;
}
.button--is-huge {
  max-width: 250px;
  padding: 1.25rem 1.5625rem;
  font-size: 1.25rem;
  font-weight: 700;
  white-space: nowrap;
}
.button--has-icon-on-left,
.button--has-icon-on-right {
  display: flex;
  padding: 0.5rem 0.75rem;
  align-content: center;
}
.button--is-uppercase {
  text-transform: uppercase;
}
.button--is-bold {
  font-weight: 700;
}
.button--allows-wrap {
  white-space: normal !important;
}
.button--has-icon-on-right {
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.button--has-icon-on-right svg {
  color: #fff;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  padding-left: 5px;
}
.button--has-icon-on-left {
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.button--has-icon-on-left svg {
  color: #fff;
  width: 18px;
  min-width: 18px;
  height: 18px;
  min-height: 18px;
  padding-right: 5px;
}
.button--is-nav-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 15px;
  border-radius: 8px;
  color: #fff;
  line-height: 1;
}
.button--is-nav-button:active,
.button--is-nav-button:focus,
.button--is-nav-button:hover {
  background-color: #d40007;
  color: #fefefe;
}
.button--fills-container {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
  padding: 0;
}
.button--has-white-border {
  max-width: 220px;
  border: 2px solid #f3f3f3;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.25rem;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
}
.button--has-white-border:focus,
.button--has-white-border:hover {
  background-color: #4444ab;
}
.button--has-white-border svg {
  color: #f3f3f3;
  width: 24px;
  height: 24px;
}
.button--has-white-border svg path,
.button--has-white-border svg polygon,
.button--has-white-border svg use {
  fill: #f3f3f3;
}
.link--is-disabled svg path,
.link--is-disabled svg polygon,
.link--is-disabled svg use,
.tabs__container .tabs-mobile li.next svg path,
.tabs__container .tabs-mobile li.next svg polygon,
.tabs__container .tabs-mobile li.next svg use,
.tabs__container .tabs-mobile li.previous svg path,
.tabs__container .tabs-mobile li.previous svg polygon,
.tabs__container .tabs-mobile li.previous svg use {
  fill: #cdcdcd;
}
.button__top {
  bottom: -20px;
  transition: opacity 0.5s ease-in, bottom 0.5s ease-in;
  font-weight: 400;
  opacity: 0;
}
.button__top--is-active {
  bottom: 20px;
  opacity: 0.5;
  visibility: visible;
}
.button__top:hover,
.featured-slider:hover .slick-next,
.featured-slider:hover .slick-prev {
  transition: opacity 0.2s ease-in;
  opacity: 1;
}
.button__chat {
  bottom: 20px;
  opacity: 0.8;
}
.button__chat:hover {
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  opacity: 1;
}
.button--show-spinner {
  justify-content: flex-start;
}
.button--show-spinner .button__icon,
.button--show-spinner .zip-finder__button__header,
.button--show-spinner span {
  display: none;
}
.button--show-spinner .spinner {
  position: relative;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-right: 3px solid #fefefe;
  border-radius: 50%;
  animation: 2s linear infinite spinner;
  transition: opacity 0.2s ease-in;
  opacity: 1;
}
@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.best-of-hero-card .rating-star-empty,
.best-of-hero-card .rating-star-full,
.button--hide-spinner .button__icon,
.button--hide-spinner .zip-finder__button__header,
.button--hide-spinner span,
.product-card .rating-star-empty,
.product-card .rating-star-full,
.rating-stars-container .rating-star-empty,
.rating-stars-container .rating-star-full,
.single-review-hero-card .rating-star-empty,
.single-review-hero-card .rating-star-full,
.vc_general.vc_cta3.vc_cta3-icons-bottom .vc_cta3-icons .vc_icon_element,
.vc_general.vc_cta3.vc_cta3-icons-top .vc_cta3-icons .vc_icon_element {
  display: inline-block;
}
.button--hide-spinner .spinner {
  opacity: 0;
}
.button--secondary {
  width: max-content;
  border: 2px solid #5050b9;
  background-color: #fff;
  color: #5050b9;
  text-transform: uppercase;
}
.button--secondary:hover {
  background-color: #5050b9;
  color: #fefefe;
}
.width--standard {
  width: max-content;
}
.footer__email-form,
.reveal.exit-intent-popup,
.reveal.exit-intent-popup .dialog__bottom,
.reveal.exit-intent-popup .dialog__top,
.width--full,
ul.channel-lineup__lineup {
  width: 100%;
}
button.cta {
  min-width: 150px;
  height: 50px;
  padding: 0 10px;
  border-radius: 0;
  color: #fff;
  text-decoration: none;
}
.hero--generic .breadcrumb a,
.link--is-underlined {
  text-decoration: underline;
}
.copy-columns__two {
  column-count: 2;
}
.channel-lineup {
  padding-top: 30px;
  padding-bottom: 30px;
}
.channel-lineup__filters li {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.1875rem 0.625rem;
  border: 2px solid #5b5b5b;
  border-radius: 0.3125rem;
  font-weight: 700;
  cursor: pointer;
}
.channel-lineup__filters li:first-child {
  margin-left: 0;
}
.channel-lineup__filter {
  background-color: #f3f3f3;
  color: #5b5b5b;
}
.channel-lineup__filter--selected {
  background-color: #5b5b5b;
  color: #fff;
}
.container--equipment,
.container--equipment-lg,
.tabs__container .tabs li.is-active:hover {
  background-color: #f3f3f3;
}
table.channel-lineup__lineup {
  width: 100%;
  border-collapse: collapse;
}
ul.channel-lineup__lineup li {
  width: 100%;
  float: left;
  list-style: none;
  text-align: left;
}
@media print, screen and (min-width: 40em) {
  ul.channel-lineup__lineup li {
    width: 33.333%;
  }
}
ul.channel-lineup__lineup li.channel-lineup__row--no-channels {
  width: 100%;
  color: #9f9f9f;
  font-size: 14px;
}
.container--equipment {
  height: 250px;
  margin-bottom: 30px;
  padding: 30px 10px;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
}
.container--equipment-lg {
  height: 275px;
  margin-bottom: 30px;
  padding: 30px 10px;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
}
.container--circle-top .circle-top__number,
.container-circle-arrow--blue {
  background-color: #5050b9;
  color: #f3f3f3;
  font-weight: 700;
}
.container-circle-grayscale {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-top: 25px;
}
.container-grayscale {
  display: flex;
  position: absolute;
  left: 65px;
  align-items: center;
  height: 95px;
  padding-left: 55px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.15) 0,
    rgba(0, 0, 0, 0) 100%
  );
  font-weight: 700;
  z-index: 0;
}
.container--circle-top {
  position: relative;
  width: 80%;
  height: 150px;
  margin: 25px auto;
  padding-right: 15px;
  padding-left: 15px;
  border: 1px solid #5050b9;
}
@media print, screen and (min-width: 40em) {
  .container--circle-top {
    width: 100%;
  }
}
@media print, screen and (min-width: 64em) {
  ul.channel-lineup__lineup li {
    width: 25%;
  }
  .container--circle-top {
    width: 80%;
  }
}
.container--circle-top .circle-top__number {
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  font-size: 35px;
  text-align: center;
}
.container--circle-top .circle-top__image {
  position: relative;
  top: 50%;
  margin-top: -25px;
  transform: translateY(-50%);
}
.container-circle-arrow--blue::after,
.container-circle-arrow--red::after,
.container-circle-arrow--yellow::after {
  top: calc(50% - 10px);
  left: 98%;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  position: absolute;
  content: "";
}
.container--circle-top .circle-top__image img {
  max-width: 200px;
  max-height: 75px;
}
.container-circle-arrow {
  display: flex;
  position: absolute;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 1;
}
.container-circle-arrow--blue::after {
  border-color: transparent transparent transparent #5050b9;
}
.container-circle-arrow--yellow {
  background-color: #eba74b;
  color: #f3f3f3;
  font-weight: 700;
}
.container-circle-arrow--yellow::after {
  border-color: transparent transparent transparent #eba74b;
}
.container-circle-arrow--red {
  background-color: #ff2128;
  color: #f3f3f3;
  font-weight: 700;
}
.container-circle-arrow--red::after {
  border-color: transparent transparent transparent #ff2128;
}
.container--swipe-hint {
  display: none;
  margin-bottom: 10px;
  color: #5b5b5b;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}
.container--swipe-hint-target {
  overflow-x: auto;
}
.container--is-icon-tile {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #5050b9;
}
.container--is-icon-tile svg {
  width: 65px;
  height: 65px;
  color: #fff;
}
@media print, screen and (min-width: 64em) {
  .container--is-icon-tile {
    display: flex;
    width: 142px;
    height: 142px;
  }
  .container--is-icon-tile svg {
    width: 90px;
    height: 90px;
  }
}
.container--is-icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  min-height: 60px;
  border-radius: 50%;
  -ms-flex-align: center;
}
.container--is-icon-circle svg {
  width: 30px;
  height: 30px;
}
.container--has-outline-generic-color1 {
  padding: 30px 15px 15px;
  border: 2px solid #5050b9;
  border-radius: 5px;
}
@media print, screen and (min-width: 40em) {
  .container--is-icon-tile {
    display: flex;
  }
  .container--is-icon-circle {
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    min-height: 120px;
  }
  .container--is-icon-circle svg {
    width: 60px;
    height: 60px;
  }
  .container--has-outline-generic-color1 {
    padding-right: 60px;
    padding-left: 60px;
  }
}
.container--has-outline-xfinity-color1 {
  padding: 30px 15px 15px;
  border: 2px solid #2b9cd8;
  border-radius: 5px;
}
.container__channel-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.container__flex-stack {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.container__flex-stack--icon {
  margin: 0 15px;
}
.container__flex-stack--text {
  width: 215px;
  text-align: left;
}
@media print, screen and (min-width: 40em) {
  .container--has-outline-xfinity-color1 {
    padding-right: 60px;
    padding-left: 60px;
  }
  .container__flex-stack {
    flex-direction: column;
  }
  .container__flex-stack--icon {
    margin: 0;
  }
  .container__flex-stack--text {
    width: 200px;
    text-align: center;
  }
}
@media screen and (min-width: 49.375em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
  .topbar-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .topbar-2,
  .topbar-up-6 > .column,
  .topbar-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .topbar-3,
  .topbar-up-4 > .column,
  .topbar-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .topbar-4,
  .topbar-up-3 > .column,
  .topbar-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .topbar-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .topbar-6,
  .topbar-up-2 > .column,
  .topbar-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .topbar-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .topbar-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .topbar-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .topbar-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .topbar-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .topbar-12,
  .topbar-up-1 > .column,
  .topbar-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .topbar-up-1,
  .topbar-up-2,
  .topbar-up-3,
  .topbar-up-4,
  .topbar-up-5,
  .topbar-up-6,
  .topbar-up-7,
  .topbar-up-8 {
    flex-wrap: wrap;
  }
  .topbar-up-5 > .column,
  .topbar-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .topbar-up-7 > .column,
  .topbar-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .topbar-up-8 > .column,
  .topbar-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .menu.topbar-expanded li,
  .menu.topbar-simple li,
  .row.topbar-unstack > .column,
  .row.topbar-unstack > .columns {
    flex: 1 1 0px;
  }
  .topbar-collapse > .column,
  .topbar-collapse > .columns,
  .topbar-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
  .topbar-uncollapse > .column,
  .topbar-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .topbar-order-1 {
    order: 1;
  }
  .topbar-order-2 {
    order: 2;
  }
  .topbar-order-3 {
    order: 3;
  }
  .topbar-order-4 {
    order: 4;
  }
  .topbar-order-5 {
    order: 5;
  }
  .topbar-order-6 {
    order: 6;
  }
  .topbar-flex-container {
    display: flex;
  }
  .topbar-flex-child-auto {
    flex: 1 1 auto;
  }
  .topbar-flex-child-grow {
    flex: 1 0 auto;
  }
  .topbar-flex-child-shrink {
    flex: 0 1 auto;
  }
  .topbar-flex-dir-row {
    flex-direction: row;
  }
  .topbar-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .topbar-flex-dir-column {
    flex-direction: column;
  }
  .topbar-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .topbar-text-left {
    text-align: left;
  }
  .topbar-text-right {
    text-align: right;
  }
  .topbar-text-center {
    text-align: center;
  }
  .topbar-text-justify {
    text-align: justify;
  }
  .menu.topbar-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.topbar-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
    margin-right: auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
    margin-left: auto;
  }
  .hide-for-topbar {
    display: none !important;
  }
  .dropdown.menu.topbar-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.topbar-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.topbar-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.topbar-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.topbar-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .grid-x > .topbar-1,
  .grid-x > .topbar-10,
  .grid-x > .topbar-11,
  .grid-x > .topbar-12,
  .grid-x > .topbar-2,
  .grid-x > .topbar-3,
  .grid-x > .topbar-4,
  .grid-x > .topbar-5,
  .grid-x > .topbar-6,
  .grid-x > .topbar-7,
  .grid-x > .topbar-8,
  .grid-x > .topbar-9,
  .grid-x > .topbar-full,
  .grid-x > .topbar-shrink,
  .grid-y > .topbar-1,
  .grid-y > .topbar-10,
  .grid-y > .topbar-11,
  .grid-y > .topbar-12,
  .grid-y > .topbar-2,
  .grid-y > .topbar-3,
  .grid-y > .topbar-4,
  .grid-y > .topbar-5,
  .grid-y > .topbar-6,
  .grid-y > .topbar-7,
  .grid-y > .topbar-8,
  .grid-y > .topbar-9,
  .grid-y > .topbar-full,
  .grid-y > .topbar-shrink {
    flex-basis: auto;
  }
  .grid-x > .topbar-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .topbar-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .topbar-1,
  .large-margin-collapse > .topbar-1,
  .medium-margin-collapse > .topbar-1,
  .small-margin-collapse > .topbar-1,
  .topbar-margin-collapse > .large-1,
  .topbar-margin-collapse > .medium-1,
  .topbar-margin-collapse > .small-1,
  .topbar-margin-collapse > .topbar-1 {
    width: 8.3333333333%;
  }
  .grid-x > .topbar-2,
  .large-margin-collapse > .topbar-2,
  .medium-margin-collapse > .topbar-2,
  .small-margin-collapse > .topbar-2,
  .topbar-margin-collapse > .large-2,
  .topbar-margin-collapse > .medium-2,
  .topbar-margin-collapse > .small-2,
  .topbar-margin-collapse > .topbar-2,
  .topbar-up-6 > .cell {
    width: 16.6666666667%;
  }
  .grid-x > .topbar-3,
  .large-margin-collapse > .topbar-3,
  .medium-margin-collapse > .topbar-3,
  .small-margin-collapse > .topbar-3,
  .topbar-margin-collapse > .large-3,
  .topbar-margin-collapse > .medium-3,
  .topbar-margin-collapse > .small-3,
  .topbar-margin-collapse > .topbar-3,
  .topbar-up-4 > .cell {
    width: 25%;
  }
  .grid-x > .topbar-4,
  .large-margin-collapse > .topbar-4,
  .medium-margin-collapse > .topbar-4,
  .small-margin-collapse > .topbar-4,
  .topbar-margin-collapse > .large-4,
  .topbar-margin-collapse > .medium-4,
  .topbar-margin-collapse > .small-4,
  .topbar-margin-collapse > .topbar-4,
  .topbar-up-3 > .cell {
    width: 33.3333333333%;
  }
  .grid-x > .topbar-5,
  .large-margin-collapse > .topbar-5,
  .medium-margin-collapse > .topbar-5,
  .small-margin-collapse > .topbar-5,
  .topbar-margin-collapse > .large-5,
  .topbar-margin-collapse > .medium-5,
  .topbar-margin-collapse > .small-5,
  .topbar-margin-collapse > .topbar-5 {
    width: 41.6666666667%;
  }
  .grid-x > .topbar-6,
  .large-margin-collapse > .topbar-6,
  .medium-margin-collapse > .topbar-6,
  .small-margin-collapse > .topbar-6,
  .topbar-margin-collapse > .large-6,
  .topbar-margin-collapse > .medium-6,
  .topbar-margin-collapse > .small-6,
  .topbar-margin-collapse > .topbar-6,
  .topbar-up-2 > .cell {
    width: 50%;
  }
  .grid-x > .topbar-7,
  .large-margin-collapse > .topbar-7,
  .medium-margin-collapse > .topbar-7,
  .small-margin-collapse > .topbar-7,
  .topbar-margin-collapse > .large-7,
  .topbar-margin-collapse > .medium-7,
  .topbar-margin-collapse > .small-7,
  .topbar-margin-collapse > .topbar-7 {
    width: 58.3333333333%;
  }
  .grid-x > .topbar-8,
  .large-margin-collapse > .topbar-8,
  .medium-margin-collapse > .topbar-8,
  .small-margin-collapse > .topbar-8,
  .topbar-margin-collapse > .large-8,
  .topbar-margin-collapse > .medium-8,
  .topbar-margin-collapse > .small-8,
  .topbar-margin-collapse > .topbar-8 {
    width: 66.6666666667%;
  }
  .grid-x > .topbar-9,
  .large-margin-collapse > .topbar-9,
  .medium-margin-collapse > .topbar-9,
  .small-margin-collapse > .topbar-9,
  .topbar-margin-collapse > .large-9,
  .topbar-margin-collapse > .medium-9,
  .topbar-margin-collapse > .small-9,
  .topbar-margin-collapse > .topbar-9 {
    width: 75%;
  }
  .grid-x > .topbar-10,
  .large-margin-collapse > .topbar-10,
  .medium-margin-collapse > .topbar-10,
  .small-margin-collapse > .topbar-10,
  .topbar-margin-collapse > .large-10,
  .topbar-margin-collapse > .medium-10,
  .topbar-margin-collapse > .small-10,
  .topbar-margin-collapse > .topbar-10 {
    width: 83.3333333333%;
  }
  .grid-x > .topbar-11,
  .large-margin-collapse > .topbar-11,
  .medium-margin-collapse > .topbar-11,
  .small-margin-collapse > .topbar-11,
  .topbar-margin-collapse > .large-11,
  .topbar-margin-collapse > .medium-11,
  .topbar-margin-collapse > .small-11,
  .topbar-margin-collapse > .topbar-11 {
    width: 91.6666666667%;
  }
  .cell .topbar-grid-frame,
  .grid-x > .topbar-12,
  .large-margin-collapse > .topbar-12,
  .medium-margin-collapse > .topbar-12,
  .small-margin-collapse > .topbar-12,
  .topbar-margin-collapse > .large-12,
  .topbar-margin-collapse > .medium-12,
  .topbar-margin-collapse > .small-12,
  .topbar-margin-collapse > .topbar-12,
  .topbar-up-1 > .cell {
    width: 100%;
  }
  .grid-margin-x > .topbar-auto,
  .grid-margin-x > .topbar-shrink {
    width: auto;
  }
  .grid-margin-x > .topbar-1 {
    width: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-x.topbar-up-6 > .cell,
  .grid-margin-x > .topbar-2 {
    width: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-x.topbar-up-4 > .cell,
  .grid-margin-x > .topbar-3 {
    width: calc(25% - 1.875rem);
  }
  .grid-margin-x.topbar-up-3 > .cell,
  .grid-margin-x > .topbar-4 {
    width: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-x > .topbar-5 {
    width: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-x.topbar-up-2 > .cell,
  .grid-margin-x > .topbar-6 {
    width: calc(50% - 1.875rem);
  }
  .grid-margin-x > .topbar-7 {
    width: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-x > .topbar-8 {
    width: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-x > .topbar-9 {
    width: calc(75% - 1.875rem);
  }
  .grid-margin-x > .topbar-10 {
    width: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-x > .topbar-11 {
    width: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-x.topbar-up-1 > .cell,
  .grid-margin-x > .topbar-12 {
    width: calc(100% - 1.875rem);
  }
  .topbar-up-5 > .cell {
    width: 20%;
  }
  .topbar-up-7 > .cell {
    width: 14.2857142857%;
  }
  .topbar-up-8 > .cell {
    width: 12.5%;
  }
  .grid-margin-x.topbar-up-5 > .cell {
    width: calc(20% - 1.875rem);
  }
  .grid-margin-x.topbar-up-7 > .cell {
    width: calc(14.2857142857% - 1.875rem);
  }
  .grid-margin-x.topbar-up-8 > .cell {
    width: calc(12.5% - 1.875rem);
  }
  .topbar-margin-collapse,
  .topbar-margin-collapse > .cell,
  .topbar-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .topbar-offset-0 {
    margin-left: 0;
  }
  .grid-margin-x > .topbar-offset-0 {
    margin-left: calc(0% + 0.9375rem);
  }
  .topbar-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid-margin-x > .topbar-offset-1 {
    margin-left: calc(8.3333333333% + 0.9375rem);
  }
  .topbar-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid-margin-x > .topbar-offset-2 {
    margin-left: calc(16.6666666667% + 0.9375rem);
  }
  .topbar-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .topbar-offset-3 {
    margin-left: calc(25% + 0.9375rem);
  }
  .topbar-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid-margin-x > .topbar-offset-4 {
    margin-left: calc(33.3333333333% + 0.9375rem);
  }
  .topbar-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid-margin-x > .topbar-offset-5 {
    margin-left: calc(41.6666666667% + 0.9375rem);
  }
  .topbar-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .topbar-offset-6 {
    margin-left: calc(50% + 0.9375rem);
  }
  .topbar-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid-margin-x > .topbar-offset-7 {
    margin-left: calc(58.3333333333% + 0.9375rem);
  }
  .topbar-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid-margin-x > .topbar-offset-8 {
    margin-left: calc(66.6666666667% + 0.9375rem);
  }
  .topbar-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .topbar-offset-9 {
    margin-left: calc(75% + 0.9375rem);
  }
  .topbar-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid-margin-x > .topbar-offset-10 {
    margin-left: calc(83.3333333333% + 0.9375rem);
  }
  .topbar-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid-margin-x > .topbar-offset-11 {
    margin-left: calc(91.6666666667% + 0.9375rem);
  }
  .grid-y > .topbar-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-margin-y > .topbar-auto,
  .grid-margin-y > .topbar-shrink,
  .grid-y > .topbar-shrink {
    height: auto;
  }
  .grid-y > .topbar-1 {
    height: 8.3333333333%;
  }
  .grid-y > .topbar-2 {
    height: 16.6666666667%;
  }
  .grid-y > .topbar-3 {
    height: 25%;
  }
  .grid-y > .topbar-4 {
    height: 33.3333333333%;
  }
  .grid-y > .topbar-5 {
    height: 41.6666666667%;
  }
  .grid-y > .topbar-6 {
    height: 50%;
  }
  .grid-y > .topbar-7 {
    height: 58.3333333333%;
  }
  .grid-y > .topbar-8 {
    height: 66.6666666667%;
  }
  .grid-y > .topbar-9 {
    height: 75%;
  }
  .grid-y > .topbar-10 {
    height: 83.3333333333%;
  }
  .grid-y > .topbar-11 {
    height: 91.6666666667%;
  }
  .cell .grid-y.topbar-grid-frame,
  .grid-y > .topbar-12 {
    height: 100%;
  }
  .topbar-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .topbar-cell-block,
  .topbar-cell-block-y {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar;
  }
  .topbar-cell-block {
    overflow-x: auto;
    max-width: 100%;
  }
  .topbar-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .topbar-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .topbar-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
  }
  .grid-y.topbar-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
  .grid-margin-y > .topbar-1 {
    height: calc(8.3333333333% - 1.875rem);
  }
  .grid-margin-y > .topbar-2 {
    height: calc(16.6666666667% - 1.875rem);
  }
  .grid-margin-y > .topbar-3 {
    height: calc(25% - 1.875rem);
  }
  .grid-margin-y > .topbar-4 {
    height: calc(33.3333333333% - 1.875rem);
  }
  .grid-margin-y > .topbar-5 {
    height: calc(41.6666666667% - 1.875rem);
  }
  .grid-margin-y > .topbar-6 {
    height: calc(50% - 1.875rem);
  }
  .grid-margin-y > .topbar-7 {
    height: calc(58.3333333333% - 1.875rem);
  }
  .grid-margin-y > .topbar-8 {
    height: calc(66.6666666667% - 1.875rem);
  }
  .grid-margin-y > .topbar-9 {
    height: calc(75% - 1.875rem);
  }
  .grid-margin-y > .topbar-10 {
    height: calc(83.3333333333% - 1.875rem);
  }
  .grid-margin-y > .topbar-11 {
    height: calc(91.6666666667% - 1.875rem);
  }
  .grid-margin-y > .topbar-12 {
    height: calc(100% - 1.875rem);
  }
  .grid-frame.grid-margin-y,
  .grid-margin-y.topbar-grid-frame {
    height: calc(100vh + 1.875rem);
  }
  .subnav__list li a:active,
  .subnav__list li a:focus,
  .subnav__list li a:hover {
    text-decoration: underline;
  }
  .container__flex-stack--text {
    width: 315px;
    text-align: center;
  }
}
.container--small-logo {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 142px;
  height: 90px;
  margin: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
}
.container--small-logo img {
  max-height: 50px;
}
.container--small-logo .rating {
  position: absolute;
  top: 3px;
  left: 5px;
  color: #000;
  font-size: 12px;
  font-weight: 700;
}
.container--small-logo .label {
  margin-top: 5px;
  font-weight: 700;
}
.container--is-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  padding: 15px;
  background-color: #ffe;
  color: #000;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.container--is-message .icon--is-error svg {
  color: red;
  width: 18px;
  height: 18px;
}
.container--is-message .icon--is-error svg path,
.container--is-message .icon--is-error svg polygon,
.container--is-message .icon--is-error svg use {
  fill: red;
}
.container--is-message .icon--is-alert svg {
  color: #faa41a;
  width: 18px;
  height: 18px;
}
.container--is-message .icon--is-alert svg path,
.container--is-message .icon--is-alert svg polygon,
.container--is-message .icon--is-alert svg use {
  fill: #faa41a;
}
.container--is-message .icon--is-info svg {
  color: #0061a4;
  width: 18px;
  height: 18px;
}
.container--is-message .icon--is-info svg path,
.container--is-message .icon--is-info svg polygon,
.container--is-message .icon--is-info svg use {
  fill: #0061a4;
}
.container--is-message .text {
  margin-left: 3px;
}
.container--is-message .text .heading {
  margin-top: 2px;
  margin-bottom: 5px;
  font-weight: 700;
}
.container--is-black-friday-ad {
  display: flex;
  width: 100%;
  border-top: 10px solid #ff2128;
}
.container--is-black-friday-ad img {
  max-height: 70px;
  transition: transform 0.3s ease-in-out;
}
.container--is-black-friday-ad:hover img {
  transform: rotate(-45deg);
}
.container--is-black-friday-ad .ad-bg1 {
  min-width: 100px;
  padding: 25px;
  background-color: #ff2128;
  text-align: center;
}
@media print, screen and (min-width: 40em) {
  .container--is-black-friday-ad .ad-bg1 {
    min-width: 150px;
    padding: 20px 45px;
  }
}
.container--is-black-friday-ad .ad-bg2 {
  width: 100%;
  padding: 30px 30px 15px;
  background-color: #000;
}
.container--is-black-friday-ad .ad-bg2 h3 {
  margin-bottom: 5px;
}
.color--grayscale-color1,
.container--is-black-friday-ad .ad-bg2 h3,
.container--is-black-friday-ad .ad-bg2 p {
  color: #fff;
}
.container--is-centered {
  display: flex;
  justify-content: center;
}
.container--is-dense-list {
  padding: 30px 60px;
  border: 3px solid #000;
  box-shadow: 8px 8px 0 0 #000;
}
.border--red {
  border: 1px dashed red;
}
.border--blue {
  border: 1px dashed #0061a4;
}
.border--green {
  border: 1px dashed #0f0;
}
.border--purple {
  border: 1px dashed #e200c4;
}
.provider-logos-sticky-container {
  width: 100%;
  z-index: 1006;
}
.speed-comparison ul {
  width: 80%;
  margin: 0 auto;
  padding: 0;
}
.speed-comparison ul li {
  margin-top: 14px;
  font-weight: 700;
  list-style: none;
}
.speed-comparison ul .range {
  display: block;
  position: relative;
  margin-top: 0.5em;
  padding: 0.2em 0;
  text-align: center;
}
.speed-comparison ul .range::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  z-index: -1;
}
.speed-comparison .brand-speed .range {
  border: 2px solid #5050b9;
  border-radius: 5px;
  color: #fff;
}
.speed-comparison .brand-speed .range::after {
  width: 70%;
  background-image: linear-gradient(to right, #5050b9, #3d3d99);
}
.speed-comparison .brand-speed--charter .range {
  border: 2px solid #0061a4;
  border-radius: 5px;
  color: #fff;
  text-shadow: 1px 1px 1px #151515;
}
.speed-comparison .brand-speed--charter .range::after {
  width: 70%;
  background-image: repeating-linear-gradient(
    -55deg,
    #0061a4,
    #0061a4 10px,
    #005590 10px,
    #005590 20px
  );
}
.speed-comparison .city-speed .range {
  border: 2px solid #5b5b5b;
  border-radius: 5px;
  color: #5b5b5b;
}
.speed-comparison .city-speed .range::after {
  width: 20%;
  background-image: repeating-linear-gradient(
    -55deg,
    #818181,
    #818181 10px,
    #777 10px,
    #777 20px
  );
}
#breadcrumbs {
  position: relative;
  padding-top: 1.5em;
}
#breadcrumbs a,
#breadcrumbs p {
  color: #7b7b7b;
}
#breadcrumbs a {
  padding: 0 8px;
  font-size: 0.875rem;
  text-decoration: none;
}
#breadcrumbs a:hover {
  color: #555;
}
.hero--generic #breadcrumbs {
  position: relative;
  padding-top: 15px;
}
.hero--generic #breadcrumbs a,
.hero--generic #breadcrumbs p {
  color: #f3f3f3;
}
.hero--generic #breadcrumbs a {
  padding: 0 8px;
  font-size: 0.8125rem;
}
.hero--generic .breadcrumb {
  position: absolute;
  top: 10px;
  font-size: 0.8125rem;
  text-align: left;
}
.hero--generic .breadcrumb a,
.hero--generic .breadcrumb span {
  padding: 0 8px;
  color: #f3f3f3;
}
.hero--home {
  background-image: url(../../../public/ban2.jpg);
}
.card__rating-dialog .card__rating-dialog__details {
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
}
.card__rating-dialog dl {
  padding: 1.5em 1em 2em;
  line-height: 2em;
}
.card__rating-dialog dl dt {
  width: auto;
  font-weight: 700;
  line-height: 1;
  text-align: left;
}
.card__rating-dialog dl dt span,
.disclosure-container a:hover,
a:focus,
a:hover {
  color: #ff2128;
}
.card__rating-dialog dl dd {
  font-weight: 400;
}
.card__rating-dialog dl dd p {
  display: block;
  font-size: 14px;
  line-height: 1;
  text-align: left;
}
.card__rating-dialog dl dd .card__rating-dialog__bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #5050b9;
  text-align: center;
}
.card__rating-dialog dl dd .card__rating-dialog__bottom-bar::after {
  position: absolute;
  bottom: -28px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 16px 11px;
  border-style: solid;
  border-color: #5050b9 transparent transparent;
  content: "";
}
.tabs__container .tabs li,
.tabs__container .tabs-mobile li {
  height: 45px;
  border-top: 2px solid #c5c5c5;
  border-right: 2px solid #c5c5c5;
  border-bottom: 2px solid #c5c5c5;
  border-left: 2px solid #c5c5c5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.tabs__container .tabs li.is-active,
.tabs__container .tabs-mobile li.is-active {
  border-bottom: 2px solid #fefefe;
}
.card__rating-dialog dl dd .card__rating-dialog__bottom-bar a {
  color: #f3f3f3;
  font-weight: 700;
}
.card__rating-dialog__close {
  text-align: center;
}
.card__rating-dialog__close a {
  margin-top: 12px;
  color: #f3f3f3;
  font-size: 24px;
  font-weight: 700;
  text-decoration: underline;
}
.link--inherits-color,
.link--inherits-color:focus,
.link--inherits-color:hover,
a.container {
  color: inherit;
}
.has-button a:first-of-type div,
.hero-components .vc-table-scrolling__cell.has-button a:first-of-type div,
.link--is-bold,
.vc-table-scrolling__cell.has-button a:first-of-type div,
a.button div {
  font-weight: 700;
}
.has-button a:hover:first-of-type,
.hero-components .vc-table-scrolling__cell.has-button a:hover:first-of-type,
a.button:hover {
  text-decoration: none !important;
}
.link--is-block {
  display: block;
}
.flex,
.link--is-flex {
  display: flex;
}
.link--is-uppercase {
  text-transform: uppercase;
}
.link--has-icon-on-left {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.link--has-icon-on-left svg {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.link--has-icon-on-right {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.channel-lineup__row,
.store-locations__map,
.tabs__panels .tabs__panels-border-top,
.tabs__panels--higher .tabs__panels-border-top {
  display: none;
}
.link--has-icon-on-right svg {
  width: 12px;
  height: 12px;
  margin-left: 5px;
}
.link--is-disabled,
.link--is-disabled svg {
  color: #cdcdcd;
}
.main-wrap.page ul li,
.main-wrap.page ul.tabs-mobile li:last-child,
.main-wrap.post ul li,
.main-wrap.post ul.tabs-mobile li:last-child,
.main-wrap.search ul li,
.main-wrap.search ul.tabs-mobile li:last-child {
  margin-bottom: 0.7em;
}
.tabs__container {
  position: static;
  margin-top: 50px;
  margin-bottom: 0;
  text-align: center;
}
.tabs__container .tabs {
  position: relative;
  height: 45px;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #c5c5c5;
  list-style: none;
  z-index: 1;
}
.tabs__container .tabs li {
  display: block;
  position: static;
  margin: 0;
}
.tabs__container .tabs li:hover {
  background-color: #e6e6e6;
}
.tabs__container .tabs li a {
  display: block;
  height: 43px;
  padding-top: 7px;
  padding-right: 20px;
  padding-left: 20px;
  color: #151515;
  font-weight: 700;
  text-decoration: none;
}
.cta-new__heading a:hover,
.disclosure-container a,
.featured-post__info h3 a:hover,
.footer__email-form__disclaimer a:hover,
.main-wrap.page .search-result .result-post-info h4 a:hover,
.main-wrap.post .search-result .result-post-info h4 a:hover,
.main-wrap.search .search-result .result-post-info h4 a:hover,
.package-card--spectrum .package-features__list-item__link,
.package-card--xfinity .package-features__list-item__link,
.related-content a:hover,
.table--channel-lineup tr th a:hover {
  text-decoration: underline;
}
.tabs__container .tabs li a:focus,
.tabs__container .tabs li a:hover {
  color: #000;
}
.tabs__container .tabs-mobile {
  position: relative;
  height: 45px;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid #c5c5c5;
  list-style: none;
}
.tabs__container .tabs-mobile li {
  display: inline-block;
  position: static;
  margin: 0;
  padding: 7px 20px 0;
}
.tabs__container .tabs-mobile li.next,
.tabs__container .tabs-mobile li.previous {
  padding-right: 5px;
  padding-left: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  color: #151515;
}
.tabs__container .tabs-mobile li.next svg,
.tabs__container .tabs-mobile li.previous svg {
  color: #cdcdcd;
  width: 22px;
  height: 22px;
}
.tabs__container .tabs-mobile li.next:focus,
.tabs__container .tabs-mobile li.next:hover,
.tabs__container .tabs-mobile li.previous:focus,
.tabs__container .tabs-mobile li.previous:hover {
  color: #a7a7a7;
}
.tabs__container .tabs-mobile li.next:focus path,
.tabs__container .tabs-mobile li.next:focus polygon,
.tabs__container .tabs-mobile li.next:focus use,
.tabs__container .tabs-mobile li.next:hover path,
.tabs__container .tabs-mobile li.next:hover polygon,
.tabs__container .tabs-mobile li.next:hover use,
.tabs__container .tabs-mobile li.previous:focus path,
.tabs__container .tabs-mobile li.previous:focus polygon,
.tabs__container .tabs-mobile li.previous:focus use,
.tabs__container .tabs-mobile li.previous:hover path,
.tabs__container .tabs-mobile li.previous:hover polygon,
.tabs__container .tabs-mobile li.previous:hover use {
  fill: #a7a7a7;
}
.tabs__container .tabs-mobile li a {
  color: #151515;
  font-weight: 700;
}
.tabs__container .tabs-mobile li a:focus,
.tabs__container .tabs-mobile li a:hover {
  color: #000;
}
.tabs__header h2 {
  padding: 30px 0 0;
}
.tabs__panels--higher {
  padding-top: 50px;
}
.channel-lineup__row--visible,
.table--default tbody .table-default__row {
  display: table-row;
}
.table--default {
  width: 100%;
  margin-bottom: 0.9375rem;
  border-collapse: collapse;
}
.table--default td.sticky-column,
.table--default th.sticky-column {
  position: sticky;
  left: 0;
  z-index: 10;
  background-clip: padding-box;
}
.table--default tr th {
  padding: 0.625rem;
  border: 1px solid #1d253c;
  background-color: #1d253c;
  color: #f3f3f3;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
@media print, screen and (min-width: 40em) {
  .hero--home {
    background-image: url(../../../public/ban2.jpg);
  }
  .tabs__container .tabs li {
    display: inline-block;
    position: relative;
    margin-right: 10px;
  }
  .tabs__container .tabs li.is-active {
    border-bottom: 2px solid #fefefe;
  }
  .tabs__panels--higher .tabs__panels-border-top {
    display: block;
    position: relative;
    top: 0;
    width: 100%;
    height: 3px;
    background-color: #cdcdcd;
    z-index: 0;
  }
  .table--default tr th {
    font-size: 0.875rem;
    line-height: 1rem;
  }
}
.table--default tr th a {
  color: #f3f3f3;
  text-decoration: none;
}
.table--default tr td {
  padding: 0.625rem;
  border: 1px solid #c5c5c5;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.table--default tbody .table-default__row:nth-child(2n),
.table--default tbody .table-default__row:nth-child(2n) td.sticky-column {
  background-color: #f3f3f3;
}
.bg-white,
.featured-slider,
.table--default tbody .table-default__row:nth-child(odd),
.table--default tbody .table-default__row:nth-child(odd) td.sticky-column {
  background-color: #fff;
}
.table--channel-lineup {
  width: 100%;
  border-collapse: collapse;
}
.table--channel-lineup tr th {
  padding: 0.625rem;
  border: 1px solid #5b5b5b;
  background-color: #5b5b5b;
  z-index: 100;
}
.table--channel-lineup tr th a {
  color: #f3f3f3;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-decoration: none;
}
.table--channel-lineup tr td {
  padding: 0.625rem;
  border: 1px solid #c5c5c5;
}
.full-width-hero-card1 .contents .column-4,
.table--channel-lineup tr td:first-child {
  width: 25%;
}
.table--channel-lineup tr td span {
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
  border-radius: 3.125rem;
}
.channel-lineup__row--highlight {
  background-color: #e6e6e6;
}
.channel-lineup__row--popular > span {
  display: inline-block;
  width: 12.5rem;
  height: 3.75rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding: 0.625rem 0.3125rem;
  border: 1px solid #fff;
  background-color: #fff;
  text-align: center;
  vertical-align: middle;
}
.channel-lineup__row--popular > span img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.channel-lineup__row__cell--popular {
  min-width: 120px;
}
.table--sub-block {
  margin: 0 0 10px;
  padding: 10px 0 0;
  border: 1px solid #c5c5c5;
  text-align: center;
}
.margin--top-0 {
  margin-top: 0;
}
.best-of-hero-card .cta-link,
.margin--top-5,
.margin--top-5-small-only,
.single-review-hero-card .cta-link {
  margin-top: 5px;
}
@media print, screen and (min-width: 40em) {
  .table--channel-lineup tr th a,
  .table--default tr td {
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .margin--top-5-small-only {
    margin-top: 0;
  }
}
.margin--top-15,
.margin--top-15-small-only {
  margin-top: 15px;
}
@media print, screen and (min-width: 40em) {
  .margin--top-15-small-only {
    margin-top: 0;
  }
}
.margin--top-30,
.margin--top-30-small-only {
  margin-top: 30px;
}
@media print, screen and (min-width: 40em) {
  .margin--top-30-small-only {
    margin-top: 0;
  }
}
.margin--top-60,
.margin--top-60-small-only {
  margin-top: 60px;
}
@media print, screen and (min-width: 40em) {
  .margin--top-60-small-only {
    margin-top: 0;
  }
}
.margin--top-120 {
  margin-top: 120px;
}
.margin--bottom-0 {
  margin-bottom: 0;
}
.margin--bottom-5 {
  margin-bottom: 5px;
}
.margin--bottom-15,
.margin--bottom-15-small-only {
  margin-bottom: 15px;
}
@media print, screen and (min-width: 40em) {
  .margin--bottom-15-small-only {
    margin-bottom: 0;
  }
  .store-locations__map {
    display: block;
    width: auto;
    height: 398px;
    border: 1px #c5c5c5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
.compare-card .card-bottom .card-description,
.generic-form .input-row,
.main-wrap.page .results-text,
.main-wrap.post .results-text,
.main-wrap.search .results-text,
.margin--bottom-30,
.post-block,
.slick-slider {
  margin-bottom: 30px;
}
.margin--bottom-60 {
  margin-bottom: 60px;
}
.margin--bottom-120 {
  margin-bottom: 120px;
}
.store-locations__select-area {
  display: block;
  height: 3.75rem;
  margin-bottom: 0;
  padding: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}
.store-locations__select-area .select-area__text {
  display: none;
}
.store-locations__select-area .select-area__input select {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
}
.store-locations__error {
  width: auto;
  margin-bottom: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  background-color: #ffe;
  color: #151515;
}
.store-locations__loading {
  width: 100%;
  text-align: center;
}
.store-locations__loading .loading-gif {
  width: 100px;
  height: 100px;
  margin-top: 0;
}
.store-locations__locations .location {
  display: flex;
  margin-bottom: 12px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  color: #151515;
}
.store-locations__locations .location:hover {
  background-color: #eee;
  cursor: pointer;
}
.store-locations__locations .location--is-hidden {
  display: none;
}
.store-locations__locations .location__number-area {
  padding-right: 5px;
}
.main-wrap.page .search-result .result-post-info,
.main-wrap.page .search-result .result-post-thumbnail,
.main-wrap.post .search-result .result-post-info,
.main-wrap.post .search-result .result-post-thumbnail,
.main-wrap.search .search-result .result-post-info,
.main-wrap.search .search-result .result-post-thumbnail,
.store-locations__locations .location__number-area .number {
  width: auto;
}
.compare-card .card-bottom .card-title,
.store-locations__locations .location__address-area .name {
  font-weight: 700;
}
.store-locations__nav .nav__links {
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 0;
}
.store-locations__nav .nav__links--is-hidden,
.toggle--is-hidden {
  display: none;
}
.store-locations__nav .nav__links .link {
  color: #5050b9;
}
.package-card--buckeye-broadband .brand-icons__list__item.available svg,
.package-card--buckeye-broadband .package-features__visibility-toggle svg {
  fill: #11407a;
}
.store-locations__nav .nav__links .link:hover {
  color: #4444ab;
}
.store-locations__nav .nav__links .link:hover path,
.store-locations__nav .nav__links .link:hover polygon,
.store-locations__nav .nav__links .link:hover use {
  fill: #4444ab;
}
.store-locations__nav .nav__links .link.link--is-disabled,
.store-locations__nav .nav__links .link.link--is-disabled svg {
  color: #cdcdcd;
}
.store-locations__nav .nav__links .link.link--is-disabled svg path,
.store-locations__nav .nav__links .link.link--is-disabled svg polygon,
.store-locations__nav .nav__links .link.link--is-disabled svg use {
  fill: #cdcdcd;
}
.disclosure-container {
  margin: 0;
  padding: 10px;
  background-color: #1d253c;
  color: #f3f3f3;
  line-height: 1em;
}
.disclosure-container a {
  color: #f3f3f3;
}
.cta-new__heading a,
.main-wrap.index .post-snippet a.post-title,
.main-wrap.page .search-result .result-post-info h4 a,
.main-wrap.post .search-result .result-post-info h4 a,
.main-wrap.search .search-result .result-post-info h4 a,
.related-content a,
a.skip-to-main {
  text-decoration: none;
}
.disclosure-container .disclosure-text {
  font-size: 0.625rem;
}
.disclosure-container .disclosure-close {
  float: right;
  font-size: 1.5rem;
  cursor: pointer;
  color: #f3f3f3;
  margin-top: -3px;
  height: 20px;
}
.color--grayscale-color1 > a {
  color: #fff;
}
.color--grayscale-color2,
.color--grayscale-color2 > a {
  color: #f3f3f3;
}
.color--grayscale-color1 > a:active,
.color--grayscale-color1 > a:focus,
.color--grayscale-color1 > a:hover {
  color: #f2f2f2;
}
a.color--grayscale-color1:active,
a.color--grayscale-color1:focus,
a.color--grayscale-color1:hover {
  color: #f2f2f2 !important;
}
hr.color--grayscale-color1 {
  border-color: #fff !important;
}
.color--grayscale-color2 path,
.color--grayscale-color2 polygon,
.color--grayscale-color2 use {
  fill: #f3f3f3;
}
.color--grayscale-color3,
.color--grayscale-color3 > a {
  color: #c5c5c5;
}
.color--grayscale-color2 > a:active,
.color--grayscale-color2 > a:focus,
.color--grayscale-color2 > a:hover {
  color: #e6e6e6;
}
a.color--grayscale-color2:active,
a.color--grayscale-color2:focus,
a.color--grayscale-color2:hover {
  color: #e6e6e6 !important;
}
hr.color--grayscale-color2 {
  border-color: #f3f3f3 !important;
}
.color--grayscale-color3 path,
.color--grayscale-color3 polygon,
.color--grayscale-color3 use {
  fill: #c5c5c5;
}
.color--grayscale-color4,
.color--grayscale-color4 > a {
  color: #5b5b5b;
}
.color--grayscale-color3 > a:active,
.color--grayscale-color3 > a:focus,
.color--grayscale-color3 > a:hover {
  color: #b8b8b8;
}
a.color--grayscale-color3:active,
a.color--grayscale-color3:focus,
a.color--grayscale-color3:hover {
  color: #b8b8b8 !important;
}
hr.color--grayscale-color3 {
  border-color: #c5c5c5 !important;
}
.color--grayscale-color4 path,
.color--grayscale-color4 polygon,
.color--grayscale-color4 use {
  fill: #5b5b5b;
}
.color--grayscale-color4 > a:active,
.color--grayscale-color4 > a:focus,
.color--grayscale-color4 > a:hover {
  color: #4e4e4e;
}
a.color--grayscale-color4:active,
a.color--grayscale-color4:focus,
a.color--grayscale-color4:hover {
  color: #4e4e4e !important;
}
.color--grayscale-color5,
.color--grayscale-color5 > a,
.main-wrap.page h1,
.main-wrap.page h2,
.main-wrap.page h3,
.main-wrap.page h4,
.main-wrap.page h5,
.main-wrap.post h1,
.main-wrap.post h2,
.main-wrap.post h3,
.main-wrap.post h4,
.main-wrap.post h5,
.main-wrap.search h1,
.main-wrap.search h2,
.main-wrap.search h3,
.main-wrap.search h4,
.main-wrap.search h5,
.zip-finder--inline {
  color: #151515;
}
hr.color--grayscale-color4 {
  border-color: #5b5b5b !important;
}
.color--grayscale-color5 path,
.color--grayscale-color5 polygon,
.color--grayscale-color5 use {
  fill: #151515;
}
.color--grayscale-color5 > a:active,
.color--grayscale-color5 > a:focus,
.color--grayscale-color5 > a:hover {
  color: #080808;
}
a.color--grayscale-color5:active,
a.color--grayscale-color5:focus,
a.color--grayscale-color5:hover {
  color: #080808 !important;
}
.color--grayscale-color6,
.color--grayscale-color6 > a,
.color--grayscale-color6 > a:active,
.color--grayscale-color6 > a:focus,
.color--grayscale-color6 > a:hover {
  color: #000;
}
hr.color--grayscale-color5 {
  border-color: #151515 !important;
}
.color--grayscale-color6 path,
.color--grayscale-color6 polygon,
.color--grayscale-color6 use {
  fill: #000;
}
.color--generic-color1,
.color--generic-color1 > a {
  color: #087b82;
}
a.color--grayscale-color6:active,
a.color--grayscale-color6:focus,
a.color--grayscale-color6:hover {
  color: #000 !important;
}
hr.color--grayscale-color6 {
  border-color: #000 !important;
}
.color--generic-color1 path,
.color--generic-color1 polygon,
.color--generic-color1 use {
  fill: #087b82;
}
.color--generic-color2,
.color--generic-color2 > a {
  color: #df432b;
}
.color--generic-color1 > a:active,
.color--generic-color1 > a:focus,
.color--generic-color1 > a:hover {
  color: #07646a;
}
a.color--generic-color1:active,
a.color--generic-color1:focus,
a.color--generic-color1:hover {
  color: #07646a !important;
}
hr.color--generic-color1 {
  border-color: #087b82 !important;
}
.color--generic-color2 path,
.color--generic-color2 polygon,
.color--generic-color2 use {
  fill: #df432b;
}
.color--generic-color3,
.color--generic-color3 > a {
  color: #eba74b;
}
.color--generic-color2 > a:active,
.color--generic-color2 > a:focus,
.color--generic-color2 > a:hover {
  color: #d13720;
}
a.color--generic-color2:active,
a.color--generic-color2:focus,
a.color--generic-color2:hover {
  color: #d13720 !important;
}
hr.color--generic-color2 {
  border-color: #df432b !important;
}
.color--generic-color3 path,
.color--generic-color3 polygon,
.color--generic-color3 use {
  fill: #eba74b;
}
.color--generic2-color1,
.color--generic2-color1 > a,
.main-wrap.page .hero-components .features-container .features-html a,
.main-wrap.post .hero-components .features-container .features-html a,
.main-wrap.search .hero-components .features-container .features-html a,
.stars.charcoal input[type="radio"]:checked ~ label,
.stars.charcoal.dynamic:not(:checked) > label:hover,
.stars.charcoal.dynamic:not(:checked) > label:hover ~ label {
  color: #5050b9;
}
.color--generic-color3 > a:active,
.color--generic-color3 > a:focus,
.color--generic-color3 > a:hover {
  color: #e89c34;
}
a.color--generic-color3:active,
a.color--generic-color3:focus,
a.color--generic-color3:hover {
  color: #e89c34 !important;
}
hr.color--generic-color3 {
  border-color: #eba74b !important;
}
.color--generic2-color1 path,
.color--generic2-color1 polygon,
.color--generic2-color1 use {
  fill: #5050b9;
}
.color--generic2-color2,
.color--generic2-color2 > a {
  color: #ff2128;
}
.color--generic2-color1 > a:active,
.color--generic2-color1 > a:focus,
.color--generic2-color1 > a:hover {
  color: #4444ab;
}
a.color--generic2-color1:active,
a.color--generic2-color1:focus,
a.color--generic2-color1:hover {
  color: #4444ab !important;
}
hr.color--generic2-color1 {
  border-color: #5050b9 !important;
}
.color--generic2-color2 path,
.color--generic2-color2 polygon,
.color--generic2-color2 use {
  fill: #ff2128;
}
.color--charter-color1,
.color--charter-color1 > a {
  color: #56c4c0;
}
.color--generic2-color2 > a:active,
.color--generic2-color2 > a:focus,
.color--generic2-color2 > a:hover {
  color: #ff080f;
}
a.color--generic2-color2:active,
a.color--generic2-color2:focus,
a.color--generic2-color2:hover {
  color: #ff080f !important;
}
hr.color--generic2-color2 {
  border-color: #ff2128 !important;
}
.color--charter-color1 path,
.color--charter-color1 polygon,
.color--charter-color1 use {
  fill: #56c4c0;
}
.color--charter-color2,
.color--charter-color2 > a {
  color: #0061a4;
}
.color--charter-color1 > a:active,
.color--charter-color1 > a:focus,
.color--charter-color1 > a:hover {
  color: #43bdb9;
}
a.color--charter-color1:active,
a.color--charter-color1:focus,
a.color--charter-color1:hover {
  color: #43bdb9 !important;
}
hr.color--charter-color1 {
  border-color: #56c4c0 !important;
}
.color--charter-color2 path,
.color--charter-color2 polygon,
.color--charter-color2 use {
  fill: #0061a4;
}
.color--spectrum-color1,
.color--spectrum-color1 > a {
  color: #003057;
}
.color--charter-color2 > a:active,
.color--charter-color2 > a:focus,
.color--charter-color2 > a:hover {
  color: #00528b;
}
a.color--charter-color2:active,
a.color--charter-color2:focus,
a.color--charter-color2:hover {
  color: #00528b !important;
}
hr.color--charter-color2 {
  border-color: #0061a4 !important;
}
.color--spectrum-color1 path,
.color--spectrum-color1 polygon,
.color--spectrum-color1 use {
  fill: #003057;
}
.color--spectrum-color2,
.color--spectrum-color2 > a {
  color: #0077bc;
}
.color--spectrum-color1 > a:active,
.color--spectrum-color1 > a:focus,
.color--spectrum-color1 > a:hover {
  color: #00223e;
}
a.color--spectrum-color1:active,
a.color--spectrum-color1:focus,
a.color--spectrum-color1:hover {
  color: #00223e !important;
}
hr.color--spectrum-color1 {
  border-color: #003057 !important;
}
.color--spectrum-color2 path,
.color--spectrum-color2 polygon,
.color--spectrum-color2 use {
  fill: #0077bc;
}
.color--spectrum-color3,
.color--spectrum-color3 > a {
  color: #faa41a;
}
.color--spectrum-color2 > a:active,
.color--spectrum-color2 > a:focus,
.color--spectrum-color2 > a:hover {
  color: #0067a3;
}
a.color--spectrum-color2:active,
a.color--spectrum-color2:focus,
a.color--spectrum-color2:hover {
  color: #0067a3 !important;
}
hr.color--spectrum-color2 {
  border-color: #0077bc !important;
}
.color--spectrum-color3 path,
.color--spectrum-color3 polygon,
.color--spectrum-color3 use {
  fill: #faa41a;
}
.color--time-warner-color1,
.color--time-warner-color1 > a {
  color: #01325d;
}
.color--spectrum-color3 > a:active,
.color--spectrum-color3 > a:focus,
.color--spectrum-color3 > a:hover {
  color: #f59905;
}
a.color--spectrum-color3:active,
a.color--spectrum-color3:focus,
a.color--spectrum-color3:hover {
  color: #f59905 !important;
}
hr.color--spectrum-color3 {
  border-color: #faa41a !important;
}
.color--time-warner-color1 path,
.color--time-warner-color1 polygon,
.color--time-warner-color1 use {
  fill: #01325d;
}
.color--xfinity-color1,
.color--xfinity-color1 > a {
  color: #2b9cd8;
}
.color--time-warner-color1 > a:active,
.color--time-warner-color1 > a:focus,
.color--time-warner-color1 > a:hover {
  color: #012444;
}
a.color--time-warner-color1:active,
a.color--time-warner-color1:focus,
a.color--time-warner-color1:hover {
  color: #012444 !important;
}
hr.color--time-warner-color1 {
  border-color: #01325d !important;
}
.color--xfinity-color1 path,
.color--xfinity-color1 polygon,
.color--xfinity-color1 use {
  fill: #2b9cd8;
}
.color--xfinity-color2,
.color--xfinity-color2 > a {
  color: #d31245;
}
.color--xfinity-color1 > a:active,
.color--xfinity-color1 > a:focus,
.color--xfinity-color1 > a:hover {
  color: #248dc5;
}
a.color--xfinity-color1:active,
a.color--xfinity-color1:focus,
a.color--xfinity-color1:hover {
  color: #248dc5 !important;
}
hr.color--xfinity-color1 {
  border-color: #2b9cd8 !important;
}
.color--xfinity-color2 path,
.color--xfinity-color2 polygon,
.color--xfinity-color2 use {
  fill: #d31245;
}
.color--xfinity-color2 > a:active,
.color--xfinity-color2 > a:focus,
.color--xfinity-color2 > a:hover {
  color: #bc103d;
}
a.color--xfinity-color2:active,
a.color--xfinity-color2:focus,
a.color--xfinity-color2:hover {
  color: #bc103d !important;
}
hr.color--xfinity-color2 {
  border-color: #d31245 !important;
}
.color__bg--generic-color1 {
  background-color: #5050b9;
}
.color__bg--generic-color5 {
  background-color: #576a7a;
}
.color__bg--generic-color5-shade-30 {
  background-color: #404d58;
}
.toggle--is-hidden-override {
  display: none !important;
}
.toggle--is-shown {
  display: block;
  margin-bottom: 15px;
}
.toggle--has-expanded-area {
  height: 100px;
}
.toggle--has-expanded-area svg {
  margin: 0 100px;
}
.toggle--has-border-bottom {
  border-bottom: 2px solid #2b9cd8;
}
.compare-card .card-bottom,
.toggle--has-border-bottom-grayscale-color3 {
  border-bottom: 2px solid #c5c5c5;
}
.single-review-hero-card .contents .features-container,
.toggle__section {
  margin-bottom: 15px;
  text-align: left;
}
.toggle-option {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0;
}
.toggle-option__section {
  margin-left: 0;
}
.toggle-option .toggle-icon {
  width: 32px;
}
.toggle-option .toggle-label {
  width: auto;
  margin-top: 3px;
  margin-bottom: 15px;
  margin-left: 3px;
}
.article-callout__image,
.callout-area p,
.footer__zip-form,
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature:not(:last-child),
.full-width-background
  .full-width-hero-card2
  .label-value-features
  .feature:not(:last-child),
.full-width-hero-card1 .features-bullets .feature:not(:last-child),
.full-width-hero-card1 .label-value-features .feature:not(:last-child),
.image-ads .image-ad-container.vertical-space-below,
.main-wrap.page .hero--generic h1,
.main-wrap.post .hero--generic h1,
.main-wrap.search .hero--generic h1,
.toggle-option__container {
  margin-bottom: 15px;
}
.toggle-option__container:last-child {
  margin-bottom: 0;
}
.rating-stars-container {
  margin-bottom: -5px;
}
.best-of-hero-card .stars-container,
.product-card .stars-container,
.rating-stars-container .stars-container,
.single-review-hero-card .stars-container {
  display: inline-block;
  position: relative;
}
.best-of-hero-card .stars-container .stars-full,
.product-card .stars-container .stars-full,
.rating-stars-container .stars-container .stars-full,
.single-review-hero-card .stars-container .stars-full {
  position: absolute;
  top: 0;
  left: 0;
}
.rating-stars-container .rating-star-empty {
  width: 20px;
  background-image: url("../images/components/rating-stars/rating-star-empty.svg");
  background-repeat: no-repeat;
  background-position: 0 100%;
}
.rating-stars-container .rating-star-full {
  width: 20px;
  min-height: 20px;
  background-image: url("../images/components/rating-stars/rating-star-full.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
}
._static-content--center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.logo-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.logo-row__item {
  flex: 1 1 33.33%;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.logo-row__item:first-of-type,
.logo-row__item:first-of-type a,
.vc_separator .vc_icon_element:not(.vc_icon_element-have-style) + h4 {
  padding-left: 0;
}
.logo-row__item span {
  display: block;
  max-width: 180px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}
.logo-row__item img {
  max-width: 100%;
}
.logo-row--only-5 .logo-row__item {
  max-width: 33.33%;
}
.logo-row__break,
.vc-table-scrolling {
  display: block;
  width: 100%;
}
.article-callout,
.margin-top {
  margin-top: 30px;
}
.article-callout__image img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  object-position: center;
}
.article-callout__text {
  overflow: auto;
}
.reveal.exit-intent-popup h3 {
  margin-bottom: 1.6875rem;
  font-size: 1.125rem;
}
.reveal.exit-intent-popup .margin-small {
  margin: 15px;
}
.reveal.exit-intent-popup .exit-label {
  margin-bottom: 0;
  line-height: 3rem;
  margin-left: 15px;
}
.ca-wrapper {
  position: relative;
  max-width: 520px;
  overflow: hidden;
}
.ca-wrapper .ca-btn {
  position: relative;
  width: 100%;
  height: 40px;
  transition: 0.3s ease-in-out !important;
  z-index: 3;
  color: #fff !important;
}
.ca-wrapper .ca-btn.off {
  transform: translateX(100%);
}
.ca-wrapper .zip-checker__results {
  display: none;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  text-align: center;
  visibility: hidden;
}
.ca-wrapper .zip-checker__results.on {
  display: block;
  opacity: 1;
  visibility: visible;
}
.ca-wrapper .zip-checker__results .ca-unavailable-btn {
  width: calc(100% - 40px) !important;
  font-size: 0.875rem !important;
}
.ca-wrapper .zip-checker__results .ca-pencil {
  width: 40px;
  background-color: #5050b9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ca-wrapper .zip-checker__results .results-not-found {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.ca-wrapper .zip-checker__results .results-not-found img {
  width: 20px;
  background-color: #5050b9;
}
.ca-wrapper .form-error,
.product-card .cta-link {
  margin-top: 10px;
}
.zip-checker.ca-form {
  display: flex;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
}
.zip-checker.ca-form input[name="zipcode"] {
  height: 100%;
  width: 100%;
  border-radius: 0;
  margin: 0;
}
.has-button .zip-checker.ca-form a:first-of-type,
.zip-checker.ca-form .button,
.zip-checker.ca-form .has-button a:first-of-type {
  height: 100%;
}
.has-button .zip-checker.ca-form a:first-of-type svg,
.zip-checker.ca-form .button svg,
.zip-checker.ca-form .has-button a:first-of-type svg {
  height: 24px;
  width: 24px;
}
.zip-checker.ca-form .zip-checker__fields,
.zip-checker.ca-form .zip-checker__input__secondary {
  width: 100%;
  height: 40px;
}
.ppc-zip-form-dialog__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1005;
  background-color: rgba(10, 10, 10, 0.87);
  overflow-y: hidden;
}
.ppc-zip-form-dialog__content {
  position: relative;
  top: 20%;
  z-index: 1006;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
.ppc-zip-form-dialog__heading {
  margin: 0 auto 15px;
  color: #fefefe;
  text-align: center;
}
.cta-new,
.footer__connect--generic li a,
.footer__local--generic li a,
.footer__providers--generic li a,
.site__footer--generic {
  color: #fff;
}
.ppc-zip-form-dialog__top,
.reveal-dialog .dialog__top {
  position: relative;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #5050b9;
}
.ppc-zip-form-dialog__top h4 {
  margin-bottom: 0;
  margin-left: 15px;
  color: #fefefe;
  font-size: 0.875rem;
  line-height: 54px;
}
@media print, screen and (min-width: 40em) {
  .store-locations__select-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.375rem;
    margin-bottom: 12px;
    padding: 1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgba(10, 10, 10, 0.8);
  }
  .store-locations__select-area .select-area__text {
    display: inline-block;
    color: #f3f3f3;
  }
  .store-locations__select-area .select-area__input select {
    min-width: 12.5rem;
  }
  .store-locations__loading .loading-gif {
    margin-top: 150px;
  }
  .disclosure-container .disclosure-text {
    font-size: 0.8125rem;
  }
  .toggle-option__section {
    margin-left: 35px;
  }
  .logo-row__item {
    flex: 1 0 16.66%;
    width: auto;
    padding: 15px;
  }
  .logo-row__item a {
    padding: 15px;
  }
  .logo-row--only-5 .logo-row__item {
    max-width: auto;
  }
  .article-callout {
    margin-top: 15px;
  }
  .article-callout__image {
    margin-bottom: 30px;
  }
  .article-callout__image img {
    height: 220px;
  }
  .reveal.exit-intent-popup {
    width: 41.125rem;
  }
  .reveal.exit-intent-popup .dialog__bottom,
  .reveal.exit-intent-popup .dialog__top {
    width: 30.375rem;
    margin: 0 auto;
  }
  .reveal.exit-intent-popup h3 {
    font-size: 1.25rem;
  }
  .ppc-zip-form-dialog__content {
    width: 600px;
    max-width: 75rem;
  }
  .ppc-zip-form-dialog__top h4 {
    font-size: 1rem;
  }
}
.ppc-zip-form-dialog__top .close-button {
  color: #fefefe;
}
.footer__connect--generic li a:focus,
.footer__connect--generic li a:hover,
.footer__legal--generic li a:focus,
.footer__legal--generic li a:hover,
.footer__local--generic li a:focus,
.footer__local--generic li a:hover,
.footer__providers--generic li a:focus,
.footer__providers--generic li a:hover,
.generic-form ._error-inner {
  color: #ff2128;
}
.ppc-zip-form-dialog__bottom {
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fefefe;
}
.divider {
  margin-top: 40px;
  height: 2px;
  background-color: #c5c5c5;
}
.cities-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
@media print, screen and (min-width: 40em) {
  .cities-list {
    grid-template-columns: repeat(4, 1fr);
  }
}
.cities-dropdown {
  margin-top: 3rem;
}
.cities-dropdown-label {
  margin-bottom: 0.5rem;
}
.top-bg__gradient--spectrum {
  background-image: linear-gradient(
    -64deg,
    rgba(0, 48, 87, 0.45) 5%,
    rgba(0, 119, 188, 0.45) 100%
  );
}
.top-bg__gradient--xfinity {
  background-image: linear-gradient(-64deg, rgba(0, 0, 0, 0.45) 5%, #000 100%);
}
.top-bg__image {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.top-bg__image--spectrum {
  background-color: #003057;
}
.top-bg__image--xfinity {
  background-color: #000;
}
.top-bg__image--pos-top-right {
  background-position: top right;
}
.cta-new {
  background-color: #222;
  text-align: center;
}
.cta-new--stuck {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 4px #5b5b5b;
  z-index: 100;
}
.cta-new--spectrum {
  background-color: #0077bc;
}
.cta-new--xfinity {
  background-color: #000;
}
.cta-new__button-area--xfinity,
.reveal-dialog .dialog__top--xfinity {
  background-color: #2b9cd8;
}
.cta-new a:active,
.cta-new a:focus,
.cta-new a:hover {
  color: #f2f2f2;
}
#content .wpb_alert p:last-child,
#content .wpb_text_column :last-child,
#content .wpb_text_column p:last-child,
.cta-new h3,
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature:last-child,
.full-width-hero-card1 .features-bullets .feature:last-child,
.main-wrap.page .search-result:last-child,
.main-wrap.page ul li:last-child,
.main-wrap.post .search-result:last-child,
.main-wrap.post ul li:last-child,
.main-wrap.search .search-result:last-child,
.main-wrap.search ul li:last-child,
.vc_general.vc_cta3 .vc_cta3-content > :last-child,
.vc_general.vc_cta3 .vc_cta3-icons > :last-child,
.vc_message_box > p:last-child,
.vc_separator .vc_icon_element,
.wpb_alert p:last-child,
.wpb_text_column :last-child,
.wpb_text_column p:last-child {
  margin-bottom: 0;
}
.cta-new__button-area {
  height: 60px;
}
.cta-new__button-area--spectrum {
  background-color: #faa41a;
}
.cta-new .cta-new__timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  font-size: 0.75rem;
  line-height: 1.125rem;
}
.cta-new .cta-new__timer--is-hidden {
  display: none;
}
.cta-new .cta-new__timer .timer__left {
  order: 1;
  margin-right: 3px;
  font-weight: 400;
}
.cta-new .cta-new__timer .timer__nums {
  order: 2;
  margin-right: 3px;
  font-weight: 700;
}
.cta-new .cta-new__timer .timer__lang {
  order: 3;
  font-weight: 400;
}
a.skip-to-main {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: 0.1s ease-in;
  z-index: 100;
  background-color: #5050b9;
  padding: 8px;
  color: #f3f3f3;
}
a.skip-to-main:focus {
  transform: none;
}
.callout-area .icon-text {
  display: flex;
  align-items: center;
  justify-content: left;
}
.callout-area .icon-text h3 {
  margin-top: 5px;
  margin-left: 10px;
}
.footer__connect--generic li,
.footer__local--generic li,
.footer__providers--generic li,
.full-width-background
  .full-width-hero-card2
  .contents
  .column-2
  .rating-container
  .rating,
.full-width-hero-card1 .contents .column-2 .rating-container .rating,
.product-card .contents .features-container .feature:not(:last-child),
.product-card .contents .rating-container .rating,
.single-review-hero-card
  .contents
  .features-container
  .feature:not(:last-child) {
  margin-bottom: 10px;
}
.site__footer--generic {
  padding-top: 3.75rem;
  padding-bottom: 1.875rem;
  background-color: #1d253c;
}
.footer__column--generic {
  margin-bottom: 30px;
  border-right: 0;
}
.footer__logo--generic svg {
  width: 176.95px;
  height: 61px;
}
.footer__colheader--generic {
  margin-bottom: 15px;
  text-align: left;
  text-transform: none;
}
.footer__providers--generic {
  margin: 0;
  padding: 0;
  list-style: none;
  columns: 2;
  text-align: left;
}
.footer__connect--generic {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1;
}
.footer__social--generic {
  justify-content: left;
  margin-top: 15px;
  line-height: 1;
}
.footer__social--generic li {
  display: inline-block;
  position: relative;
  width: auto;
  margin-right: 10px;
}
.footer__sep--generic {
  margin: 0 0 10px;
  border-bottom: 1px solid #ff2128;
}
.footer__copyright--generic {
  margin-bottom: 10px;
  font-size: 14px;
}
.footer__legal--generic {
  line-height: 1;
}
.footer__legal--generic li {
  border-right: 1px solid rgba(243, 243, 243, 0.3);
  line-height: 16px;
}
.footer__legal--generic li:last-child {
  border: 0;
}
.footer__legal--generic li a {
  display: block;
  padding-right: 15px;
  color: #fff;
  font-size: 14px;
}
.compare-card {
  max-width: 300px;
  margin: 0 auto 15px;
  text-align: left;
}
.compare-card .card-bottom {
  padding: 15px;
  border-right: 2px solid #c5c5c5;
  border-left: 2px solid #c5c5c5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.searchform {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.searchform input {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
  margin-bottom: 0;
  padding-right: 30px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  font-size: 0.875rem;
  font-style: italic;
  box-shadow: none;
}
.searchform input:focus {
  border: 1px solid #ebebeb;
  box-shadow: inset 1px 2px 5px -6px;
}
.searchform button {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 0;
  margin-left: -40px;
  z-index: 1;
  cursor: pointer;
}
.searchform button img {
  width: 22px;
  height: 22px;
}
.reveal {
  height: auto !important;
  min-height: auto !important;
}
.reveal-dialog {
  padding: 0 15px;
  border: 0;
  outline: 0;
  overflow-y: auto;
}
.no-padding-left-right,
.no-padding-right {
  padding-right: 0 !important;
}
.post-meta__avatar,
.stars {
  overflow: hidden;
}
.reveal-dialog__overlay {
  background-color: rgba(10, 10, 10, 0.87);
  overflow-y: hidden;
}
.reveal-dialog .dialog__heading {
  margin: 0 auto 15px;
  color: #fff;
  text-align: center;
}
.reveal-dialog .dialog__top h4,
.reveal-dialog .dialog__top p {
  margin-bottom: 0;
  margin-left: 15px;
  color: #fff;
  font-size: 0.875rem;
  line-height: 54px;
}
@media print, screen and (min-width: 40em) {
  .cta-new {
    padding-top: 22px;
    padding-bottom: 22px;
    text-align: left;
  }
  .cta-new .cta-new__timer {
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: center;
    justify-content: flex-end;
    height: 34px;
    margin-bottom: -9px;
    font-weight: 700;
    line-height: 1rem;
  }
  .cta-new .cta-new__timer--spectrum,
  .cta-new .cta-new__timer--xfinity {
    align-content: flex-start;
  }
  .cta-new .cta-new__timer .timer__left {
    flex: 1 1 12px;
    margin-right: 0;
    font-weight: 700;
  }
  .cta-new .cta-new__timer .timer__nums {
    flex: 2 1 12px;
    order: 3;
    margin-right: 0;
    margin-left: 15px;
    font-size: 24px;
    line-height: 24px;
  }
  .cta-new .cta-new__timer .timer__lang {
    flex: 1 1 18px;
    align-self: flex-end;
    order: 2;
  }
  .callout-area p,
  .footer__copyright--generic {
    margin-bottom: 0;
  }
  .site__footer--generic {
    padding-top: 3.75rem;
    padding-bottom: 1.875rem;
  }
  .footer__column--generic,
  .footer__logo--generic {
    margin-bottom: 15px;
  }
  .footer__colheader--generic {
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 700;
    text-align: left;
  }
  .footer__sep--generic {
    margin: 15px 0 10px;
  }
  .reveal-dialog .dialog__top h4,
  .reveal-dialog .dialog__top p {
    font-size: 1rem;
  }
  .footer__email-form {
    padding-left: 20px;
  }
}
.main-wrap.page .hero h1,
.main-wrap.page .hero h2,
.main-wrap.page .hero h3,
.main-wrap.page .hero h4,
.main-wrap.page .hero h5,
.main-wrap.post .hero h1,
.main-wrap.post .hero h2,
.main-wrap.post .hero h3,
.main-wrap.post .hero h4,
.main-wrap.post .hero h5,
.main-wrap.search .hero h1,
.main-wrap.search .hero h2,
.main-wrap.search .hero h3,
.main-wrap.search .hero h4,
.main-wrap.search .hero h5,
.provider-cards__card--is-bf
  .provider-cards__card-flex-area
  .provider-cards__card-features-table-cell,
.provider-cards__card--is-bf
  .provider-cards__card-rating
  .provider-cards__card-rating-details,
.reveal-dialog .dialog__top .close-button,
.text-white,
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5 {
  color: #fff;
}
.reveal-dialog .dialog__bottom {
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}
.no-padding-left,
.no-padding-left-right {
  padding-left: 0 !important;
}
.footer__email-form__disclaimer,
.vc-email-form__disclaimer {
  color: #151515;
  font-size: 0.75rem;
  text-align: left;
}
.footer__email-form__disclaimer a {
  font-size: 0.75rem;
}
.footer__email-form__inline-form,
.medium-down-text-center,
.separator_align_center,
.vc-email-form__inline-form,
.vc_general.vc_cta3.vc_cta3-icons-bottom .vc_cta3-icons,
.vc_general.vc_cta3.vc_cta3-icons-top .vc_cta3-icons,
.vc_pie_chart,
.wpb_single_image.vc_align_center {
  text-align: center;
}
.footer__email-form label {
  display: none;
}
.footer__email-form ._form-content,
.vc-email-subscribe ._form-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
}
@media print, screen and (min-width: 40em) {
  .footer__email-form__disclaimer {
    text-align: right;
  }
  .footer__email-form__inline-form {
    color: #ff2128;
    font-weight: 700;
    text-align: right;
  }
  .footer__email-form ._form-content {
    justify-content: flex-end;
  }
}
.footer__email-form ._form-content ._field-wrapper,
.vc-email-form ._form-content ._field-wrapper,
.vc-email-subscribe ._form-content ._field-wrapper,
.vc_general.vc_cta3.vc_cta3-icons-on-border {
  position: relative;
}
.footer__email-form ._form-content ._field-wrapper ._error._below,
.vc-email-subscribe ._form-content ._field-wrapper ._error._below {
  position: absolute;
  top: -25%;
  left: 0;
  color: #cc4b37;
  font-size: 0.75rem;
  font-weight: 700;
}
.footer__email-form p {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.footer__email-form input {
  height: 60px;
  margin-bottom: 20px;
  padding: 0.5rem;
  border-radius: 0;
}
.footer__email-form input.field-email,
.vc-email-form input.field-email {
  width: 200px;
  margin-right: 15px;
}
.footer__email-form input.field-zip,
.vc-email-subscribe input.field-zip {
  width: 115px;
  margin-right: 15px;
}
.footer__email-form button {
  width: 110px;
  height: 60px;
  padding: 1rem;
  border-radius: 0;
  background-color: #ff2128;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 0.8;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}
._form-thank-you,
.footer ._form-thank-you {
  color: #ff2128;
  text-align: right;
}
.header-logo--buckeye-broadband {
  width: 7.9375rem;
  height: 1.5625rem;
  background-image: url("../images/brands/logo-buckeye-broadband.svg");
}
.footer__logo--buckeye-broadband svg {
  width: 205.71px;
  height: 40px;
}
.footer__colheader--buckeye-broadband,
.package-card--buckeye-broadband .package-features__visibility-toggle {
  color: #11407a;
}
.post-info a,
.post-info__share-block {
  color: darker(#5050b9, 30%);
}
.package-card--buckeye-broadband .package-features__button {
  background-color: #11407a;
  color: #f3f3f3;
  text-transform: uppercase;
}
.package-card--buckeye-broadband .package-features__button:hover {
  background-color: #081d37;
}
.package-card--spectrum .brand-icons__list__item.available svg,
.package-card--spectrum .package-features__visibility-toggle svg {
  fill: #0077bc;
}
.package-card--spectrum .package-features__list-item__link {
  color: #0077bc;
}
.package-card--spectrum .package-features__list-item__link:active,
.package-card--spectrum .package-features__list-item__link:focus,
.package-card--spectrum .package-features__list-item__link:hover {
  color: #0067a3;
}
.package-card--spectrum .package-features__button {
  background-color: #faa41a;
  color: #f3f3f3;
}
.package-card--spectrum .package-features__button:hover {
  background-color: #f59905;
}
.package-card--spectrum .package-features__visibility-toggle {
  color: #0077bc;
  font-weight: 700;
  text-transform: none;
}
.package-card--xfinity .brand-icons__list__item.available svg {
  fill: #d31245;
}
.package-card--xfinity .package-features__list-item__link {
  color: #2b9cd8;
}
.package-card--xfinity .package-features__list-item__link:active,
.package-card--xfinity .package-features__list-item__link:focus,
.package-card--xfinity .package-features__list-item__link:hover {
  color: #248dc5;
}
.package-card--xfinity .package-features__button {
  background-color: #2b9cd8;
  color: #f3f3f3;
}
.package-card--xfinity .package-features__button:hover {
  background-color: #1c6f9a;
}
.package-card--xfinity .package-features__visibility-toggle {
  color: #2b9cd8;
}
.package-card--xfinity .package-features__visibility-toggle svg {
  fill: #2b9cd8;
}
.provider-cards__card--is-bf .provider-cards__card-bottom {
  border-top: 2px solid #fff;
  background-color: #000;
}
.provider-cards__card--is-bf
  .provider-cards__card-bottom
  .columns:nth-child(3) {
  border-top: 0;
}
.provider-cards__card--is-bf
  .provider-cards__card-flex-area
  .provider-cards__card-features-table-cell
  h5,
.provider-cards__card--is-bf
  .provider-cards__card-flex-area
  .provider-cards__card-features-table-cell
  p {
  font-size: 0.75rem;
  line-height: 1.2;
}
.provider-cards__card--is-bf
  .provider-cards__card-flex-area
  .provider-cards__card-features-table-cell
  span {
  color: #fff;
  text-decoration: underline;
}
.main-wrap.page,
.main-wrap.post,
.main-wrap.search {
  background-color: #fff;
  color: #151515;
}
.main-wrap.page .search-result,
.main-wrap.post .search-result,
.main-wrap.search .search-result {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.main-wrap.page ul,
.main-wrap.post ul,
.main-wrap.search ul {
  line-height: 2em;
}
.main-wrap.page blockquote,
.main-wrap.post blockquote,
.main-wrap.search blockquote {
  margin: 20px 0 30px;
  padding-top: 0;
  padding-left: 20px;
  border-left: 5px solid #000;
  color: #ff2128;
  font-size: 15px;
  font-weight: 700;
}
.main-wrap.page blockquote span,
.main-wrap.post blockquote span,
.main-wrap.search blockquote span {
  color: #ff2128;
  font-weight: 600;
}
.main-wrap.page .hero--generic h3,
.main-wrap.post .hero--generic h3,
.main-wrap.search .hero--generic h3 {
  font-size: 1.125rem;
  line-height: 24px;
}
.main-wrap.page .hero--has-components,
.main-wrap.post .hero--has-components,
.main-wrap.search .hero--has-components {
  padding-bottom: 0;
  margin-bottom: 0;
}
.main-wrap.page .hero-components,
.main-wrap.post .hero-components,
.main-wrap.search .hero-components {
  position: relative;
  width: 100%;
  z-index: 0;
}
.main-wrap.page .hero-components.best-of-hero-card-container,
.main-wrap.post .hero-components.best-of-hero-card-container,
.main-wrap.search .hero-components.best-of-hero-card-container {
  margin-top: 15px;
  margin-bottom: -20px;
  z-index: 1;
}
.main-wrap.page
  .hero-components.best-of-hero-card-container
  .full-width-hero
  .column,
.main-wrap.page
  .hero-components.best-of-hero-card-container
  .full-width-hero
  .columns,
.main-wrap.post
  .hero-components.best-of-hero-card-container
  .full-width-hero
  .column,
.main-wrap.post
  .hero-components.best-of-hero-card-container
  .full-width-hero
  .columns,
.main-wrap.search
  .hero-components.best-of-hero-card-container
  .full-width-hero
  .column,
.main-wrap.search
  .hero-components.best-of-hero-card-container
  .full-width-hero
  .columns {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 40em) {
  .header-logo--buckeye-broadband {
    width: 10.8125rem;
    height: 2.125rem;
  }
  .main-wrap.page .search-result,
  .main-wrap.post .search-result,
  .main-wrap.search .search-result {
    flex-direction: row;
    margin-bottom: 60px;
  }
  .main-wrap.page
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .column,
  .main-wrap.page
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .columns,
  .main-wrap.post
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .column,
  .main-wrap.post
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .columns,
  .main-wrap.search
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .column,
  .main-wrap.search
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .columns {
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.post-thumbnail-header img {
  display: block;
  margin: 0 auto 30px;
}
.post-info {
  margin: 0 0 30px;
  text-align: left;
}
.post-info__author-image {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.main-wrap .post-info,
.main-wrap .post-meta {
  line-height: 1.5em;
}
.post-info__divider {
  display: inline-block;
  margin: 0 5px;
}
.post-info__share-block {
  margin-bottom: 0;
}
.post-info__share-link {
  display: inline-flex;
  position: relative;
  margin: 0 0 0 5px;
  vertical-align: middle;
  align-items: center;
  width: 16px;
  height: 16px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.post-info__share-link:hover {
  opacity: 0.7;
}
.post-info__edited {
  padding-left: 0.5rem;
  color: #5b5b5b;
}
.post-info__edited a {
  color: #5b5b5b;
  text-decoration: none;
}
.post-info__author-info {
  display: inline-block;
  margin: 0 0 0 12px;
  text-align: left;
  font-size: 0.75rem;
}
.post-info__author-info strong {
  width: 100%;
}
.post-info__author-info .fa-facebook {
  width: 14px;
  height: 14px;
  background-color: #000;
  padding: 2px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}
.related-thumb {
  width: auto;
  height: 130px;
  text-align: center;
}
.related-thumb img {
  max-height: 130px;
}
.margin-modifier {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}
.bg-shadow {
  border: 1px solid #f3f3f3;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(221, 221, 221, 0.5);
}
:root:root .no-margins {
  margin: 0;
}
.gen-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}
.large-padding,
.medium-padding {
  padding-top: 15px;
  padding-bottom: 15px;
}
.large-padding-full,
.medium-padding-full,
.vc_cta3-container .vc_general.vc_cta3 {
  padding: 15px;
}
.small-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}
.small-padding-full {
  padding: 10px;
}
.grid-x {
  display: flex;
  flex-flow: row wrap;
}
.hidden {
  display: none;
  visibility: hidden;
}
.font-small {
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.stars label,
[class*=" icon-"],
[class^="icon-"] {
  font-family: icomoon;
  line-height: 1;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  word-wrap: normal;
}
.nav-links {
  text-align: center;
  margin-top: 30px;
}
.nav-links .page-numbers {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 0;
  background-color: #ff2128;
  color: #fff;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}
.hero-components .vc-table-scrolling__cell ul br,
.stars input[type="radio"],
.vc-table-scrolling__cell ul br {
  display: none;
}
.nav-links .page-numbers:active,
.nav-links .page-numbers:focus,
.nav-links .page-numbers:hover {
  background-color: #a52b19;
}
.nav-links .page-numbers.current {
  background-color: #a52b19;
  color: #fff;
}
.nav-links .page-numbers.next {
  margin-left: 5px;
}
.nav-links .page-numbers.prev {
  margin-right: 5px;
}
.one-fourth,
.one-half,
.one-third,
.three-fourth,
.two-third {
  position: relative;
  margin-right: 4%;
  float: left;
}
.one-fourth.col-last,
.one-half.col-last,
.one-third.col-last,
.three-fourth.col-last,
.two-third.col-last {
  margin-right: 0;
  clear: right;
}
.one-half {
  width: 48%;
}
.one-third {
  width: 30.66%;
}
.two-third {
  width: 65.32%;
}
.one-fourth {
  width: 22%;
}
.three-fourth {
  width: 74%;
}
img.alignleft {
  display: inline;
  margin-right: 15px;
}
img.alignright {
  display: inline;
  margin-left: 15px;
}
.post-meta {
  display: table;
  margin-bottom: 0;
}
.post-meta__author,
.post-meta__avatar {
  display: table-cell;
  vertical-align: middle;
}
.post-meta__avatar .avatar {
  border-radius: 50%;
}
.post-meta__author {
  padding-left: 10px;
}
.author-meta__social {
  display: inline-block;
  padding: 0 5px;
  font-size: 1.25rem;
}
.single-review-hero-card-container > .row:first-child {
  margin-top: 30px;
  margin-bottom: -20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px #c5c5c5;
  z-index: 1;
}
._form label {
  display: inline-block !important;
}
@font-face {
  font-family: icomoon;
  font-display: auto;
  src: url("../fonts/blog/icomoon/icomoon.eot");
  src: url("../fonts/blog/icomoon/icomoon.eot#iefix")
      format("embedded-opentype"),
    url("../fonts/blog/icomoon/icomoon.woff") format("woff"),
    url("../fonts/blog/icomoon/icomoon.ttf") format("truetype"),
    url("../fonts/blog/icomoon/icomoon.svg#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
}
[class*=" icon-"],
[class^="icon-"] {
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
}
.icon-star::before {
  content: "";
}
.icon-check::before {
  content: "";
}
.icon-close::before,
.icon-remove::before,
.icon-times::before {
  content: "";
}
.icon-chevron-left::before {
  content: "";
}
.icon-chevron-right::before {
  content: "";
}
.icon-times-circle::before {
  content: "";
}
.icon-check-circle::before {
  content: "";
}
.icon-info-circle::before {
  content: "";
}
.icon-chevron-up::before {
  content: "";
}
.icon-chevron-down::before {
  content: "";
}
.icon-star-half::before {
  content: "";
}
.icon-star-half-empty::before,
.icon-star-half-full::before,
.icon-star-half-o::before {
  content: "";
}
.featured-post {
  position: relative;
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (min-width: 1025px) {
  .featured-post {
    height: 400px;
  }
}
.featured-post__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 40px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: left;
}
.featured-post__info a,
.featured-slider .slick-dots li.slick-active button::before {
  color: #fff;
}
.featured-post__info a:active,
.featured-post__info a:focus,
.featured-post__info a:hover {
  color: #f2f2f2;
}
.featured-post__info h3 a {
  text-decoration: none;
}
.featured-slider .slick-next,
.featured-slider .slick-prev {
  width: 44px;
  height: 44px;
  opacity: 0;
  display: none;
}
.featured-slider .slick-next::before,
.featured-slider .slick-prev::before {
  font-size: 44px;
}
.featured-slider .slick-dots {
  bottom: -10px;
  margin-left: 0;
}
.featured-slider .slick-dots li button::before {
  color: #fff;
  font-size: 40px;
}
.stars {
  display: inline-block;
  min-width: 144px;
  height: 37px;
  margin: 0;
  white-space: nowrap;
}
.stars input[type="radio"] {
  margin: 0;
}
.stars input[type="radio"]:disabled {
  cursor: default;
}
.stars input[type="radio"]:disabled + label[for] {
  cursor: default;
  margin: 0;
}
.stars label {
  align-self: center;
  margin: 0;
  float: right;
  color: #5b5b5b;
  font-size: 1.5rem;
}
.vc-email-form button,
.vc-email-subscribe button,
.zip-finder__button button {
  line-height: 0.8;
  text-transform: uppercase;
}
.stars label::before {
  margin: 0 0.1875rem;
  content: "";
}
.stars label.half::before {
  position: absolute;
  content: "";
}
.stars.small label::before {
  font-size: 1.375rem;
}
.stars.medium {
  min-width: 219px;
  height: 60px;
}
.stars.large {
  min-width: 265px;
  height: 75px;
}
.stars.large label::before {
  font-size: 3.125rem;
}
.stars.orange input[type="radio"]:checked ~ label,
.stars.orange.dynamic:not(:checked) > label:hover,
.stars.orange.dynamic:not(:checked) > label:hover ~ label {
  color: #ffd800;
}
.stars.orange.dynamic input:checked + label:hover,
.stars.orange.dynamic input:checked ~ label:hover,
.stars.orange.dynamic input:checked ~ label:hover ~ label,
.stars.orange.dynamic label:hover ~ input:checked ~ label {
  color: #ffe033;
}
.stars.blue input[type="radio"]:checked ~ label,
.stars.blue.dynamic:not(:checked) > label:hover,
.stars.blue.dynamic:not(:checked) > label:hover ~ label {
  color: #767676;
}
.stars.blue.dynamic input:checked + label:hover,
.stars.blue.dynamic input:checked ~ label:hover,
.stars.blue.dynamic input:checked ~ label:hover ~ label,
.stars.blue.dynamic label:hover ~ input:checked ~ label {
  color: #909090;
}
.stars.charcoal.dynamic input:checked + label:hover,
.stars.charcoal.dynamic input:checked ~ label:hover,
.stars.charcoal.dynamic input:checked ~ label:hover ~ label,
.stars.charcoal.dynamic label:hover ~ input:checked ~ label {
  color: #7474c8;
}
.scStarsContainer {
  display: inline-flex;
  vertical-align: bottom;
}
.scStarsContainer #text {
  font-size: 50px;
}
.product-card .rating-star-empty,
.single-review-hero-card .rating-star-empty {
  width: 18px;
  background-image: url("../images/components/rating-stars/rating-star-empty.svg");
  background-repeat: no-repeat;
}
.product-card .rating-star-full,
.single-review-hero-card .rating-star-full {
  width: 18px;
  min-height: 18px;
  background-image: url("../images/components/rating-stars/rating-star-full.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
}
.best-of-hero-card .rating-star-empty {
  width: 39px;
  background-image: url("../images/components/rating-stars/rating-star-empty.svg");
  background-repeat: no-repeat;
}
.best-of-hero-card .rating-star-full {
  width: 39px;
  min-height: 37px;
  background-image: url("../images/components/rating-stars/rating-star-full.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
}
.full-width-hero-card1 {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 60px;
  border: 2px solid #235457;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.7);
  table-layout: fixed;
  z-index: 3;
}
.full-width-hero-card1 .image img {
  display: block;
  width: 100%;
  margin: 0 auto;
  overflow: visible;
}
.full-width-background .full-width-hero-card2 .dish-bar,
.full-width-hero-card1 .dish-bar {
  display: block;
  margin: 0 auto;
  padding-bottom: 20px;
}
.full-width-background .full-width-hero-card2 .customer-ratings,
.full-width-hero-card1 .customer-ratings {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 500;
}
.full-width-background .full-width-hero-card2 .contents,
.full-width-hero-card1 .contents {
  display: inline-flex;
  width: calc(100% - 30px);
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 1;
}
.full-width-hero-card1 .contents .column,
.full-width-hero-card1 .contents .columns {
  display: table-cell;
  padding: 20px 15px;
  vertical-align: middle;
}
.full-width-background .full-width-hero-card2 .contents .column-1,
.full-width-hero-card1 .contents .column-1 {
  width: 25%;
  margin: 0 auto;
}
.full-width-background .full-width-hero-card2 .contents .column-2,
.full-width-hero-card1 .contents .column-2 {
  width: 25%;
  height: 100%;
  padding: 0 0 20px;
  text-align: center;
}
.full-width-background
  .full-width-hero-card2
  .contents
  .column-2
  .rating-container,
.full-width-background .full-width-hero-card2 .snippet,
.full-width-hero-card1 .contents .column-2 .rating-container,
.full-width-hero-card1 .snippet {
  color: #6e7176;
  font-size: 16px;
}
.full-width-background
  .full-width-hero-card2
  .contents
  .column-2
  .rating-container
  .rating
  .rating-label,
.full-width-hero-card1
  .contents
  .column-2
  .rating-container
  .rating
  .rating-label {
  margin-right: 15px;
  color: #2e3645;
  font-size: 20px;
  font-weight: 700;
}
.full-width-background
  .full-width-hero-card2
  .contents
  .column-2
  .rating-container
  .rating
  .rating-score,
.full-width-hero-card1
  .contents
  .column-2
  .rating-container
  .rating
  .rating-score {
  color: #3f9ba1;
  font-size: 45px;
  font-weight: 400;
  text-align: center;
}
.full-width-hero-card1 .contents .column-3 {
  width: 25%;
  padding-bottom: 0;
  padding-left: 40px;
}
.full-width-background .full-width-hero-card2 .contents .column-4 .ctas,
.full-width-hero-card1 .contents .column-4 .ctas {
  display: table;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}
.full-width-background
  .full-width-hero-card2
  .contents
  .column-4
  .ctas
  .primary,
.full-width-hero-card1 .contents .column-4 .ctas .primary {
  display: block;
  padding: 0 5px;
  border-radius: 5px;
  background-color: #e24220;
  color: #fff;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  width: 130px;
  height: 50px;
  font-size: 18px;
  font-weight: 400;
}
.full-width-background
  .full-width-hero-card2
  .contents
  .column-4
  .ctas
  .primary:hover,
.full-width-hero-card1 .contents .column-4 .ctas .primary:hover {
  background-color: #ff3c22;
}
.full-width-background
  .full-width-hero-card2
  .contents
  .column-4
  .ctas
  .primary:active,
.full-width-hero-card1 .contents .column-4 .ctas .primary:active {
  background-color: #ff7945;
}
.full-width-background
  .full-width-hero-card2
  .contents
  .column-4
  .ctas
  .secondary,
.full-width-hero-card1 .contents .column-4 .ctas .secondary {
  display: block;
  font-size: 16px;
  text-align: center;
}
.full-width-hero-card1 .features-bullets {
  font-size: 15px;
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature-headline,
.full-width-hero-card1 .features-bullets .feature-headline {
  margin-bottom: 8px;
  margin-left: 8px;
  color: #586a79;
  font-weight: 400;
}
.full-width-background .full-width-hero-card2 .features-bullets .feature,
.full-width-hero-card1 .features-bullets .feature {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.full-width-hero-card1 .features-bullets .feature .feature-label,
.full-width-hero-card1 .features-bullets .feature .feature-value {
  width: 50%;
  font-size: 33px;
  text-align: left;
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-label,
.full-width-hero-card1 .features-bullets .feature .feature-label {
  width: 10%;
  color: #2e3645;
  font-size: 60px;
  font-weight: 900;
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-value,
.full-width-hero-card1 .features-bullets .feature .feature-value {
  color: #586a79;
  font-weight: 400;
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-bullet,
.full-width-hero-card1 .features-bullets .feature .feature-bullet {
  width: 34px;
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-bullet
  .icon-thin-check,
.full-width-hero-card1
  .features-bullets
  .feature
  .feature-bullet
  .icon-thin-check {
  color: #85c950;
  font-size: 0.85em;
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-bullet
  .icon-thin-x,
.full-width-hero-card1 .features-bullets .feature .feature-bullet .icon-thin-x {
  color: #ff3c22;
  font-size: 0.8em;
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-text,
.full-width-hero-card1 .features-bullets .feature .feature-text {
  width: calc(100% - 34px);
  color: #586a79;
  font-size: 13px;
  font-weight: 500;
}
.full-width-background .full-width-hero-card2 .label-value-features,
.full-width-hero-card1 .label-value-features {
  width: 130%;
}
.full-width-background .full-width-hero-card2 .label-value-features .feature,
.full-width-hero-card1 .label-value-features .feature {
  display: flex;
  width: 100%;
}
.full-width-hero-card1 .label-value-features .feature .feature-label {
  width: 50%;
  color: #586a79;
  font-size: 15px;
  text-align: left;
}
.full-width-hero-card1 .label-value-features .feature .feature-value {
  width: 50%;
  padding-left: 10px;
  color: #586a79;
  font-size: 15px;
  text-align: left;
}
.full-width-background .full-width-hero-card2 .snippet strong,
.full-width-hero-card1 .snippet strong {
  color: #2e3645;
}
.full-width-background .full-width-hero-card2 .snippet p,
.full-width-hero-card1 .snippet p {
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 22px;
}
@media screen and (min-width: 640px) and (max-width: 960px) {
  .full-width-hero-card1 .contents .columns {
    padding: 0;
  }
  .full-width-hero-card1 .contents .column-1 {
    width: 25%;
  }
  .full-width-hero-card1 .contents .column-2 {
    padding-right: 10px;
    text-align: center;
  }
  .full-width-hero-card1 .contents .column-2 .rating {
    padding-top: 25px;
    text-align: center;
  }
  .full-width-hero-card1 .contents .column-2 .rating .rating-score {
    font-size: 35px;
  }
  .full-width-hero-card1 .contents .column-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 25%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
  }
  .full-width-hero-card1 .contents .column-3 .features-bullets .feature {
    margin-bottom: 5px;
  }
  .full-width-hero-card1 .contents .column-3 .features-bullets .feature-text {
    font-size: 13px;
    line-height: normal;
  }
  .full-width-hero-card1 .contents .column-4 {
    margin: auto;
  }
  .full-width-hero-card1 .contents .column-4 .ctas {
    display: flex;
    margin: 0 auto;
  }
}
@media screen and (min-width: 960px) {
  .full-width-hero-card1 .contents .columns {
    width: 100%;
    padding: 0;
  }
  .full-width-hero-card1 .contents .column-1 {
    margin-left: 20px;
  }
  .full-width-hero-card1 .contents .column-2 {
    width: 25%;
    padding-top: 20px;
    text-align: center;
  }
  .full-width-hero-card1 .contents .column-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .full-width-hero-card1 .contents .column-3 .features-bullets .feature {
    margin-bottom: 5px;
  }
  .full-width-hero-card1 .contents .column-3 .features-bullets .feature-text {
    font-size: 13px;
    line-height: 20px;
  }
  .full-width-hero-card1 .contents .column-3 .features-bullets .icon-minus {
    margin-bottom: 7px;
  }
  .full-width-hero-card1 .contents .column-4 {
    display: block;
    margin: auto;
  }
  .full-width-hero-card1 .contents .column-4 .ctas {
    display: flex;
    margin: 0 auto;
    padding: 0;
  }
}
.full-width-background {
  width: 100%;
  padding: 4.5%;
  background-color: #215357;
}
.full-width-background .full-width-hero-card2 {
  display: flex;
  position: relative;
  width: 100%;
  border: 1px solid #235457;
  border-radius: 0 5px 5px 0;
  background-color: #fff;
  box-shadow: 1px 2px 9px rgba(0, 0, 0, 0.7);
  table-layout: fixed;
  z-index: 3;
}
.full-width-background .full-width-hero-card2 .image img {
  display: block;
  width: 200px;
  margin: 0 auto;
  overflow: visible;
}
.full-width-background .full-width-hero-card2 .contents .column,
.full-width-background .full-width-hero-card2 .contents .columns {
  display: table-cell;
  padding: 15px 10px;
  vertical-align: middle;
}
@media screen and (min-width: 640px) {
  .full-width-background .full-width-hero-card2,
  .full-width-hero-card1 {
    height: 200px;
    margin-bottom: 0;
  }
  .full-width-background .full-width-hero-card2 .contents,
  .full-width-hero-card1 .contents {
    width: 100%;
  }
  .full-width-background .full-width-hero-card2 .contents .column-2,
  .full-width-hero-card1 .contents .column-2 {
    padding-right: 10px;
    border-right: 1px solid #d8d8d8;
    text-align: center;
  }
  .full-width-hero-card1 .contents .column .column-3,
  .full-width-hero-card1 .contents .columns .column-3 {
    max-width: 25%;
    padding-bottom: 0;
  }
  .full-width-background .full-width-hero-card2 .contents .column-3 {
    max-width: 35%;
  }
}
@media screen and (max-width: 640px) {
  .full-width-hero-card1 .contents .column-1 {
    padding-bottom: 30px;
    width: 100%;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-2
    .rating-container
    .rating,
  .full-width-hero-card1 .contents .column-2 .rating-container .rating {
    margin-right: 15%;
    margin-left: 15%;
    padding-top: 20px;
    border-top: 1px solid #235457;
  }
  .full-width-hero-card1 {
    display: block;
  }
  .full-width-hero-card1 .handle {
    display: block;
    width: 100%;
    min-height: 20px;
    border-radius: 5px 5px 0 0;
    box-shadow: none;
  }
  .full-width-hero-card1 .contents {
    display: block;
    width: 100%;
    padding: 0 22px;
  }
  .full-width-hero-card1 .contents .column,
  .full-width-hero-card1 .contents .columns {
    display: block;
    padding: 10px 15px;
  }
  .full-width-hero-card1 .contents .column-2 {
    width: 100%;
    margin-top: 20px;
    border-right: 0;
    text-align: center;
  }
  .full-width-hero-card1 .contents .column-3 {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .full-width-hero-card1 .contents .column-3 .icon-minus {
    margin-bottom: 5px;
  }
  .full-width-hero-card1 .contents .column-3 .label-value-features {
    padding-top: 20px;
  }
  .full-width-hero-card1 .contents .column-4 {
    width: calc(100% + 44px);
    margin-left: -22px;
    padding-top: 15px;
  }
  .full-width-hero-card1 .contents .features-bullets,
  .full-width-hero-card1 .contents .features-bullets .feature .feature-label,
  .full-width-hero-card1 .contents .features-bullets .feature .feature-value,
  .full-width-hero-card1 .contents .snippet,
  .full-width-hero-card1 .contents .snippet p,
  .full-width-hero-card1 .label-value-features .feature .feature-label,
  .full-width-hero-card1 .label-value-features .feature .feature-value {
    font-size: 14px;
  }
  .full-width-hero-card1 .contents .rating-container {
    font-size: 14px;
    text-align: center;
  }
  .full-width-hero-card1 .contents .rating-container .rating .rating-label {
    margin-right: 10px;
    font-size: 16px;
  }
  .full-width-hero-card1 .contents .features-bullets .feature {
    display: flex;
    margin-bottom: 10px;
  }
  .full-width-hero-card1 .contents .features-bullets .feature:last-child {
    margin-bottom: 0;
  }
  .full-width-hero-card1 .label-value-features {
    width: 100%;
    margin-top: -20px;
    padding-top: 0;
  }
  .full-width-background .full-width-hero-card2 .contents .column-1 {
    padding-bottom: 30px;
  }
}
.full-width-background .full-width-hero-card2 .contents .column-3 {
  width: 30%;
  padding-bottom: 0;
  padding-left: 40px;
}
@media screen and (min-width: 640px) {
  .full-width-background .full-width-hero-card2 .contents .column .column-3,
  .full-width-background .full-width-hero-card2 .contents .columns .column-3 {
    max-width: 25%;
    padding-bottom: 0;
  }
  .full-width-background .full-width-hero-card2 .features-bullets {
    max-width: 99%;
  }
}
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-label,
.full-width-background
  .full-width-hero-card2
  .features-bullets
  .feature
  .feature-value {
  width: 25%;
  font-size: 33px;
  text-align: left;
}
.full-width-background
  .full-width-hero-card2
  .label-value-features
  .feature
  .feature-label {
  width: 50%;
  color: #586a79;
  font-size: 16px;
  text-align: right;
}
.full-width-background
  .full-width-hero-card2
  .label-value-features
  .feature
  .feature-value {
  width: 50%;
  padding-left: 10px;
  color: #586a79;
  font-size: 16px;
  text-align: left;
}
@media screen and (min-width: 640px) and (max-width: 960px) {
  .full-width-background .full-width-hero-card2 .contents .columns {
    padding: 0;
  }
  .full-width-background .full-width-hero-card2 .contents .column-1 {
    width: 25%;
  }
  .full-width-background .full-width-hero-card2 .contents .column-2 {
    width: 25%;
    padding-right: 10px;
    text-align: center;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-2
    .rating-container
    .rating {
    padding-top: 25px;
    text-align: center;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-2
    .rating-container
    .rating
    .rating-score {
    font-size: 35px;
  }
  .full-width-background .full-width-hero-card2 .contents .column-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 35%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-3
    .features-bullets
    .feature {
    margin-bottom: 5px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-3
    .features-bullets
    .feature-text {
    font-size: 15px;
    line-height: normal;
  }
  .full-width-background .full-width-hero-card2 .contents .column-4 {
    margin: auto;
  }
  .full-width-background .full-width-hero-card2 .contents .column-4 .ctas {
    display: flex;
    margin: 0 auto;
  }
}
@media screen and (min-width: 960px) {
  .full-width-background .full-width-hero-card2 .contents .columns {
    width: 100%;
    padding: 0;
  }
  .full-width-background .full-width-hero-card2 .contents .column-1 {
    margin-left: 20px;
  }
  .full-width-background .full-width-hero-card2 .contents .column-2 {
    width: 30%;
    padding-top: 20px;
    text-align: center;
  }
  .full-width-background .full-width-hero-card2 .contents .column-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-3
    .features-bullets
    .feature {
    margin-bottom: 5px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-3
    .features-bullets
    .feature-text {
    font-size: 15px;
    line-height: 20px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-3
    .features-bullets
    .icon-minus {
    margin-bottom: 7px;
  }
  .full-width-background .full-width-hero-card2 .contents .column-4 {
    display: block;
    margin: auto;
  }
  .full-width-background .full-width-hero-card2 .contents .column-4 .ctas {
    display: flex;
    margin: 0 auto;
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  .full-width-background .full-width-hero-card2 {
    display: block;
  }
  .full-width-background .full-width-hero-card2 .handle {
    display: block;
    width: 100%;
    min-height: 20px;
    border-radius: 5px 5px 0 0;
    box-shadow: none;
  }
  .full-width-background .full-width-hero-card2 .contents {
    display: block;
    width: 100%;
    padding: 0 22px;
  }
  .full-width-background .full-width-hero-card2 .contents .column,
  .full-width-background .full-width-hero-card2 .contents .columns {
    display: block;
    padding: 10px 15px;
  }
  .full-width-background .full-width-hero-card2 .contents .column-1 {
    width: 100%;
  }
  .full-width-background .full-width-hero-card2 .contents .column-2 {
    width: 100%;
    border-right: 0;
    text-align: center;
  }
  .full-width-background .full-width-hero-card2 .contents .column-3 {
    width: 100%;
    margin-top: 0;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-3
    .icon-minus {
    margin-bottom: 5px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .column-3
    .label-value-features {
    padding-top: 20px;
  }
  .full-width-background .full-width-hero-card2 .contents .column-4 {
    width: calc(100% + 44px);
    margin-left: -22px;
    padding-top: 15px;
  }
  .full-width-background .full-width-hero-card2 .contents .features-bullets,
  .full-width-background
    .full-width-hero-card2
    .contents
    .features-bullets
    .feature
    .feature-label,
  .full-width-background
    .full-width-hero-card2
    .contents
    .features-bullets
    .feature
    .feature-value,
  .full-width-background .full-width-hero-card2 .contents .snippet,
  .full-width-background .full-width-hero-card2 .contents .snippet p,
  .full-width-background
    .full-width-hero-card2
    .label-value-features
    .feature
    .feature-label,
  .full-width-background
    .full-width-hero-card2
    .label-value-features
    .feature
    .feature-value {
    font-size: 14px;
  }
  .full-width-background .full-width-hero-card2 .contents .rating-container {
    font-size: 14px;
    text-align: center;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .rating-container
    .rating
    .rating-label {
    margin-right: 10px;
    font-size: 16px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .features-bullets
    .feature {
    display: flex;
    margin-bottom: 10px;
  }
  .full-width-background
    .full-width-hero-card2
    .contents
    .features-bullets
    .feature:last-child {
    margin-bottom: 0;
  }
  .full-width-background .full-width-hero-card2 .label-value-features {
    width: 100%;
    margin-top: -20px;
    padding-top: 0;
  }
}
.hero-components .vc-table-scrolling__labels.is-scrollable,
.vc-table-scrolling__labels tr td:first-child,
.vc-table-scrolling__labels.is-scrollable,
.vc-table-scrolling__table tr td:first-child {
  box-shadow: 4px 0 4px -2px rgba(0, 0, 0, 0.2);
}
.zip-finder {
  width: 100%;
  margin-bottom: 15px;
}
.product-card .contents .column-1,
.single-review-hero-card .contents .column-1,
.vc-email-form,
.vc-email-subscribe,
.vc_sep_width_100,
.wpb_single_image img.vc_img-placeholder,
.zip-checker,
.zip-finder--is-centered {
  width: 100%;
}
.zip-finder--is-centered .zip-finder__fields {
  justify-content: center;
  width: auto;
  margin: 0 auto;
}
.zip-finder--blue {
  margin-bottom: 0.9375rem;
  padding: 0.9375rem;
  border: 2px solid #5050b9;
  border-radius: 2px;
}
@media print, screen and (min-width: 40em) {
  .margin-modifier {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .gen-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .large-padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .large-padding-full,
  .vc_cta3-container .vc_general.vc_cta3 {
    padding: 30px;
  }
  .medium-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .medium-padding-full {
    padding: 15px;
  }
  .small-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .small-padding-full {
    padding: 10px;
  }
  .nav-links {
    text-align: right;
  }
  .single-review-hero-card-container > .row:first-child {
    margin-top: 45px;
  }
  .vc_cta3-container {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .zip-finder--blue {
    padding: 1.875rem;
  }
}
.zip-finder--gray {
  margin-bottom: 0.9375rem;
  padding: 0.9375rem;
  border: 2px solid #c5c5c5;
  border-radius: 2px;
  background-color: #f3f3f3;
}
.zip-finder__header {
  margin-bottom: 0.9375rem;
  font-size: 1rem;
  font-weight: 700;
}
.zip-finder__fields {
  display: flex;
  position: relative;
}
.zip-finder__fields .form-error {
  position: absolute;
  bottom: -22px;
}
.zip-checker__input,
.zip-finder__input {
  width: auto;
}
.zip-finder__input input {
  width: 140px;
  height: 60px;
  margin-right: 0.3125rem;
  border-radius: 0;
  font-size: 1.25rem;
}
.zip-finder__button button {
  height: 60px;
  padding: 0.5rem 1.25rem;
  background-color: #ff2128;
  font-size: 1.125rem;
  font-weight: 700;
  vertical-align: -2px;
}
.zip-finder__button button:active,
.zip-finder__button button:focus,
.zip-finder__button button:hover {
  background-color: #d40007;
}
.zip-finder__button h4.zip-finder__button__header {
  margin: 0;
  color: #fff;
  font-family: Oswald, Helvetica, Arial, sans-serif;
}
.zip-finder .h4 {
  font-weight: 700;
}
.zip-checker__wrapper {
  margin-bottom: 15px;
  padding: 15px;
  border: 2px solid #363687;
  border-radius: 5px;
}
.zip-checker__fields {
  display: flex;
  flex-direction: row;
}
.zip-checker__input__secondary {
  display: flex;
}
.zip-checker__input input {
  width: 105px;
  height: 60px;
  margin-right: 15px;
  border-radius: 0;
  font-size: 1.25rem;
  box-shadow: none;
}
.zip-checker__input input:focus {
  border: 1px solid #cacaca;
  background-color: inherit;
  box-shadow: none;
}
.zip-checker__input input .is-invalid-input {
  color: #cacaca;
}
.zip-checker__button button {
  height: 60px;
  padding: 0.625rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 0.8;
  vertical-align: -4px;
}
.zip-checker__button button:active,
.zip-checker__button button:focus,
.zip-checker__button button:hover {
  background-color: #d40007;
}
.zip-checker__results .results-found .icon-check {
  display: inline-block;
  margin-right: 3px;
  color: #5050b9;
  font-size: 28px;
  vertical-align: -5px;
}
.zip-checker__results .results-not-found .icon-times {
  display: inline-block;
  margin-right: 3px;
  color: #ff2128;
  font-size: 28px;
  vertical-align: -5px;
}
.table--vc-default {
  width: auto !important;
}
.table--vc-default--is-full-width {
  width: 100% !important;
}
.table--vc-default a {
  font-weight: 700;
}
.table--vc-main {
  width: auto !important;
}
.table--vc-main--is-full-width {
  width: 100% !important;
}
.table--vc-main a {
  font-weight: 700;
}
.table--vc-comparison {
  width: auto !important;
}
.table--vc-comparison--is-full-width {
  width: 100% !important;
}
.table--vc-comparison tr td:first-child {
  border: 1px solid #9f9f9f;
  background-color: #c5c5c5;
}
.table--vc-comparison a {
  font-weight: 700;
}
.vc-table-scrolling__label {
  border-top: 1px solid #d8d8d8;
  color: #fff;
  font-weight: 700;
  text-align: left;
}
.vc-table-scrolling__label.has-headings td {
  border: 1px solid #1d253c;
  background-color: #1d253c;
  color: #fff;
}
.vc-table-scrolling__label.has-labels td {
  border-top: 1px solid #d8d8d8;
  background-color: #fff;
  color: #1d253c;
}
.vc-table-scrolling__cell,
.vc-table-scrolling__label__content {
  font-size: 14px;
  line-height: 16px;
}
.vc-table-scrolling__table-container {
  position: relative;
  max-width: 100%;
  overflow-y: auto;
}
.vc-table-scrolling__labels {
  position: relative;
  width: calc(100% + 1px);
  border-left: 1px solid #d8d8d8;
  z-index: 1;
}
.vc-table-scrolling__table {
  position: relative;
  border-right: 1px solid #d8d8d8;
  width: 100%;
  max-width: 100%;
}
.vc-table-scrolling .sticky-label {
  position: sticky;
  left: 0;
  padding: 18px 14px;
}
.vc-table-scrolling__labels tr,
.vc-table-scrolling__table tr {
  border-bottom: 1px solid #d8d8d8;
}
.vc-table-scrolling__labels tr td,
.vc-table-scrolling__table tr td {
  padding: 18px 14px;
  color: #000;
  text-align: left;
}
.vc-table-scrolling__labels tr:nth-child(2n),
.vc-table-scrolling__labels tr:nth-child(2n) td.sticky-label,
.vc-table-scrolling__labels tr:nth-child(2n) th.sticky-label,
.vc-table-scrolling__table tr:nth-child(2n),
.vc-table-scrolling__table tr:nth-child(2n) td.sticky-label,
.vc-table-scrolling__table tr:nth-child(2n) th.sticky-label {
  background-color: #f3f3f3;
}
.vc-table-scrolling__labels tr:nth-child(odd),
.vc-table-scrolling__labels tr:nth-child(odd) td.sticky-label,
.vc-table-scrolling__table tr:nth-child(odd),
.vc-table-scrolling__table tr:nth-child(odd) td.sticky-label {
  background-color: #fff;
}
.vc-table-scrolling__labels tr:first-child,
.vc-table-scrolling__table tr:first-child {
  border: 1px solid #1d253c;
  background-color: #1d253c;
  color: #fff;
}
.vc-table-scrolling__labels tr:first-child td,
.vc-table-scrolling__labels tr:first-child th,
.vc-table-scrolling__table tr:first-child td,
.vc-table-scrolling__table tr:first-child th {
  color: #fff;
  font-weight: 700;
}
.vc-table-scrolling__row {
  border-top: 1px solid #d8d8d8;
}
.vc-table-scrolling__row.has-headings td {
  background-color: #1d253c;
  color: #fff;
  font-weight: 700;
  vertical-align: top;
}
.vc-table-scrolling__row.has-labels td {
  background-color: #fff;
  color: #1d253c;
  font-weight: 700;
  vertical-align: top;
}
.hero-components .vc-table-scrolling__cell.text-blue,
.vc-table-scrolling__cell.text-blue {
  color: #5050b9;
}
.vc-table-scrolling__cell.text-gray {
  color: #506a89;
}
.hero-components .vc-table-scrolling th,
.vc-table-scrolling__cell ul {
  text-align: left;
}
.vc-table-scrolling th.sticky-label {
  background-color: #1d253c;
}
.hero-components .vc-table-scrolling {
  display: block;
  margin-top: 4px;
}
.hero-components .vc-table-scrolling__label {
  border-top: 2px solid #f3f3f3;
  color: #fff;
  font-weight: 400;
  text-align: left;
}
.hero-components .vc-table-scrolling__label.has-headings td {
  border: 0;
  background-color: #fff;
  color: #1d253c;
}
.hero-components .vc-table-scrolling__label.has-labels td {
  border: 0;
  background-color: #fff;
  color: #5b5b5b;
}
.hero-components .vc-table-scrolling__label__content {
  background-color: #fff;
  font-size: 12px;
  line-height: 14px;
}
.hero-components .vc-table-scrolling__table-container {
  position: relative;
  max-width: 100%;
  overflow-y: auto;
}
.hero-components .vc-table-scrolling__labels {
  position: relative;
  width: calc(100% + 1px);
  border-left: 0;
  z-index: 1;
}
.hero-components .vc-table-scrolling__table {
  position: relative;
  width: 100%;
  max-width: 100%;
  border-right: 0;
}
.hero-components .vc-table-scrolling .sticky-label {
  position: sticky;
  left: 0;
}
.hero-components .vc-table-scrolling__labels tr,
.hero-components .vc-table-scrolling__table tr {
  border-bottom: 2px solid #f3f3f3;
}
.hero-components .vc-table-scrolling__labels tr td,
.hero-components .vc-table-scrolling__table tr td {
  padding: 18px 14px;
  color: #000;
  text-align: left;
}
.hero-components .vc-table-scrolling__labels tr:nth-child(2n),
.hero-components .vc-table-scrolling__labels tr:nth-child(2n) td.sticky-label,
.hero-components .vc-table-scrolling__labels tr:nth-child(2n) th.sticky-label,
.hero-components .vc-table-scrolling__labels tr:nth-child(odd),
.hero-components .vc-table-scrolling__labels tr:nth-child(odd) td.sticky-label,
.hero-components .vc-table-scrolling__labels tr:nth-child(odd) th.sticky-label,
.hero-components .vc-table-scrolling__table tr:nth-child(2n),
.hero-components .vc-table-scrolling__table tr:nth-child(2n) td.sticky-label,
.hero-components .vc-table-scrolling__table tr:nth-child(2n) th.sticky-label,
.hero-components .vc-table-scrolling__table tr:nth-child(odd),
.hero-components .vc-table-scrolling__table tr:nth-child(odd) td.sticky-label,
.hero-components .vc-table-scrolling__table tr:nth-child(odd) th.sticky-label,
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner.vc_icon_element-background-color-white.vc_icon_element-background {
  background-color: #fff;
}
.hero-components .vc-table-scrolling__labels tr:first-child,
.hero-components .vc-table-scrolling__table tr:first-child {
  border: 0;
  background-color: #fff;
  color: #1d253c;
}
.hero-components .vc-table-scrolling__labels tr:first-child td,
.hero-components .vc-table-scrolling__labels tr:first-child th,
.hero-components .vc-table-scrolling__table tr:first-child td,
.hero-components .vc-table-scrolling__table tr:first-child th {
  color: #1d253c;
  font-weight: 700;
}
.hero-components .vc-table-scrolling__row {
  border-top: 2px solid #f3f3f3;
}
.hero-components .vc-table-scrolling__row.has-headings td {
  border: 0;
  background-color: #fff;
  color: #1d253c;
}
.hero-components .vc-table-scrolling__row.has-labels td {
  background-color: #fff;
  color: #5b5b5b;
  font-weight: 400;
  vertical-align: top;
}
.hero-components .vc-table-scrolling__cell {
  font-size: 12px;
  line-height: 14px;
}
.hero-components .vc-table-scrolling__cell.text-gray {
  color: #506a89;
}
.hero-components .vc-table-scrolling__cell ul,
.vc-email-subscribe__inline-form,
.vc_icon_element.vc_icon_element-outer.vc_icon_element-align-left {
  text-align: left;
}
.product-card {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  padding: 20px;
  border: 4px solid #5050b9;
  background-color: #fff;
  color: #1d253c;
}
.best-of-hero-card__label,
.single-review-hero-card__label {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 0.875rem;
  line-height: 0.875rem;
}
.product-card .contents {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.product-card .contents .column-1 .image--is-provider-logo {
  max-width: 145px;
}
.product-card .contents .column-2 {
  flex-basis: 50%;
  width: 100%;
}
.product-card .contents .column-3,
.single-review-hero-card .contents .column-3 {
  align-self: center;
  width: 100%;
}
.product-card .contents .logo-rating-container,
.single-review-hero-card .contents .logo-container {
  position: relative;
  text-align: center;
}
.product-card .contents .rating-container {
  margin: 10px 0;
  text-align: center;
}
.best-of-hero-card .cta-link a,
.product-card .contents .rating-container .rating .rating-link,
.product-card .cta-link a,
.single-review-hero-card .cta-link a {
  color: #5050b9;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}
.product-card .contents .rating-container .rating-star,
.single-review-hero-card .contents .rating-container .rating-star {
  margin-right: 6px;
}
.product-card .contents .cta-container,
.single-review-hero-card .contents .cta-container {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}
.product-card .contents .pros-cons-container .pros-cons-item,
.single-review-hero-card .contents .pros-cons-container .pros-cons-item {
  display: flex;
  margin-bottom: 10px;
}
.product-card .contents .pros-cons-container .pros-cons-icon,
.single-review-hero-card .contents .pros-cons-container .pros-cons-icon {
  display: inline-block;
  width: 20px;
  margin-top: 3px;
  margin-right: 10px;
}
.product-card .contents .pros-cons-container .pros-cons-text,
.single-review-hero-card .contents .pros-cons-container .pros-cons-text {
  display: inline-block;
  width: calc(100% - 40px);
  margin-left: -4px;
  color: #151515;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}
.product-card .contents .features-container {
  height: 100% !important;
  margin-bottom: 15px;
  text-align: left;
}
.product-card .contents .features-container .feature,
.single-review-hero-card .contents .features-container .feature {
  display: list-item;
  width: 100%;
  list-style-type: none;
}
.product-card .contents .features-container .feature .feature-label,
.product-card .contents .features-container .feature .feature-value {
  font-size: 14px;
  text-align: left;
}
.product-card .contents .features-container .feature .feature-label,
.single-review-hero-card .contents .features-container .feature .feature-label {
  display: inline-block;
  color: #151515;
  font-weight: 400;
}
.product-card .contents .features-container .feature .feature-value,
.single-review-hero-card .contents .features-container .feature .feature-value {
  display: inline-block;
  margin-left: 5px;
  color: #151515;
  font-weight: 700;
}
.best-of-hero-card img,
.product-card img,
.single-review-hero-card img {
  display: block;
  margin: auto;
}
.product-card__label {
  display: flex;
  position: absolute;
  top: -30px;
  left: -4px;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 30px;
  padding: 12px;
  background-color: #5050b9;
  color: #f3f3f3;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 0.875rem;
  text-align: center;
}
.product-card .brand-logo__img {
  display: block;
  width: 150px;
  height: 50px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.best-of-hero-card .brand-logo__img,
.best-of-hero-card img.image--is-provider-logo,
.single-review-hero-card .brand-logo__img,
.single-review-hero-card img.image--is-provider-logo {
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.single-review-hero-card {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  color: #1d253c;
  box-shadow: 0 5px 4px -4px #c5c5c5;
  z-index: 1;
}
.single-review-hero-card .contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #fff;
}
.single-review-hero-card .contents .column-2 {
  width: 100%;
  padding-top: 15px;
  border-top: 2px solid #f3f3f3;
}
.single-review-hero-card .contents .rating-container {
  margin: 15px 0 10px;
  text-align: center;
}
.single-review-hero-card .contents .rating-container .rating {
  margin: 0;
}
.single-review-hero-card .contents .rating-container .rating .rating-link {
  display: block;
  margin-top: -5px;
  color: #5050b9;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}
.single-review-hero-card .contents .features-container .features-html,
.single-review-hero-card .contents .features-container .features-html > li,
.single-review-hero-card .contents .features-container .features-html > p {
  font-size: 12px;
  line-height: 18px;
}
.single-review-hero-card .contents .features-container .feature .feature-label,
.single-review-hero-card .contents .features-container .feature .feature-value {
  font-size: 12px;
  text-align: left;
}
.single-review-hero-card img.image--is-provider-logo {
  display: block;
  width: 210px;
  max-width: 210px;
  margin: 0 auto 20px;
}
.single-review-hero-card__label {
  display: flex;
  position: absolute;
  top: -30px;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 30px;
  padding: 12px;
  background-color: #000;
  color: #f3f3f3;
  font-weight: 700;
  text-align: center;
}
.best-of-hero-card .brand-logo__img,
.single-review-hero-card .brand-logo__img {
  display: block;
  width: 210px;
  margin: 0 auto;
}
.best-of-hero-card {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #1d253c;
  z-index: 1;
  padding: 12px 8px;
}
.best-of-hero-card.layout-columns .best-of-hero-card__contents__first-column {
  justify-content: start;
}
.best-of-hero-card:not(.is-best) .best-of-hero-card__contents {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  filter: drop-shadow(5px 0px 5px rgba(0, 0, 0, 0.3));
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
.best-of-hero-card.is-best .best-of-hero-card__contents {
  border-right: 2px solid #ff2128;
  border-bottom: 2px solid #ff2128;
  border-left: 2px solid #ff2128;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.best-of-hero-card__label {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 12px;
  background-color: #363687;
  color: #f3f3f3;
  font-weight: 700;
  text-align: left;
}
.best-of-hero-card__contents {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 15px;
  background-color: #fff;
}
.best-of-hero-card__contents__first-column {
  max-width: none;
  border-right: none;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.best-of-hero-card__contents__logo-container {
  border-bottom: 2px solid #f3f3f3;
  padding: 0 0 8px;
  position: relative;
}
.best-of-hero-card__contents__cta-container {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}
.best-of-hero-card__contents__features-container {
  padding: 16px 0;
  width: 100%;
}
.best-of-hero-card__contents__features-container.first-container {
  display: block;
}
.best-of-hero-card__contents__features-container.second-container,
.vc-email-form label,
.vc-email-subscribe label {
  display: none;
}
.best-of-hero-card__contents .rating-container {
  margin: 12px 0 8px;
  text-align: center;
}
.best-of-hero-card__contents .rating-container .rating {
  margin: 0;
}
.best-of-hero-card__contents .rating-container .rating .rating-link {
  display: block;
  margin-top: -5px;
  color: #5050b9;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
}
.best-of-hero-card__contents .rating-container .rating-star {
  margin-right: 6px;
}
.best-of-hero-card__contents .featured-price-container {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
}
.best-of-hero-card__contents .pros-cons-container {
  max-width: 230px;
  margin: 0 auto;
}
.best-of-hero-card__contents .pros-cons-container .pros-cons-item:last-child,
.vc-email-form__wrapper .callout-area {
  margin-bottom: 20px;
}
.best-of-hero-card__contents .pros-cons-container .pros-cons-item {
  display: flex;
  margin-bottom: 10px;
}
.best-of-hero-card__contents .pros-cons-container .pros-cons-icon {
  display: inline-block;
  width: 18px;
  margin-top: 0;
  margin-right: 10px;
}
.best-of-hero-card__contents .pros-cons-container .pros-cons-text {
  display: inline-block;
  width: calc(100% - 40px);
  margin-left: -4px;
  color: #151515;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
.best-of-hero-card__contents .features-list-container .feature {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  text-align: left;
}
.best-of-hero-card__contents
  .features-list-container
  .feature:not(:last-of-type) {
  border-bottom: 2px solid #f3f3f3;
}
.best-of-hero-card__contents .features-list-container .feature-label {
  font-weight: 700;
}
.best-of-hero-card__contents .features-list-container .feature-value {
  text-align: right;
}
.best-of-hero-card__contents .features-html-container p {
  margin: 0;
  padding: 8px 0;
}
.best-of-hero-card img.image--is-provider-logo {
  display: block;
  max-width: 210px;
  width: 100%;
  margin: 0 auto 8px;
  object-fit: contain;
}
@media print, screen and (min-width: 40em) {
  .zip-finder--gray {
    padding: 1.875rem;
  }
  .zip-checker__input input,
  .zip-finder__input input {
    width: 220px;
    margin-right: 0.9375rem;
  }
  .zip-checker__wrapper {
    padding: 30px;
    margin-bottom: 30px;
  }
  .zip-checker__button button {
    padding: 0.5rem 1.25rem;
  }
  .vc-table-scrolling__label__content {
    font-size: 1rem;
    line-height: 1.125rem;
  }
  .vc-table-scrolling__labels tr td:first-child,
  .vc-table-scrolling__table tr td:first-child {
    box-shadow: none;
  }
  .vc-table-scrolling__cell {
    font-size: 16px;
    line-height: 18px;
  }
  .hero-components .vc-table-scrolling__label__content {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
  .hero-components .vc-table-scrolling__cell {
    font-size: 12px;
    line-height: 14px;
  }
  .product-card {
    padding: 30px;
  }
  .product-card .contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .product-card .contents .column-1,
  .product-card .contents .column-3 {
    width: 30%;
  }
  .product-card .contents .column-2 {
    width: 40%;
  }
  .product-card .contents .logo-rating-container,
  .single-review-hero-card .contents .logo-container {
    max-width: 210px;
    float: left;
    text-align: center;
  }
  .product-card .contents .cta-container {
    float: right;
    text-align: center;
  }
  .best-of-hero-card__contents .pros-cons-container .pros-cons-item:last-child,
  .product-card .contents .pros-cons-container .pros-cons-item:last-child,
  .single-review-hero-card
    .contents
    .pros-cons-container
    .pros-cons-item:last-child {
    margin-bottom: 10px;
  }
  .single-review-hero-card .contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
  }
  .single-review-hero-card .contents .column-1,
  .single-review-hero-card .contents .column-3 {
    flex-basis: 33%;
  }
  .single-review-hero-card .contents .column-2 {
    flex-basis: 33%;
    padding-left: 15px;
    border-top: 0;
  }
  .single-review-hero-card .contents .cta-container {
    max-width: 300px;
    float: right;
    text-align: center;
  }
  .best-of-hero-card.num-cards-2,
  .best-of-hero-card.num-cards-5 {
    width: 50%;
  }
  .best-of-hero-card.num-cards-3 {
    width: 33%;
  }
  .best-of-hero-card.num-cards-4 {
    width: 25%;
  }
  .best-of-hero-card img.image--is-provider-logo {
    max-width: 175px;
    height: 65px;
  }
}
.swipeable-animation {
  display: none;
  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 999;
}
.swipeable-animation.is-animating {
  display: block;
  transition: right 1s ease-in;
}
.vc-email-form__wrapper {
  margin-bottom: 35px;
  padding: 30px 15px;
  border-top: 6px solid #5b5b5b;
  background-color: #f3f3f3;
}
.generic-form .disclaimer-text,
.vc-email-form__wrapper .callout-area p {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.vc-email-form__disclaimer a {
  font-size: 0.75rem;
}
.vc-email-form__disclaimer a:hover {
  text-decoration: underline;
}
.vc-email-form ._form-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 20px;
}
.vc-email-form ._form-content ._field-wrapper ._error._below {
  position: absolute;
  top: -25%;
  left: 0;
  color: #cc4b37;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: left;
}
.vc-email-form p.vc-email-form__disclaimer {
  line-height: 1rem;
}
.vc-email-form input {
  height: 60px;
  padding: 0.5rem;
  border-radius: 0;
}
.vc-email-form input.field-zip {
  width: 100px;
  margin-right: 15px;
}
.vc-email-form button {
  width: 100%;
  height: 60px;
  padding: 1rem;
  border-radius: 0;
  background-color: #5050b9;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vc-email-subscribe__wrapper {
  padding: 30px 15px;
  border: 5px solid #303b5d;
  background-color: #f3f3f3;
}
.vc-email-subscribe__wrapper .callout-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7px;
  margin-bottom: 10px;
}
.vc-email-subscribe__wrapper .callout-area .callout-icon {
  margin: 0 auto 15px;
}
.vc-email-subscribe__wrapper .callout-area .callout-icon svg {
  width: 65px;
  height: 54px;
}
.vc-email-subscribe__wrapper .callout-area .callout-text {
  display: flex;
  flex-direction: column;
  margin-top: -5px !important;
  margin-left: 0;
}
.vc-email-subscribe__wrapper .callout-area .callout-text h3 {
  border: 0;
}
.vc-email-subscribe__wrapper .callout-area .callout-text p {
  margin-top: -7px;
}
.vc-email-subscribe__disclaimer {
  color: #151515;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: left;
}
@media print, screen and (min-width: 40em) {
  .vc-email-form__wrapper,
  .vc-email-subscribe__wrapper {
    padding: 30px;
  }
  .vc-email-form__wrapper .callout-area p {
    font-size: 1rem;
  }
  .vc-email-form__inline-form {
    color: #ff2128;
    font-weight: 700;
    text-align: right;
  }
  .vc-email-subscribe__wrapper .callout-area {
    flex-direction: row;
  }
  .vc-email-subscribe__wrapper .callout-area .callout-icon {
    margin: 0;
  }
  .vc-email-subscribe__wrapper .callout-area .callout-text {
    margin-left: 35px;
  }
  .vc-email-subscribe__disclaimer {
    text-align: center;
  }
  .vc-email-subscribe__inline-form {
    color: #ff2128;
    font-weight: 700;
    text-align: left;
  }
}
.vc-email-subscribe__disclaimer a {
  font-size: 0.75rem;
}
.vc-email-subscribe__disclaimer a:hover {
  text-decoration: underline;
}
@media print, screen and (min-width: 40em) {
  .vc-email-subscribe ._form-content {
    justify-content: center;
  }
}
.vc-email-subscribe input {
  height: 50px;
  margin-bottom: 5px;
  padding: 0.5rem;
  border-radius: 0;
  font-size: 0.95rem;
}
.vc-email-subscribe input.field-email {
  width: 236px;
  margin-right: 15px;
  margin-bottom: 15px;
}
@media print, screen and (min-width: 64em) {
  ._static-content--center {
    align-items: flex-start;
    justify-content: left;
    text-align: left;
  }
  .logo-row__break {
    display: none;
  }
  .ppc-zip-form-dialog__top h4,
  .reveal-dialog .dialog__top h4,
  .reveal-dialog .dialog__top p {
    font-size: 1.125rem;
  }
  .cities-list {
    grid-template-columns: repeat(5, 1fr);
  }
  .searchform {
    border: 0;
  }
  .searchform button {
    width: 40px;
    height: 40px;
  }
  .searchform button img {
    width: 16px;
    height: 16px;
  }
  .footer__email-form ._form-content,
  .vc-email-form ._form-content {
    flex-wrap: nowrap;
  }
  .provider-cards__card--is-bf .provider-cards__card-bottom .columns,
  .provider-cards__card--is-bf
    .provider-cards__card-bottom
    .columns:nth-child(3) {
    border-left: 0;
  }
  .main-wrap.page
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .column,
  .main-wrap.page
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .columns,
  .main-wrap.post
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .column,
  .main-wrap.post
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .columns,
  .main-wrap.search
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .column,
  .main-wrap.search
    .hero-components.best-of-hero-card-container
    .full-width-hero
    .columns {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .post-info__author-info {
    font-size: 1rem;
  }
  .gen-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .large-padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .large-padding-full {
    padding: 30px;
  }
  .medium-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .medium-padding-full {
    padding: 15px;
  }
  .small-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .small-padding-full {
    padding: 10px;
  }
  .medium-down-text-center {
    text-align: inherit;
  }
  .best-of-hero-card__contents .pros-cons-container .pros-cons-text,
  .product-card .contents .pros-cons-container .pros-cons-text,
  .single-review-hero-card .contents .pros-cons-container .pros-cons-text {
    line-height: 19px;
  }
  .product-card .brand-logo__img,
  .single-review-hero-card .brand-logo__img,
  .single-review-hero-card img.image--is-provider-logo {
    width: 210px;
    height: 70px;
  }
  .best-of-hero-card.layout-columns .best-of-hero-card__contents {
    flex-direction: row;
  }
  .best-of-hero-card.layout-columns .best-of-hero-card__contents__first-column {
    max-width: 50%;
    border-right: 2px solid #f3f3f3;
    padding: 0 16px;
  }
  .best-of-hero-card.layout-columns
    .best-of-hero-card__contents__features-container {
    padding: 16px 12px;
  }
  .best-of-hero-card.layout-columns
    .best-of-hero-card__contents__features-container.first-container {
    display: none;
  }
  .best-of-hero-card.layout-columns
    .best-of-hero-card__contents__features-container.second-container {
    display: block;
  }
  .best-of-hero-card.layout-columns
    .best-of-hero-card__contents__cta-container {
    padding: 16px 0;
  }
  .best-of-hero-card.num-cards-4 img.image--is-provider-logo {
    max-width: 175px;
    height: 55px;
  }
  .best-of-hero-card.num-cards-5 {
    width: 20%;
  }
  .best-of-hero-card.num-cards-5 .has-button a:first-of-type,
  .best-of-hero-card.num-cards-5 a.button,
  .has-button .best-of-hero-card.num-cards-5 a:first-of-type {
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
  }
  .best-of-hero-card.num-cards-5 img.image--is-provider-logo {
    max-width: 140px;
    height: 45px;
  }
  .best-of-hero-card .brand-logo__img {
    width: 100%;
    height: auto;
    padding-top: 56%;
  }
  .vc-email-form__wrapper svg {
    margin-right: 20px;
    margin-bottom: -50px;
    margin-left: -90px;
    transform: scale(1.7);
  }
  .vc-email-subscribe__wrapper .callout-area,
  .vc-email-subscribe__wrapper .callout-area .callout-icon,
  .vc-email-subscribe__wrapper .callout-area .callout-text p {
    margin-left: 0;
  }
  .vc-email-subscribe__disclaimer {
    text-align: left;
  }
  .vc-email-subscribe ._form-content {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .vc-email-subscribe input.field-email {
    width: 200px;
  }
  .vc-email-subscribe input.field-zip {
    width: 110px;
  }
}
.vc-email-subscribe input::placeholder {
  text-transform: uppercase;
}
.vc-email-subscribe button {
  width: 100%;
  height: 50px;
  padding: 1rem;
  border-radius: 0;
  background-color: #ff2128;
  color: #fff;
  font-size: 0.95rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media print, screen and (min-width: 40em) {
  .vc-email-subscribe input.field-email {
    margin-right: 15px;
    margin-bottom: 0;
  }
  .post-block {
    margin-bottom: 15px;
  }
}
.post-block--featured-image {
  width: 100%;
  min-height: 200px;
  margin-right: auto;
  margin-bottom: 15px;
  margin-left: auto;
}
.post-block--featured-image .image-holder img {
  object-fit: cover;
}
.post-block--title {
  margin-bottom: 5px;
}
.post-block--title a {
  color: #1d253c;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-decoration: none;
  text-transform: uppercase;
}
select.city-select,
select.state-select {
  padding: 10px 45px 10px 10px;
  background-image: url("../images/pages/generic/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 0 bottom 50%;
  background-size: 50px 65%;
  color: #000;
  cursor: pointer;
  background-origin: border-box;
  width: 100%;
}
.post-block--title a:hover {
  text-decoration: underline;
}
.post-block--title.dark-bg a {
  color: #fff;
}
.post-block--author-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
}
.post-block--author-area .author-area--image-link {
  flex-grow: 0;
  margin-right: 10px;
}
.post-block--author-area .author-area--name {
  flex-grow: 3;
  font-size: 0.875rem;
}
.post-block--author-area .author-area--name a {
  color: #1d253c;
  font-size: 0.875rem;
  text-decoration: none;
}
.post-block--author-area .author-area--date.dark-bg,
.post-block--author-area .author-area--name.dark-bg,
.post-block--author-area .author-area--name.dark-bg a,
.post-block--excerpt.dark-bg,
.pull_quote.dark h4 {
  color: #fff;
}
.post-block--author-area .author-area--name a:hover {
  text-decoration: underline;
}
.post-block--author-area .author-area--date {
  flex-grow: 1;
  font-size: 0.875rem;
  text-align: right;
}
.post-block--excerpt {
  border: 0;
  color: #1d253c;
}
.responsive-embed,
.video-wrapper {
  position: relative;
  height: 0;
  padding-top: 25px;
}
.responsive-embed iframe,
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wpb_video_widget.vc_video-aspect-ratio-169 .wpb_video_wrapper {
  padding-top: unset;
}
.pull_quote {
  margin-bottom: 50px;
  margin-left: 35px;
  margin-top: 20px;
  padding: 35px 25px;
  position: relative;
  text-align: left;
}
.vc_cta3-container,
.vc_icon_element,
.wpb_button,
.wpb_content_element,
ul.wpb_thumbnails-fluid > li {
  margin-bottom: 35px;
}
.pull_quote .h4 {
  font-size: 30px;
  line-height: 2.25rem;
  font-weight: 700;
}
.vc_cta3-container,
.vc_sep_pos_align_center {
  margin-left: auto;
  margin-right: auto;
}
.pull_quote_icon {
  background-color: #ff2128;
  height: 75px;
  left: -37px;
  padding: 21px 16px;
  position: absolute;
  top: -37px;
  width: 75px;
}
.pull_quote.dark {
  background-color: #1d1d49;
  color: #fff;
}
.pull_quote.dark .triangle {
  border-top-color: #1d1d49;
}
.pull_quote.light {
  background-color: #fff;
  border: 3px solid #ff2128;
  color: #1d1d49;
}
.pull_quote.light h4 {
  color: #1d1d49;
}
.pull_quote.light .triangle {
  border-top-color: #ff2128;
  bottom: -32px;
}
.pull_quote.light .triangle:before {
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 27px 32px 0 0;
  content: "";
  height: 0;
  left: 3px;
  position: absolute;
  top: -34px;
  width: 0;
}
.pull_quote .triangle {
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 30px 35px 0 0;
  height: 0;
  left: 60%;
  position: absolute;
  bottom: -30px;
  width: 0;
}
.generic-form [type="color"],
.generic-form [type="date"],
.generic-form [type="datetime-local"],
.generic-form [type="datetime"],
.generic-form [type="email"],
.generic-form [type="month"],
.generic-form [type="number"],
.generic-form [type="password"],
.generic-form [type="search"],
.generic-form [type="tel"],
.generic-form [type="text"],
.generic-form [type="time"],
.generic-form [type="url"],
.generic-form [type="week"] {
  margin-bottom: 0;
  padding: 1.813rem;
  border-radius: 0;
}
.generic-form [type="checkbox"] {
  margin: 0 0.5rem;
}
.generic-form .button,
.generic-form .has-button a:first-of-type,
.has-button .generic-form a:first-of-type {
  width: auto;
  min-width: 160px;
  padding: 1.25rem;
  font-size: 1.25rem;
}
.generic-form label {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5;
}
select.state-select {
  max-width: 240px;
  height: 50px;
  border: 0;
  border-radius: 0;
  background-color: #f3f3f3;
  font-size: 0.95rem;
}
select.city-select:active,
select.city-select:focus,
select.state-select:active,
select.state-select:focus {
  border: 1px solid #3c3c8b;
}
select.city-select {
  max-width: 240px;
  height: 50px;
  border-width: 3px;
  border-radius: 0;
  border-color: #c5c5c5;
  background-color: #fff;
  font-size: 0.9375rem;
}
@media print, screen and (min-width: 40em) {
  .pull_quote {
    margin-left: 0;
    margin-top: 30px;
    padding: 35px;
  }
  select.city-select,
  select.state-select {
    max-width: 300px;
  }
}

@media screen and (max-width: 425px) {
  /* .blog-box {
    width: calc(50% - 1rem); 2 cards per row on medium-large screens
  } */
  .hero--generic h1, h1 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: uppercase;
}

p {
  text-rendering: optimizeLegibility;
  margin-bottom: 15px;
  font-size: 0.8rem;
}

h2 {
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 0.999rem;
}
h3 {
  font-size: 0.8125rem;
}

h4 {
  font-size: 0.825rem;
  font-weight: 700;
}

.hero--generic-blog h1, h4, h5, p {
  line-height: 1.1rem;
}

.heading--channel-lineup, h3 {
  font-weight: 700;
  line-height: 0.9875rem;
}
.vc-email-subscribe input.field-email {
  width: 130px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.vc-email-subscribe input {
  height: 33px;
  margin-bottom: 5px;
  padding: 0.5rem;
  border-radius: 0;
  font-size: 0.85rem;
}
.vc-email-subscribe button {
  width: 100%;
  height: 32px;
  padding: 0.5rem;
  border-radius: 0;
  background-color: #ff2128;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.zip-form__input input {
  width: 97px;
  height: 40px;
  border-radius: 0;
  font-size: 0.75rem;
}

.zip-form__button button {
  height: 40px;
  padding: 0.5rem 1.25rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 0.8;
  text-transform: uppercase;
  vertical-align: -2px;
}

select.state-select {
  max-width: 240px;
  height: 40px;
  border: 0;
  border-radius: 0;
  background-color: #f3f3f3;
  font-size: 0.85rem;
}

.ctv-logo img {
  height: 50px;
  width: 200px;
  padding-top: 5px;
  background-size: cover;
}

.button--is-big, .has-button a:first-of-type, .hero-components .vc-table-scrolling__cell.has-button a:first-of-type, .vc-table-scrolling__cell.has-button a:first-of-type {
  padding: 0.625rem 1.25rem;
  font-size: 0.825rem;
  white-space: nowrap;
}
.site__footer a img{
  height: 50px;
  width: 200px;
  background-size: cover;
}
}
.vc_separator {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner.vc_icon_element-size-md
  .vc_icon_element-icon {
  font-size: 2.15em !important;
}
.vc_separator.vc_sep_color_grey .vc_sep_line {
  border-color: #ebebeb;
}
.vc_separator .vc_sep_holder .vc_sep_line {
  height: 1px;
  border-top: 1px solid #ebebeb;
  display: block;
  position: relative;
  top: 1px;
  width: 100%;
}
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner.vc_icon_element-size-md {
  max-width: 100% !important;
  line-height: 2.15em !important;
}
.vc_icon_element.vc_icon_element-outer .vc_icon_element-inner {
  text-align: center;
  display: inline-block;
  border: 2px solid transparent;
  width: 4em;
  height: 4em;
  box-sizing: content-box;
  position: relative;
}
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner.vc_icon_element-color-green
  .vc_icon_element-icon {
  color: #6dab3c;
}
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner
  .vc_icon_element-icon {
  font-size: 2.15em;
  font-style: normal;
  font-weight: 400;
  line-height: 1 !important;
  font-size-adjust: none;
  font-stretch: normal;
  font-feature-settings: normal;
  font-language-override: normal;
  font-kerning: auto;
  font-synthesis: weight style;
  font-variant: normal;
  text-rendering: auto;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vc_separator.vc_separator_align_center h4 {
  padding: 0 0.8em;
}
.vc_separator h4 {
  line-height: 1em;
  font-size: 100%;
  margin: 0;
  word-wrap: break-word;
  flex: 0 1 auto;
}
.vc_separator .vc_sep_holder {
  height: 1px;
  position: relative;
  flex: 1 1 auto;
  min-width: 10%;
}
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner.vc_icon_element-color-juicy_pink
  .vc_icon_element-icon {
  color: #f4524d;
}
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner
  .vc_icon_element-icon:before {
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  text-decoration: inherit;
  width: inherit;
  height: inherit;
  font-size: 1em;
  text-align: center;
  text-rendering: optimizelegibility;
}
.vc_separator.vc_sep_color_blue .vc_sep_line {
  border-color: #5472d2;
}
.vc_icon_element.vc_icon_element-outer
  .vc_icon_element-inner.vc_icon_element-color-blue
  .vc_icon_element-icon {
  color: #5472d2;
}
.wpb_single_image .vc_figure {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  max-width: 100%;
}
.wpb_single_image .vc_single_image-wrapper {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}
.wpb_single_image a {
  border: none;
  outline: 0;
}
.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}
.vc_cta3-container::after,
.vc_cta3-container::before {
  display: table;
  content: " ";
}
.vc_general.vc_cta3.vc_cta3-icons-on-border.vc_cta3-icons-top.vc_cta3-icon-size-md {
  margin-top: 28px;
}
.vc_general.vc_cta3.vc_cta3-color-blue.vc_cta3-style-outline {
  border-color: #5472d2;
  background-color: rgba(0, 0, 0, 0);
}
.vc_general.vc_cta3.vc_cta3-style-outline {
  border-width: 3px;
}
.vc_general.vc_cta3 {
  border: 1px solid transparent;
  font-size: 1em;
  padding: 28px;
  word-wrap: break-word;
}
.vc_general.vc_cta3.vc_cta3-icons-on-border.vc_cta3-icons-top .vc_cta3-icons {
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.vc_general.vc_cta3.vc_cta3-icons-on-border .vc_cta3-icons {
  position: absolute;
}
.vc_general.vc_cta3 .vc_cta3-icons {
  vertical-align: middle;
}
.vc_icon_element.vc_icon_element-outer {
  box-sizing: border-box;
  text-align: center;
}
.vc_icon_element {
  line-height: 0;
  font-size: 14px;
}
.vc_general.vc_cta3.vc_cta3-icons-on-border.vc_cta3-icons-top.vc_cta3-icon-size-lg
  .vc_cta3_content-container,
.vc_general.vc_cta3.vc_cta3-icons-on-border.vc_cta3-icons-top.vc_cta3-icon-size-md.vc_cta3-icons-in-box
  .vc_cta3_content-container {
  padding-top: 21px;
}
.vc_general.vc_cta3.vc_cta3-icons-on-border.vc_cta3-icons-top.vc_cta3-icon-size-md
  .vc_cta3_content-container,
.vc_general.vc_cta3.vc_cta3-icons-on-border.vc_cta3-icons-top.vc_cta3-icon-size-sm.vc_cta3-icons-in-box
  .vc_cta3_content-container {
  padding-top: 14px;
}
.vc_general.vc_cta3.vc_cta3-icons-top .vc_cta3_content-container {
  padding-top: 1em;
}
.vc_color-info.vc_message_box {
  color: #5e7f96;
  border-color: #cfebfe;
  background-color: #dff2fe;
}
.vc_message_box {
  border: 1px solid transparent;
  display: block;
  overflow: hidden;
  margin: 0 0 21.74px;
  padding: 1em 1em 1em 4em;
  position: relative;
  font-size: 1em;
  box-sizing: border-box;
}
.vc_color-info.vc_message_box .vc_message_box-icon {
  color: #56b0ee;
}
.vc_message_box-icon {
  bottom: 0;
  font-size: 1em;
  left: 0;
  top: 0;
  width: 4em;
}
.vc_message_box-icon > *,
.vc_message_box-icon > .fa {
  font-size: 1.7em;
  line-height: 1;
}
.vc_message_box-icon > *,
.vc_toggle_simple .vc_toggle_icon::after,
.vc_toggle_simple .vc_toggle_icon::before {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vc_message_box-icon,
.vc_message_box-icon > * {
  position: absolute;
  font-weight: 400;
  font-style: normal;
}
.vc_general.vc_cta3.vc_cta3-style-classic,
.vc_general.vc_cta3.vc_cta3-style-custom {
  border-color: #f0f0f0;
  background-color: #f7f7f7;
}
.fa-television:before {
  content: "";
}
