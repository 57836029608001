.modalCss{
width: 40vw;
}

.loader-usa{
    margin: auto;
    text-align: center;
    justify-items: center;
    justify-content: center;
    margin-top: 20%;
}

@media screen and (max-width: 1000px) {
    .modalCss{
        width: 80vw;

    }
  }